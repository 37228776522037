import {
    OPEN_AGENT_BUTTON,
    CLOSE_AGENT_BUTTON,
    FETCH_ONLINE_AGENTS,
    FETCH_KEYS,
    SET_ONLINE_USERS,
    TOTAL_ONLINE_USER,
    OPEN_MODAL,
    CLOSE_MODAL
} from "./OnlineCustomerActions"

const initialState = {
    openAgentList: false,
    showFabButton: true,
    isOpenModal: false,
    onlineUsers: [],
    pubnubCredentials: {},
    onlineUserList: [],
    totalOnlineUser:0
};

const agent = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_AGENT_BUTTON:
            return {
                ...state,
                openAgentList: action.openAgentList,
                showFabButton: action.showFabButton
            };
        case CLOSE_AGENT_BUTTON:
            return {
                ...state,
                openAgentList: action.openAgentList,
                showFabButton: action.showFabButton
            };
        case FETCH_ONLINE_AGENTS:
            return {
                ...state,
                onlineUsers: action.onlineUsers
            }
        case FETCH_KEYS:

            return {
                ...state,
                pubnubCredentials: action.pubnubCredentials
            }
        case SET_ONLINE_USERS:
            return {
                ...state,
                onlineUserList: action.onlineUserList
            }
        case TOTAL_ONLINE_USER:
            return {
                ...state,
                totalOnlineUser:action.totalOnlineUser
            }
        case OPEN_MODAL:
            return{
                ...state,
                isOpenModal : action.isOpenModal
            }
        case CLOSE_MODAL:
            return{
                ...state,
                isOpenModal : action.isOpenModal
            }
        default:
            return state;
    }
}

export const getOpenAgentList = (state) => state.agent.openAgentList;
export const getShowFabButton = (state) => state.agent.showFabButton;
export const getOnlineUsers = (state) => state.agent.onlineUsers;
export const getPubnubCredentials = (state) => state.agent.pubnubCredentials;
export const getOnlineUserList = (state) => state.agent.onlineUserList;
export const getTotalOnlineUser = (state) => state.agent.totalOnlineUser;
export const getIsOpenModal = (state) => state.agent.isOpenModal;

export default agent;