import React, {Component} from 'react';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';
import async from 'async';
import {
    Button,
    Modal,
    Tab, Tabs
} from 'react-bootstrap';
import _ from 'lodash';
import RecoVenues from './components/RecoVenues';
import AddRecoVenueForm from './components/AddRecoVenueForm';
import ShortlistedVenues from './components/ShortlistedVenues';
import PrevEmails from './components/PrevEmails';
import EmailSent from './components/EmailSent';
import SmsSent from './components/SmsSent';
import PackageInfo from './components/PackageInfo';
import SubpackageInfo from './components/SubpackageInfo';
import BookedVisitsInfo from './components/BookedVisitsInfo';
import VisitInfo from './components/VisitInfo';
import InformVenue from './components/InformVenue';
import PartnerVenueLeads from './components/PartnerVenueLeads';
import EventEditForm from './components/EventEditForm';
import EventLogs from './components/EventLogs';
import EventTasks from './components/EventTasks';
import LeadConversionForm from './components/LeadConversionForm';
import EditSubPackageSection from './components/EditSubpackageSection';
import EditVisitSection from './components/EditVisitSection';
import callEventApi from '../../util/eventApiCaller';
import callApi from '../../util/apiCaller';
import callCustomerApi from '../../util/customerApiCaller';
import VenueMenu from './components/VenueMenu';
import SmsVenueSegment from './components/SmsVenueSegment';
import FadingCircleLoader from "../Loader/FadingCircleLoader";
import AddBundleSetForm from "./components/AddBundleSetForm";
import CloseWonBell from "../CloseWonBell";
import EventFeedback from "./components/EventFeedback";
import ScheduleVisitSection from "./components/ScheduleVisitSection";
import {
    getAsset,
    getBundle,
    getBundleId,
    getCities,
    getCorporateEvent,
    getEmailtasks,
    getEvent,
    getEventLoadingState,
    getEventStat,
    getEventtemplates,
    getFeedback,
    getFetchingLogsStatus,
    getIsClosingLead,
    getIsFindingNewAgent,
    getIsNewAgentFoundProcessed,
    getIsShowCloseWonBell,
    getIsUpdatingEventInfo,
    getLocation,
    getLogId,
    getLogs,
    getNewFoundAgent,
    getOccasions,
    getPackages,
    getPrevEmails,
    getPreviousEvents,
    getRecoList,
    getReloadingBundleStatus,
    getSelectedEventId,
    getSmstasks,
    getTasks,
    getUserSmsTemplates,
    getVenuebundleList,
    getVenueList,
    getVenueSmsTemplates,
    getVenuetypes,
    getIsInformingVenue,
    getIsSendingUserEmail,
    getIsEditingVisit,
    getIsSchedulingVisit,
    getSelectedVisit,
    getScheduleVisitId,
    getIsSavingVisit,
    getSelectedMenus,
    getIsEditingPackage,
    getIsViewingPackageMenu,
    getSelectedPackage,
    getIsSavingPackage,
    getIsSendingSmsToUser,
    getIsSendingSmsToVenue,
    getQueryList,
    getQueryBundleSet,
    getRecoListCombined
} from "../../redux/admin/EventReducer";
import {
    addPackage,
    addRecoVenues,
    AGENT_OPTIONS,
    changeInsideAgent,
    addVisitAgent,
    clearAgent,
    clonePackage,
    closeEditPackageForm,
    closeEditVisitForm,
    closeScheduleVisitForm,
    closeVenueMenu,
    convertLead,
    editPackage,
    editVisit,
    fetchEventInfo, fetchEventNotes,
    fetchEventTemplates,
    findNewAgent,
    getAgentOptions,
    getPackageOptions,
    handleCityChange,
    handleScheduleVisit,
    handleVenueVisitUpdate,
    loadLogSegment,
    notifyToVenues,
    openScheduleVisit,
    planVisit,
    queryBundle,
    queryForVenue,
    reloadBundle,
    removeRecoVenue,
    removeShortlistVenue,
    removeSubpackage,
    removeVisit,
    updateVisitAgent,
    selectBundle,
    sendEmailForPackageInfoToUser,
    sendEmailForVisitInfoToUser,
    sendSmsForUser,
    sendSmsToVenue,
    setEventLoadingStep,
    setSelectedEventId,
    shortlistRecoVenue,
    shortlistRecoVenueFromVenueSelection,
    showMenu,
    showMenuForVenuePackage,
    showVenueMenu,
    toggleEventModalForm,
    updateAssetData,
    updateCustomerEventInfo,
    updateEvent,
    updateSubpackage,
    updateVenueBundleAdminNote,
    updateVisit
} from '../../redux/admin/EventActions';
import callIdApi from "../../util/idApiCaller";
import RecoVenueCombined from './components/RecoVenueCombined';
import {processCallToDialer} from "../../redux/admin/DialerActions";
import { getTotalAgentMap } from '../../redux/admin/UserReducer';


const PROTOCOL = window.location.protocol;
const HOST = window.location.host;
const SEARCH_PACKAGE_API = `${process.env.REACT_APP_API_URL}/api/v3/package-suggestions/search?term=`;
const SEARCH_AGENT_API = `${process.env.REACT_APP_ID_API_URL}/api/v3/agents/search?term=`;

class EventModalForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: 'Untouched',
            isMounted: false,
            isLoading: true,
            isUpdatingEventInfo: false,
            eventId: '',
            bundleId: '',
            event: {},
            logs: [],
            tasks: [],
            emailtasks: [],
            smstasks: [],
            recoList: [],
            venueList: [],
            prevEmails: [],
            queryList: [],
            intervalId: 0,
            selectedTab: this.props.selectedTab ? this.props.selectedTab : 6,
            showStageSection: false,
            showAddVenueForm: false,
            showStageChangeForm: false,
            showAddTaskForm: false,
            isSendingUserEmail: false,
            isInformingVenue: false,
            isSendingSmsToVenue: false,
            isSendingSmsToUser: false,
            isClosingLead: false,
            occasions: [],
            cities: [],
            packages: [],
            venuetypes: [],
            location: [],
            assets: {},
            selectedPackage: {},
            selectedMenus: [],
            isViewingPackageMenu: false,
            isEditingPackage: false,
            isSavingPackage: false,
            isEditingVisit: false,
            isSchedulingVisit: false,
            scheduleVisitId: '',
            isSavingVisit: false,
            eventtemplates: [],
            userSmsTemplates: [],
            venueSmsTemplates: [],
            bundle: {},
            isFetchingLogs: false,
            isFetchingCloseWon: false,
            isFetchingInformVenue: false,
            isFetchingProposal: false,
            isFetchingSortlist: false,
            isFetchingDetails: false,
            formLoadStatus: [0, 0, 0, 0, 0, 0, 0, 0, 0],
            eventStat: {},
            queryBundleSet: [],
            corporateEvent: {},
            isShowCloseWonBell: false,
            isFindingNewAgent: false,
            isNewAgentFoundProcessed: false,
            newAgentFound: {},
            feedback: {},
            previousEvents: []
        };
    };

    componentDidMount() {
        console.log("id ", this.props.id )
        this.loadEventById(this.props.id);
        this.props.dispatch(fetchEventTemplates());
        if (this.props.id) {
            this.props.dispatch(fetchEventNotes(this.props.id))
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedTab !== prevProps.selectedTab) {
            this.setState({
                selectedTab: this.props.selectedTab
            });
        }
        if (this.props.id !== prevProps.id) {
            this.setState({
                formLoadStatus: [0, 0, 0, 0, 0, 0, 0, 0, 0]
            });
            this.props.dispatch(fetchEventInfo(this.props.id))
        }
    }

    loadEventById = (id) => {
        this.props.dispatch(setEventLoadingStep());
        this.props.dispatch(fetchEventInfo(id));
    };

    updateVenueBundleAdminNote = (id, note) => {
        this.props.dispatch(updateVenueBundleAdminNote(id, note))
    };

    // todo be cached
    fetchBasicParameters = (city, next) => {
        callEventApi(`v3/dashboard/user/${city}/base-filters`, 'GET').then(res => {
            return next(null, res.data);
        });
    };

    // todo be cached
    fetchLocationParameters = (city, next) => {
        callEventApi(`v3/dashboard/user/${city}/subareas`, 'GET').then(res => {
            return next(null, res.data.subareas);
        });
    };

    // todo be cached
    fetchEventTemplates = (next) => {
        callEventApi(`v3/eventtemplates/full-list`, 'GET').then(res => {
            return next(null, res.data.eventtemplates);
        });
    };

    // todo be cached
    fetchContactsByVenueId = (venuecontact, next) => {
        callEventApi(`v3/admin-dashboard/venue/${venuecontact}/primary-contacts`, 'POST').then(res => {
            return next(null, res.data.contact);
        });
    };

    fetchPrimaryAssetsByVenueId = (venueId, next) => {
        callEventApi(`v3/admin-dashboard/venue/${venueId}/primary-assets`, 'get').then(res => {
            return next(null, res.data);
        });
    };

    // todo becached
    fetchPackagesByVenueId = (venueId, next) => {
        callEventApi(`v3/admin-dashboard/venue/${venueId}/primary-packages`, 'get').then(res => {
            return next(null, res.data.packages);
        });
    };

    // todo becached
    fetchMenusByVenueId = (venueId, next) => {
        callEventApi(`v3/admin-dashboard/venue/${venueId}/primary-menus`, 'get').then(res => {
            return next(null, res.data.menus);
        });
    };

    fetchSubpackagesByVenueId = (eventid, venueid, next) => {
        callEventApi(`v3/admin-dashboard/event/${eventid}/venue/${venueid}/user-packages`, 'get').then(res => {
            return next(null, res.data.subpackages);
        });
    };

    fetchVisitsByVenueId = (eventid, venueid, next) => {
        callEventApi(`v3/admin-dashboard/event/${eventid}/venue/${venueid}/user-visits`, 'get').then(res => {
            return next(null, res.data.visits);
        });
    };


    fetchEventStats = (eventid, next) => {
        callEventApi(`v3/admin-dashboard/event/${eventid}/stats`, 'get').then(res => {
            return next(null, res.data.stats);
        });
    };

    updateCustomerEventInfo = (id, event) => {
        this.props.dispatch(updateCustomerEventInfo(id, event))
    };

    updateFirstSeenEventStats = (id, next) => {
        callApi(`v2/eventstats/${id}/first-seen`, 'get').then(res => {
            return next(null, res.data.eventstats);
        });
    };

    fetchCorporateEventDetails = (corporateEventId, next) => {
        callEventApi(`v3/admin-dashboard/corporate-event/${corporateEventId}/info`, 'get').then(res => {
            return next(null, res.data.corporateevent);
        });
    };

//reloadBundle(this.state.bundleId, this.state.asset, this.state.venuebundleList, this.state.event._id)

    // reloadBundle = () => {
    //     let id = this.state.bundleId;
    //     this.setState({
    //         isReLoadingBundle: true,
    //         // assets: {}
    //     });
    //     this.fetchEventBundle(id, (err, bundle) => {
    //         if (bundle) {
    //             let assets = this.state.assets;
    //             let venueList = bundle && bundle.venueList ? bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
    //             let venuesNotFound = [];
    //             venueList.forEach((item) => {
    //                 if (!assets[item.uid._id]) {
    //                     venuesNotFound.push({
    //                         venueId: item.uid._id,
    //                         contactId: item.uid.venuecontact
    //                     });
    //                     assets[item.uid._id] = {};
    //                     assets[item.uid._id].menus = [];
    //                     assets[item.uid._id].contact = {};
    //                     assets[item.uid._id].packages = [];
    //                     assets[item.uid._id].subpackages = [];
    //                     assets[item.uid._id].visits = [];
    //                 }
    //             });
    //             this.setState({
    //                 isReLoadingBundle: false,
    //                 assets: assets,
    //                 venueList: venueList,
    //                 recoList: bundle.recoVenues,
    //                 prevEmails: bundle.prevemails
    //             }, () => {
    //                 async.each(venuesNotFound, (item, callback) => {
    //                     this.loadVenueSegmentById(item.venueId, item.venueBundle);
    //                     return callback();
    //                 }, (err) => {
    //                 });
    //             });
    //         }
    //     });
    // };

// Not called anywhere, only once which is commented

    // reloadBundleAfterPackageSent = (isTemplateTriggered) => {
    //     let id = this.state.bundleId;
    //     this.fetchEventBundle(id, (err, bundle) => {
    //         if (bundle) {
    //             this.setState({
    //                 venueList: bundle.venueList,
    //                 recoList: bundle.recoVenues,
    //                 prevEmails: bundle.prevemails
    //             }, () => {
    //                 let venueList = this.state.bundle && this.state.bundle.venueList ? this.state.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
    //                 this.loadContactsByVenue(venueList, (err, venues) => {

    //                 });
    //                 if (isTemplateTriggered) {
    //                     this.triggerTemplateTask();
    //                 }
    //             });
    //         }
    //     });
    // };

    shortlistRecoVenueFromVenueSelection = (bundleid, venueid) => {
        this.props.dispatch(shortlistRecoVenueFromVenueSelection(bundleid, venueid));
    };

    shortlistRecoVenue = (bundleid, venueid) => {
        //TODO v1 to v3 ritwik
        this.props.dispatch(shortlistRecoVenue(bundleid, venueid));
    };

    removeRecoVenue = (bundleid, venueid) => {
        //TODO v1 to v3 ritwik
        this.props.dispatch(removeRecoVenue(bundleid, venueid));
    };

    handleCityChange = (city) => {
        this.props.dispatch(handleCityChange(city))
    };

    sendEmailForAvailabilityToUser = (list) => {
        this.setState({
            isSendingUserEmail: true
        });
        let data = {
            customer_name: this.state.event.contact_name,
            customer_email: this.state.event.contact_email ? this.state.event.contact_email : '',
            agent_name: this.state.event.agent_name,
            agent_phone: this.state.event.agent_phone,
            agent_email: this.state.event.agent_email,
            agent_code: this.state.event.agent_code,
            enquiry_num: this.state.event.enquiry_num,
            event_id: this.state.event._id
        };
        data.venueList = list;
        callEventApi(`v3/events/${data.event_id}/admin/notify/availability`, 'POST', data).then(res => {
            this.setState({
                isSendingUserEmail: false
            });
            this.reloadBundle();
            this.props.showMessage('Mail Sent', 'success');
        });
    };


    sendEmailForPackageInfoToUser = (isTemplateTextToBeUsed, list) => {
        this.props.dispatch(sendEmailForPackageInfoToUser(isTemplateTextToBeUsed, list))
    };

    generateWhatsAppTemplateText = () => {
        let venueList = this.state.venueList.map(item => {
            if (!item.contact) {
                item.isSelected = false;
            } else if (item.contact) {
                item.isSelected = true;
            }
            return item;
        });
        let name = this.state.event && this.state.event.contact_name ? this.state.event.contact_name : '';
        let occasion = this.state.event && this.state.event.occasion && this.state.event.occasion[0] ? this.state.event.occasion[0] : '';

        return `Hi ${name}, \nGreetings from VenueMonk. \nBelow are the suggested venue options for you. Please shortlist your preferred venues. Once you shortlist the venues, we will work out the best package based on your customized requirement.  \n\n${venueList.filter(item => item.isSelected).map((venue, i) => `${venue.uid.name} \n ${venue.contact.first_name} ${venue.contact.last_name} \nContact: ${venue.contact.contact_phone} \nPackage:  ${venue.packagename} \nPackage Cost/Person: ${venue.price} \nVenue Link: ${venue.uid.shortlink} \n\n`).join('')}*Go Cashless & Win*
Book best venue for your ${occasion} online by securely paying just 20% of the total amount and get assured Amazon vouchers up to Rs. 3000 Free!
*VenueMonk Guarantee*- We Deliver What We Promise!\n
- VenueMonk.com (9717779251)`;

    };

    copyToClipboard = (text) => {
        const textField = document.createElement('textarea');
        textField.innerText = text;
        document.body.appendChild(textField);
        textField.select();
        var msg = document.execCommand('copy');
        if (msg) {
            this.props.showMessage('Copied');
        } else {
            this.props.showMessage('Try Again');
        }
        textField.remove();
    };

    triggerTemplateTask = () => {
        let text = this.generateWhatsAppTemplateText();
        this.copyToClipboard(text);
        window.open(`https://api.whatsapp.com/send?phone=91${this.state.event.contact_phone}&text=${encodeURI(text)}`, '_blank');
    };

    sendRepeatPackageEmail = (list, next) => {
        this.setState({
            isSendingUserEmail: true
        });
        let data = {
            customer_name: this.state.event.contact_name,
            customer_email: this.state.event && this.state.event.contact_email ? this.state.event.contact_email : '',
            agent_name: this.state.event.agent_name,
            agent_phone: this.state.event.agent_phone,
            enquiry_num: this.state.event.enquiry_num,
            event_id: this.state.event._id
        };
        data.venueList = list;
        callEventApi(`v3/dashboard/admin/${this.state.eventId}/bundle/${this.state.event.bundleId}/package-repeat-email`, 'POST', data).then(res => {
            this.setState({
                isSendingUserEmail: false
            });
            this.props.showMessage('Mail Sent', 'success', 3000);
        });
    };

    sendEmailForVisitInfoToUser = (list) => {
        this.props.dispatch(sendEmailForVisitInfoToUser(list))
    };

    notifyToVenues = (bundleid, list) => {
        this.props.dispatch(notifyToVenues(bundleid, list))
    };

    addRecoVenues = (bundleid, list) => {
        //TODO v1 to v3 ritwik
        this.props.dispatch(addRecoVenues(bundleid, list));
    };


    sendSmsToVenue = (id, text) => {
        this.props.dispatch(sendSmsToVenue(id, text))
    };

    sendSmsForUser = (bundleid, data) => {
        this.props.dispatch(sendSmsForUser(bundleid, data))
    };

    loadPrimaryAssetssByVenue = (venues, next) => {
        let assets = this.state.assets;
        async.each(venues, (item, callback) => {
            if (item && item.uid && item.uid._id) {
                this.fetchPrimaryAssetsByVenueId(item.uid._id, (err, data) => {
                    if (assets[item.uid._id]) {
                        assets[item.uid._id].contact = data && data.contact ? data.contact : {};
                        assets[item.uid._id].packages = data && data.packages ? data.packages : [];
                        assets[item.uid._id].menus = data && data.menus ? data.menus : [];
                    }
                    return callback();
                });
            } else {
                return callback();
            }
        }, (err) => {
            this.setState({
                assets: assets
            }, () => {
                return next(err, venues);
            });
        });
    };

    // loadVenuebundlesByVenuebundleId = (venues, next) => {
    //     let model = {};
    //     model.venuebundleList = [];
    //     console.log(venues);
    //     async.each(venues, (item, callback) => {
    //         if (item && item.venueBundle) {
    //             this.fetchVenueBundle(_.toString(item.venueBundle), (err, venuebundle) => {
    //                 venuebundle.venue_name = item.uid.name;
    //                 model.venuebundleList.push(venuebundle);
    //                 return callback();
    //             });
    //         } else {
    //             return callback();
    //         }
    //     }, (err) => {
    //         this.setState({
    //             venuebundleList: model.venuebundleList
    //         }, () => {
    //             return next(err, venues);
    //         });
    //     });
    // };

    loadContactsByVenue = (venues, next) => {
        let assets = this.state.assets;
        async.each(venues, (item, callback) => {
            if (item && item.uid && item.uid.venuecontact && assets[item.uid._id] && _.isEmpty(assets[item.uid._id].contact)) {
                this.fetchContactsByVenueId(item.uid.venuecontact, (err, contact) => {
                    assets[item.uid._id].contact = contact;
                    return callback();
                });
            } else {
                assets[item.uid._id].contact = !_.isEmpty(assets[item.uid._id].contact) ? assets[item.uid._id].contact : {};
                return callback();
            }
        }, (err) => {
            this.setState({
                assets: assets
            }, () => {
                return next(err, venues);
            });
        });
    };

    loadPackagesByVenue = (venues, next) => {
        let assets = this.state.assets;
        async.each(venues, (item, callback) => {
            if (item && item.uid !== '' && assets[item.uid._id] && _.isEmpty(assets[item.uid._id].packages)) {
                this.fetchPackagesByVenueId(item.uid._id, (err, packages) => {
                    assets[item.uid._id].packages = packages;
                    return callback();
                });
            } else {
                assets[item.uid._id].packages = !_.isEmpty(assets[item.uid._id].packages) ? assets[item.uid._id].packages : [];
                return callback();
            }
        }, (err) => {
            this.setState({
                assets: assets
            }, () => {
                return next(err, venues);
            });
        });
    };

    loadMenusByVenue = (venues, next) => {
        let assets = this.state.assets;
        async.each(venues, (item, callback) => {
            if (item && item.uid !== '' && assets[item.uid._id] && _.isEmpty(assets[item.uid._id].menus)) {
                this.fetchMenusByVenueId(item.uid._id, (err, menus) => {
                    assets[item.uid._id].menus = menus;
                    return callback();
                });
            } else {
                assets[item.uid._id].menus = !_.isEmpty(assets[item.uid._id].menus) ? assets[item.uid._id].menus : [];
                return callback();
            }
        }, (err) => {
            this.setState({
                assets: assets
            }, () => {
                return next(err, venues);
            });
        });
    };

    loadSubpackagesByVenue = (venues, next) => {
        let assets = this.state.assets;
        async.each(venues, (item, callback) => {
            if (item && item.uid !== '') {
                this.fetchSubpackagesByVenueId(this.state.event._id, item.uid._id, (err, subpackages) => {
                    if (subpackages) {
                        assets[item.uid._id].subpackages = subpackages.map(item => {
                            item.isPackageSelected = true;
                            return item;
                        });
                    } else {
                        assets[item.uid._id].subpackages = [];
                    }
                    return callback();
                });
            } else {
                assets[item.uid._id].subpackages = [];
                return callback();
            }
        }, (err) => {
            this.setState({
                assets: assets
            }, () => {
                return next(err, venues);
            });
        });
    };

    loadVisitsByVenue = (venues, next) => {
        let assets = this.state.assets;
        async.each(venues, (item, callback) => {
            if (item && item.uid !== '') {
                this.fetchVisitsByVenueId(this.state.event._id, item.uid._id, (err, visits) => {
                    assets[item.uid._id].visits = visits;
                    return callback();
                });
            } else {
                assets[item.uid._id].visits = [];
                return callback();
            }
        }, (err) => {
            this.setState({
                assets: assets
            }, () => {
                return next(err, venues);
            });
        });
    };

    // to do by Ritwik
    loadContacts = () => {
        let venues = this.state.venueList;
        this.loadContactsByVenue(venues, (err, venues) => {
            console.log(err);
            this.setState({
                venues: venues
            });
        });
    };

    removeShortlistVenue = (bundleid, venueid) => {
        this.props.dispatch(removeShortlistVenue(bundleid, venueid))
    };

    queryForVenue = (city, text) => {
        this.props.dispatch(queryForVenue(city, text))
    };

    convertLead = (eventid, feedback) => {
        this.props.dispatch(convertLead(eventid, feedback));
    };
// issue
    getPackageOptions = (input) => {
        if (!input) {
            return Promise.resolve({options: []});
        }
        let searchTerm = encodeURI(input);
        let URL = SEARCH_PACKAGE_API + searchTerm;
        return fetch(URL)
            .then((response) => response.json())
            .then((res) => {
                if (res.data) {
                    return {options: res.data.agents};
                } else {
                    return {options: []};
                }
            });
    };

    getAgentOptions = (input) => {
        if (!input) {
            return Promise.resolve({options: []});
        }
        let searchTerm = encodeURI(input);
        // todo revert the agent search api
        // todo use this method of id api call for agent search at all places
        return callIdApi(`v3/agents/search?term=${searchTerm}`, 'get').then((res) => {
            if (res.data) {
                return {options: res.data.agents};
            } else {
                return {options: []};
            }
        });
    };


    changeAgent = (id, agentid) => {
        this.props.dispatch(changeInsideAgent(id, agentid));
        this.props.close(true);
    };

    changeVisitAgent = (id, agentid) => {
        this.props.dispatch(addVisitAgent(id, agentid));
    }

    removeVisitAgent = (id, agentid) => {
        this.props.dispatch(updateVisitAgent(id, agentid));
    }

    showMenuForVenuePackage = (venueId, packageId, val) => {
        this.props.dispatch(showMenuForVenuePackage(venueId, packageId, val))
    };

    showVenueMenu = (venueId, val) => {
        this.props.dispatch(showVenueMenu(venueId, val));
    };

    clonePackage = (venueId, subId, pack) => {
        this.props.dispatch(clonePackage(venueId, subId, pack?pack:{}, this.props.event._id))
    };

    addPackage = (venueId, subId, subpackage) => {
        this.props.dispatch(addPackage(venueId, subId, subpackage))
    };

    updateSubpackage = (venueId, id, subpackage) => {
        this.props.dispatch(updateSubpackage(venueId, id, subpackage))
    };

    removeSubpackage = (venueId, subId, packageId) => {
        this.props.dispatch(removeSubpackage(venueId, subId, packageId))
    };

    editPackage = (pack) => {
        this.props.dispatch(editPackage(pack))
    };

    closeEditPackageForm = () => {
        this.props.dispatch(closeEditPackageForm())
    };

    showMenu = (pack) => {
        this.props.dispatch(showMenu(pack))
    };

    closeVenueMenu = () => {
        this.props.dispatch(closeVenueMenu())
    };


    planVisit = (venueId, visit) => {
        this.props.dispatch(planVisit(venueId, visit))
    };

    updateVisit = (venueId, id, visit) => {
        this.props.dispatch(updateVisit(venueId, id, visit))
    };

    removeVisit = (venueId, visitId) => {
        this.props.dispatch(removeVisit(venueId, visitId))
    };

    handleVenueVisitUpdate = (venueId, e, p) => {
        this.props.dispatch(handleVenueVisitUpdate(venueId, e, p))
    };

    updateAssetData = (venueId, moduleName, subId, keyName, val) => {
        this.props.dispatch(updateAssetData(venueId, moduleName, subId, keyName, val))
    };

    editVisit = (visit) => {
        this.props.dispatch(editVisit(visit))
    };

    closeEditVisitForm = () => {
        this.props.dispatch(closeEditVisitForm())
    };

    openScheduleVisit = (visitId) => {
        this.props.dispatch(openScheduleVisit(visitId))
    };

    closeScheduleVisitForm = () => {
        this.props.dispatch(closeScheduleVisitForm())
    };

    checkIfAdded = (packId, subpackages) => {
        if (subpackages && subpackages.length > 0) {
            let packs = subpackages.map(item => item.packageId);
            return packs.indexOf(packId) > -1;
        } else {
            return false;
        }
    };

    close = () => {
        this.props.close(false);
    };


    updateEvent = (id, event) => {
        this.props.dispatch(updateEvent(id, event))
    };

    handleLoadEventDetails = (eventId) => {
        let model = {};
        model.eventId = eventId;
        this.setState({
            isFetchingDetails: true,
        });

        if (model.eventId && model.eventId !== '') {
            async.series([
                (cb) => {
                    this.fetchEventInfo(model.eventId, (err, event) => {
                        if (err) {
                            return cb(err);
                        } else {
                            model.event = event;
                            return cb();
                        }
                    });
                },
                // (cb) => { // todo using in Proposels and
                //     if (model.event.bundleId) {
                //         this.fetchEventBundle(model.event.bundleId, (err, bundle) => {
                //             if (err) {
                //                 return cb(err);
                //             } else {
                //                 model.bundle = bundle;
                //                 return cb();
                //             }
                //         });
                //     } else {
                //         return cb();
                //     }
                // },
                (cb) => { // todo not using anywhere
                    if (model.event.eventstat) {
                        this.updateFirstSeenEventStats(model.eventId, (err, eventstat) => {
                            if (err) {
                                return cb(err);
                            } else {
                                model.eventstat = eventstat;
                                return cb();
                            }
                        });
                    } else {
                        return cb();
                    }
                },
                (cb) => {
                    if (model.event && model.event.city) {
                        this.fetchBasicParameters(model.event.city, (err, parameters) => {
                            if (err) {
                                return cb(err);
                            } else {
                                model.params = parameters;
                                return cb();
                            }
                        });
                    } else {
                        model.params = {};
                        return cb();
                    }
                },
                (cb) => {
                    if (model.event && model.event.city) {
                        this.fetchLocationParameters(model.event.city, (err, subareas) => {
                            if (err) {
                                return cb(err);
                            } else {
                                model.subareas = subareas;
                                return cb();
                            }
                        });
                    } else {
                        model.subareas = [];
                        return cb();
                    }
                },
                (cb) => {
                    if (model.event) {
                        this.fetchEventStats(model.event._id, (err, stats) => {
                            if (err) {
                                return cb(err);
                            } else {
                                model.eventStat = stats;
                                return cb();
                            }
                        });
                    } else {
                        return cb();
                    }
                }
            ], (err) => {
                if (err) {
                    console.log(err);
                    this.setState({
                        isFetchingDetails: false
                    });
                } else {
                    let location = model.subareas.map((item) => {
                        item.label = item.name;
                        item.value = item.sub_area;
                        return item
                    });
                    let cities = [];
                    if (model.params && model.params.cities && model.params.cities.length > 0) {
                        cities = model.params.cities.map((item) => {
                            item.label = item.name;
                            item.value = item.sname;
                            return item
                        });
                    }
                    //let assets = {};
                    // let venueList = model.bundle && model.bundle.venueList ? model.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                    // venueList.forEach(item => {
                    //     assets[item.uid._id] = {};
                    // });
                    this.setState({
                        event: model.event,
                        eventId: model.eventId,
                        bundleId: model.event.bundleId,
                        // bundle: model.bundle,
                        // eventtemplates: model.eventtemplates,
                        isMounted: true,
                        // venueList: venueList,
                        // assets: assets,
                        logId: model.event.userlog,
                        // recoList: model.bundle.recoVenues ? model.bundle.recoVenues : [],
                        // prevEmails: model.bundle.prevemails,
                        packages: model.params && model.params.packages ? model.params.packages : [],
                        occasions: model.params && model.params.occasions ? model.params.occasions : [],
                        venuetypes: model.params && model.params.venuetypes ? model.params.venuetypes : [],
                        cities: cities,
                        location: location,
                        isFetchingDetails: false,
                        eventStat: model.eventStat
                    });
                }
            });
        } else {
            this.setState({
                isFetchingDetails: false
            })
        }
    };

    handleRecommended = () => {
        let model = {};
        model.bundleId = this.state.event.bundleId;
        model.bundle = this.state.bundle;
        this.setState({
            isFetchingRecommended: true
        });

        if (model.bundleId && model.bundleId !== '') {
            async.series([
                (cb) => { // todo using in Proposels and other
                    if (model.bundleId && _.isEmpty(model.bundle)) {
                        this.fetchEventBundle(model.bundleId, (err, bundle) => {
                            if (err) {
                                return cb(err);
                            } else {
                                model.bundle = bundle;
                                return cb();
                            }
                        });
                    } else {
                        return cb();
                    }
                },
            ], (err) => {
                if (err) {

                } else {
                    let assets = {};
                    let venueList = model.bundle && model.bundle.venueList ? model.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                    venueList.forEach(item => {
                        assets[item.uid._id] = {};
                    });

                    this.setState({
                        bundle: model.bundle,
                        venueList: venueList,
                        assets: !_.isEmpty(this.state.assets) ? this.state.assets : assets,
                        recoList: model.bundle.recoVenues ? model.bundle.recoVenues : [],
                        prevEmails: model.bundle.prevemails,
                        isFetchingRecommended: false
                    })
                }
            });
        } else {
            this.setState({
                isFetchingRecommended: false
            })
        }
    };

    handleFetchSortlist = () => { // todo all assets are needed except visits
        let model = {};
        model.venueList = this.state.venueList;
        model.bundleId = this.state.event.bundleId;
        model.bundle = this.state.bundle;
        model.assets = this.state.assets;
        this.setState({
            isFetchingSortlist: true
        });

        async.series([
            (cb) => {
                if (model.bundleId && _.isEmpty(model.bundle)) {
                    this.fetchEventBundle(model.bundleId, (err, bundle) => {
                        if (err) {
                            return cb(err);
                        } else {
                            model.bundle = bundle;
                            let assets = {};
                            let venueList = model.bundle && model.bundle.venueList ? model.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                            venueList.forEach(item => {
                                assets[item.uid._id] = {};
                            });
                            model.venueList = venueList;
                            model.assets = assets;
                            this.setState({
                                assets: model.assets,
                                venueList: model.venueList
                            }, () => {
                                return cb();
                            });
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {
                if (model.venueList) {
                    let venueList = model.venueList;
                    this.loadPrimaryAssetssByVenue(venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {
                if (model.venueList) {
                    let venueList = model.venueList;
                    this.loadSubpackagesByVenue(venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            }
        ], (err) => {
            if (err) {
                console.log(err);
                this.setState({
                    isFetchingSortlist: false
                });
            } else {
                this.setState({
                    isLoading: false,
                    isVenueSegmentLoaded: true,
                    bundle: model.bundle,
                    assets: !_.isEmpty(this.state.assets) ? this.state.assets : model.assets,
                    bundleId: model.bundleId,
                    venueList: model.venueList,
                    recoList: model.bundle.recoVenues ? model.bundle.recoVenues : [],
                    prevEmails: model.bundle.prevemails,
                    isFetchingSortlist: false
                });
            }
        });
    };

    handleProposals = () => {
        let model = {};
        model.eventtemplates = [];
        model.venueList = this.state.venueList;
        model.bundleId = this.state.event.bundleId;
        model.bundle = this.state.bundle;
        model.assets = this.state.assets;
        this.setState({
            isFetchingProposal: true
        });

        async.series([
            (cb) => {
                if (model.bundleId && _.isEmpty(model.bundle)) {
                    this.fetchEventBundle(model.bundleId, (err, bundle) => {
                        if (err) {
                            return cb(err);
                        } else {
                            model.bundle = bundle;
                            let assets = {};
                            let venueList = model.bundle && model.bundle.venueList ? model.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                            venueList.forEach(item => {
                                assets[item.uid._id] = {};
                            });
                            model.venueList = venueList;
                            model.assets = assets;
                            this.setState({
                                assets: model.assets,
                                venueList: model.venueList
                            }, () => {
                                return cb();
                            });
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {  // todo using in Proposals
                this.fetchEventTemplates((err, eventtemplates) => {
                    if (err) {
                        return cb(err);
                    } else {
                        model.eventtemplates = eventtemplates;
                        return cb();
                    }
                });
            },
            (cb) => {
                if (model.venueList) {
                    let venueList = model.venueList;
                    this.loadPrimaryAssetssByVenue(venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {
                if (model.venueList) {
                    let venueList = model.venueList;
                    this.loadSubpackagesByVenue(venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            }
        ], (err) => {
            if (err) {
                this.setState({
                    isFetchingProposal: false
                })
            } else {
                this.setState({
                    isFetchingProposal: false
                })
            }
        })
    };

    handleVisits = () => { // todo assets => only visits and contactList needed
        let model = {};
        model.assets = this.state.assets;
        model.venueList = this.state.venueList;
        model.bundleId = this.state.event.bundleId;
        model.bundle = this.state.bundle;
        this.setState({
            isFetchingVisit: true
        });

        async.series([
            (cb) => {
                if (model.bundleId && _.isEmpty(model.bundle)) {
                    this.fetchEventBundle(model.bundleId, (err, bundle) => {
                        if (err) {
                            return cb(err);
                        } else {
                            model.bundle = bundle;
                            let assets = {};
                            let venueList = model.bundle && model.bundle.venueList ? model.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                            venueList.forEach(item => {
                                assets[item.uid._id] = {};
                            });
                            model.venueList = venueList;
                            model.assets = assets;
                            this.setState({
                                assets: model.assets,
                                venueList: model.venueList
                            }, () => {
                                return cb();
                            });
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {
                if (model.venueList) {
                    this.loadVisitsByVenue(model.venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {
                if (model.venueList) {
                    this.loadContactsByVenue(model.venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            }

        ], (err) => {
            if (err) {
                this.setState({
                    isFetchingVisit: false
                })
            } else {
                this.setState({
                    bundle: model.bundle,
                    bundleId: model.bundleId,
                    venueList: model.venueList,
                    assets: !_.isEmpty(this.state.assets) ? this.state.assets : model.assets,
                    recoList: model.bundle.recoVenues ? model.bundle.recoVenues : [],
                    prevEmails: model.bundle.prevemails,
                    isFetchingVisit: false
                });
            }
        });

    };

    handleInformVenue = () => {  // todo => contactList assets is only needed
        let model = {};
        model.assets = this.state.assets;
        model.venueList = this.state.venueList;
        model.bundleId = this.state.event.bundleId;
        model.bundle = this.state.bundle;
        this.setState({
            isFetchingInformVenue: true
        });

        async.series([
            (cb) => {
                if (model.bundleId && _.isEmpty(model.bundle)) {
                    this.fetchEventBundle(model.bundleId, (err, bundle) => {
                        if (err) {
                            return cb(err);
                        } else {
                            model.bundle = bundle;
                            let assets = {};
                            let venueList = model.bundle && model.bundle.venueList ? model.bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                            venueList.forEach(item => {
                                assets[item.uid._id] = {};
                            });
                            model.venueList = venueList;
                            model.assets = assets;
                            this.setState({
                                assets: model.assets,
                                venueList: model.venueList
                            }, () => {
                                return cb();
                            });
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {
                if (model.venueList) {
                    this.loadContactsByVenue(model.venueList, (err, venues) => {
                        if (err) {
                            return cb(err);
                        } else {
                            return cb();
                        }
                    });
                } else {
                    return cb();
                }
            },
            (cb) => {  // todo using in Proposals
                this.fetchEventTemplates((err, eventtemplates) => {
                    if (err) {
                        return cb(err);
                    } else {
                        model.eventtemplates = eventtemplates;
                        return cb();
                    }
                });
            }

        ], (err) => {
            if (err) {
                this.setState({
                    isFetchingInformVenue: false
                })
            } else {
                let eventtemplates = model.eventtemplates.filter(item => item.tag === 'whatsapp-text');
                let userSmsTemplates = model.eventtemplates.filter(item => item.tag === 'user-sms');
                let venueSmsTemplates = model.eventtemplates.filter(item => item.tag === 'venue-sms');
                this.setState({
                    eventtemplates: eventtemplates,
                    userSmsTemplates: userSmsTemplates,
                    venueSmsTemplates: venueSmsTemplates,
                    assets: !_.isEmpty(this.state.assets) ? this.state.assets : model.assets,
                    bundle: model.bundle,
                    bundleId: model.bundleId,
                    venueList: model.venueList,
                    recoList: model.bundle.recoVenues ? model.bundle.recoVenues : [],
                    prevEmails: model.bundle.prevemails,
                    isFetchingInformVenue: false
                });
            }
        });
    };

    handleTabChange = (key) => {
        this.props.dispatch(toggleEventModalForm(true, key));
    };

    queryBundle = (text) => {
        this.props.dispatch(queryBundle(text));
    };

    selectBundle = (bundlesetid) => {
        this.props.dispatch(selectBundle(bundlesetid));
    };

    handleScheduleVisit = (visit) => {
        this.props.dispatch(handleScheduleVisit(visit))
    };

    generateMagicToken = (eventId) => {
        callCustomerApi(`auth/generate-magic-token?eventId=${eventId}`, 'get').then((res) => {
            if (res && res.status === 'Success') {
                this.props.showMessage('Magic Token Created!', 'success');
            } else {
                this.props.showMessage('Failed Magic Token Creation!', 'error');
            }
        });
    }

    findNewAgent = (eventId) => {
        this.props.dispatch(findNewAgent(eventId))
    };

    clearAgent = () => {
        this.props.dispatch(clearAgent())
    };

    connectCall = (phone) => {
        this.props.dispatch(processCallToDialer(phone));
    }

    render() {
        // console.log("selectedcity",selectedcity);
        let Segment;
        if (isEmpty(this.props.event)) {
            Segment = <div style={{
                width: '100%',
                minHeight: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}><FadingCircleLoader size={42}/></div>;
        } else {
            const {
                event
            } = this.props;
            let occasiontypeOptions = this.props.occasions ? this.props.occasions : [];
            let citytypeOptions = this.props.cities ? this.props.cities : [];
            let packagetypeOptions = this.props.packages ? this.props.packages : [];
            let venuetypeOptions = this.props.venuetypes ? this.props.venuetypes : [];
            let locationtypeOptions = this.props.location ? this.props.location : [];
            let selectedoccasion = [];
            let selectedpackage = [];
            let selectedvenue = [];
            let selectedcity = [];
            let selectedlocation = [];
            this.props.event.occasion && this.props.event.occasion.map(function (item) {
                occasiontypeOptions.map(function (item2) {
                    if (item === item2.label) {
                        selectedoccasion.push(item2);
                    }
                });
            });
            packagetypeOptions.map(function (item2) {
                if (event.package_code && (parseInt(event.package_code) === parseInt(item2.value))) {
                    selectedpackage.push(item2);
                }
            });
            citytypeOptions.map(function (item2) {
                if (event.city && (event.city === item2.value)) {
                    selectedcity.push(item2);
                }
            });
            if (this.props.event.venuetype && this.props.event.venuetype.length > 0) {
                this.props.event.venuetype.map(function (item) {
                    venuetypeOptions.map(function (item2) {
                        if (item === item2.label) {
                            selectedvenue.push(item2);
                        }
                    });
                });
            }
            locationtypeOptions.map(function (item2) {
                if (event.location && (event.location === item2.value)) {
                    selectedlocation.push(item2);
                }
            });
            Segment = <Tabs
                activeKey={this.props.selectedTab}
                animation={false}
                id='noanim-tab-example'
                onSelect={this.handleTabChange}
            >
                <Tab eventKey={6} title='Event Details'>
                    {
                        this.props.isLoading ?
                            <div style={{
                                width: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><FadingCircleLoader size={42}/></div> :
                            <React.Fragment>
                                <EventEditForm
                                    corporateEvent={this.props.corporateEvent}
                                    connectCall={this.connectCall}
                                    isUpdating={this.props.isUpdatingEventInfo}
                                    isFindingNewAgent={this.props.isFindingNewAgent}
                                    isNewAgentFoundProcessed={this.props.isNewAgentFoundProcessed}
                                    newFoundAgent={this.props.newFoundAgent}
                                    id={this.props.event._id}
                                    customerEventId={this.props.event.customerEventId}
                                    event={this.props.event}
                                    occasiontypes={occasiontypeOptions}
                                    selectedoccasion={selectedoccasion ? selectedoccasion : []}
                                    packagetypes={packagetypeOptions}
                                    selectedpackage={selectedpackage ? selectedpackage : []}
                                    venuetypes={venuetypeOptions}
                                    selectedvenue={selectedvenue ? selectedvenue : []}
                                    locationtypes={locationtypeOptions}
                                    selectedlocation={selectedlocation ? selectedlocation : []}
                                    citytypes={citytypeOptions}
                                    selectedcity={selectedcity ? selectedcity : ''}
                                    update={this.updateCustomerEventInfo}
                                    handleCityChange={this.handleCityChange}
                                    changeAgent={this.changeAgent}
                                    changeVisitAgent={this.changeVisitAgent}
                                    removeVisitAgent={this.removeVisitAgent}
                                    getAgentOptions={this.getAgentOptions}
                                    findNewAgent={this.findNewAgent}
                                    clearAgent={this.clearAgent}
                                    userPermissions={this.props.userPermissions}
                                    previousEvents={this.props.previousEvents}
                                    showMessage={this.props.showMessage}
                                    eventStat = {this.props.eventStat}
                                    totalAgentMap = {this.props.totalAgentMap}
                                />
                                {/* {!_.isEmpty(this.props.eventStat) ?
                                    <div className="panel panel-default">
                                        <div className="panel-heading">
                                            Event Stats
                                        </div>
                                        <table className="table">
                                            <thead>
                                            <tr>
                                                <th>
                                                    Parameter
                                                </th>
                                                <th>
                                                    Value
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr>
                                                <td>
                                                    Landing Page
                                                </td>
                                                <td>
                                                    {this.props.eventStat.landingPage}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Lead Fill Page
                                                </td>
                                                <td>
                                                    {this.props.eventStat.leadFillPage}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    PopUp Count
                                                </td>
                                                <td>
                                                    {this.props.eventStat.popupCount}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Time Before Form Filled (s)
                                                </td>
                                                <td>
                                                    {this.props.eventStat.submitDiff}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    UTM Media
                                                </td>
                                                <td>
                                                    {this.props.eventStat.utm_media}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    UTM Source
                                                </td>
                                                <td>
                                                    {this.props.eventStat.utm_source}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div> :
                                    null
                                } */}
                            </React.Fragment>
                    }
                </Tab>
                <Tab eventKey={1} title='Recommended'>
                    {
                        this.props.isLoading ?
                            <div style={{
                                width: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><FadingCircleLoader size={42}/></div> :
                            <div style={{position: 'relative', display: 'block', width: '100%'}}>
                                <RecoVenueCombined
                                    event={this.props.event}
                                    assets={this.props.assets}
                                    bundleid={this.props.event.bundleId}
                                    selectedoccasion={selectedoccasion ? selectedoccasion : []}
                                    selectedcity={selectedcity ? selectedcity : ''}
                                    selectedpackage={selectedpackage ? selectedpackage : []}
                                    venues={this.props.recoListCombined}
                                    shortlistVenue={this.shortlistRecoVenue}
                                    removeVenue={this.removeRecoVenue}
                                />
                                <AddRecoVenueForm
                                    event={this.props.event}
                                    bundleid={this.props.bundleId}
                                    venues={this.props.queryList}
                                    cities={this.props.cities}
                                    query={this.queryForVenue}
                                    selectVenue={this.addRecoVenues}
                                    shortlistVenue={this.shortlistRecoVenueFromVenueSelection}
                                />
                                <RecoVenues
                                    bundleid={this.props.event.bundleId}
                                    venues={this.props.recoList}
                                    shortlistVenue={this.shortlistRecoVenue}
                                    removeVenue={this.removeRecoVenue}
                                />
                                <AddBundleSetForm
                                    queryBundle={this.queryBundle}
                                    selectBundle={this.selectBundle}
                                    queryBundleSet={this.props.queryBundleSet}
                                />
                            </div>
                    }
                </Tab>
                <Tab eventKey={2} title='Shortlisted'>
                    {
                        this.props.isLoading ?
                            <div style={{
                                width: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><FadingCircleLoader size={42}/></div> :
                            <ShortlistedVenues
                                connectCall={this.connectCall}
                                bundleid={this.props.bundleId}
                                venues={this.props.venueList}
                                assets={this.props.assets}
                                removeVenue={this.removeShortlistVenue}
                                showMenuForVenuePackage={this.showMenuForVenuePackage}
                                showVenueMenu={this.showVenueMenu}
                                clonePackage={this.clonePackage}
                                checkIfAdded={this.checkIfAdded} // TODO utility
                                showMessage={this.props.showMessage}

                                id={this.props.selectedPackage._id}
                                venueId={this.props.selectedPackage.venueId}
                                isSaving={this.props.isSavingPackage}
                                closeEditPackageForm={this.closeEditPackageForm}
                                isEditingPackage={this.props.isEditingPackage}

                                menus={this.props.selectedMenus}
                                package={this.props.selectedPackage}
                                closeVenueMenu={this.closeVenueMenu}
                                isViewingPackageMenu={this.props.isViewingPackageMenu}

                                eventId={this.props.event._id}
                                eventtemplates={this.props.eventtemplates}
                                event={this.props.event}
                                send={this.sendEmailForPackageInfoToUser}
                                isSending={this.props.isSendingUserEmail}
                                getPackageOptions={this.getPackageOptions}
                                addPackage={this.addPackage}
                                editPackage={this.editPackage}
                                showMenu={this.showMenu}
                                removeSubpackage={this.removeSubpackage}
                                updatePackage={this.updateSubpackage}
                                updateAssetData={this.updateAssetData}

                                sendEmail={this.sendEmailForVisitInfoToUser}
                                editVisit={this.editVisit}
                                openScheduleVisit={this.openScheduleVisit}
                                removeVisit={this.removeVisit}
                                updateVisit={this.updateVisit}

                                planVisit={this.planVisit}
                                totalAgentMap = {this.props.totalAgentMap}

                                closeEditVisitForm={this.closeEditVisitForm}
                            />
                    }
                </Tab>
                {/* <Tab eventKey={4} title='Visits'>
                    {
                        this.props.isLoading ?
                            <div style={{
                                width: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><FadingCircleLoader size={42}/></div> :
                            <div style={{position: 'relative', display: 'block', width: '100%'}}>
                                {this.props.isEditingVisit ?
                                    <EditVisitSection
                                        updateVisit={this.updateVisit}
                                        id={this.props.selectedVisit._id}
                                        venueId={this.props.selectedVisit.venueId}
                                        visit={this.props.selectedVisit}
                                        isSaving={this.props.isSavingVisit}
                                        closeEditVisitForm={this.closeEditVisitForm}
                                        showMessage={this.props.showMessage}
                                    /> :
                                    null
                                }
                                {
                                    this.props.isSchedulingVisit ?
                                        <ScheduleVisitSection
                                            visitId={this.props.scheduleVisitId}
                                            showMessage={this.props.showMessage}
                                            handleScheduleVisit={this.handleScheduleVisit}
                                            closeEditVisitForm={this.closeScheduleVisitForm}
                                        />
                                        : null
                                }
                                <BookedVisitsInfo
                                    // connectCall={this.props.connectCall}
                                    // eventId={this.props.event._id}
                                    // venues={this.props.venueList}
                                    // assets={this.props.assets}
                                    // event={this.props.event}
                                    // bundleid={this.props.event.bundleId}
                                    showMessage={this.props.showMessage}
                                    send={this.sendEmailForVisitInfoToUser}
                                    // isSending={this.props.isSendingUserEmail}
                                    editVisit={this.editVisit}
                                    openScheduleVisit={this.openScheduleVisit}
                                    removeVisit={this.removeVisit}
                                    updateVisit={this.updateVisit}
                                    updateAssetData={this.updateAssetData}
                                />
                                <VisitInfo
                                    eventId={this.props.event._id}
                                    agentId={this.props.event.agent_id}
                                    venues={this.props.venueList}
                                    planVisit={this.planVisit}
                                />
                            </div>
                    }
                </Tab> */}
                <Tab eventKey={5} title='Inform Venue'>
                    {
                        this.props.isLoading ?
                            <div style={{
                                width: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><FadingCircleLoader size={42}/></div> :
                            <div style={{position: 'relative', display: 'block', width: '100%'}}>
                                <PartnerVenueLeads
                                    eventId={this.props.id}
                                    bundleid={this.props.bundleId}
                                    venuebundleList={this.props.venuebundleList}
                                    send={this.notifyToVenues}
                                    updateVenueBundleAdminNote={this.updateVenueBundleAdminNote}
                                    isSending={this.props.isInformingVenue}
                                />
                                <InformVenue
                                    eventId={this.props.id}
                                    bundleid={this.props.bundleId}
                                    venues={this.props.venueList}
                                    send={this.notifyToVenues}
                                    isSending={this.props.isInformingVenue}
                                />
                                <SmsVenueSegment
                                    venues={this.props.venueList}
                                    assets={this.props.assets}
                                    event={this.props.event}
                                    venueSmsTemplates={this.props.venueSmsTemplates}
                                    userSmsTemplates={this.props.userSmsTemplates}
                                    bundleid={this.props.event.bundleId}
                                    showMessage={this.props.showMessage}
                                    sendSms={this.sendSmsToVenue}
                                    sendUserSms={this.sendSmsForUser}
                                />
                            </div>
                    }
                </Tab>
                <Tab eventKey={7} title='Close - Won'>
                    {
                        this.props.isShowCloseWonBell ?
                            <CloseWonBell/>
                            :
                            <div style={{width: '100%', height: '100%'}}>
                                {
                                    this.props.isLoading ?
                                        <div style={{
                                            width: '100%',
                                            minHeight: '300px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}><FadingCircleLoader size={42}/></div> :
                                        <div style={{position: 'relative', width: '100%'}}>
                                            <LeadConversionForm
                                                event={this.props.event}
                                                bundleid={this.props.event.bundleId}
                                                venues={this.props.venueList}
                                                eventid={this.props.event._id}
                                                status={this.props.event.status}
                                                agentid={this.props.event.agent_id}
                                                userid={this.props.event.user}
                                                showMessage={this.props.showMessage}
                                                updateEvent={this.updateEvent}
                                                convertLead={this.convertLead}
                                                isSending={this.props.isClosingLead}
                                            />
                                            <EventFeedback
                                                feedback={this.props.feedback}
                                            />
                                        </div>
                                }
                            </div>
                    }
                </Tab>
                <Tab eventKey={8} title='Logs'>
                    {
                        this.props.isFetchingLogs ?
                            <div style={{
                                width: '100%',
                                minHeight: '300px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}><FadingCircleLoader size={42}/></div> :
                            <div style={{position: 'relative', display: 'block', width: '100%'}}>
                                <div className='row'>
                                    <div className='col-xs-12'>
                                        <div className='text-center'>
                                            <Button
                                                onClick={() => this.props.dispatch(loadLogSegment(this.props.event))}
                                            >
                                                Load
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <EmailSent
                                    mails={this.props.emailtasks}
                                />
                                <SmsSent
                                    smstasks={this.props.smstasks}
                                />
                                <PrevEmails
                                    isSending={this.props.isSendingUserEmail}
                                    send={this.sendRepeatPackageEmail}
                                    mails={this.props.prevEmails ? this.props.prevEmails : []}
                                />
                                <EventTasks
                                    tasks={this.props.tasks}
                                    newtask={this.props.openAddTaskModal}
                                    enableTaskAddition={false}
                                />
                                <EventLogs
                                    logs={this.props.logs}
                                />
                            </div>
                    }
                </Tab>
            </Tabs>
        }
        return (
            <Modal
                bsSize='large'
                onHide={this.close}
                show={this.props.showModal}
                className={"vm-modal-z-index"}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {this.props.eventId
                            ?
                            <React.Fragment>
                                <a href={`/manager/events/${this.props.eventId}/update`}>Event
                                    <i className='fa fa-external-link'></i>
                                </a>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <i style={{color: "#63CAF8"}} class="fa fa-solid fa-clone"
                                   onClick={() => this.copyToClipboard(this.props.event.magicToken)}></i>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button>
                                    <i className="fa fa-refresh" aria-hidden="true"
                                       onClick={() => this.generateMagicToken(this.props.event._id)}></i>
                                </Button>
                            </React.Fragment> :
                            null
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {Segment}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => this.props.close(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

function mapStateToProps(state, props) {
    return {
        id: getSelectedEventId(state),
        event: getEvent(state),
        isLoading: getEventLoadingState(state),
        bundleId: getBundleId(state),
        bundle: getBundle(state),
        logId: getLogId(state),
        eventtemplates: getEventtemplates(state),
        userSmsTemplates: getUserSmsTemplates(state),
        venueSmsTemplates: getVenueSmsTemplates(state),
        venueList: getVenueList(state),
        assets: getAsset(state),
        recoList: getRecoList(state),
        prevEmails: getPrevEmails(state),
        packages: getPackages(state),
        occasions: getOccasions(state),
        venuetypes: getVenuetypes(state),
        cities: getCities(state),
        location: getLocation(state),
        eventStat: getEventStat(state),
        corporateEvent: getCorporateEvent(state),
        feedback: getFeedback(state),
        previousEvents: getPreviousEvents(state),
        venuebundleList: getVenuebundleList(state),
        isFetchingLogs: getFetchingLogsStatus(state),
        smstasks: getSmstasks(state),
        emailtasks: getEmailtasks(state),
        tasks: getTasks(state),
        logs: getLogs(state),
        isClosingLead: getIsClosingLead(state),
        isShowCloseWonBell: getIsShowCloseWonBell(state),
        isReLoadingBundle: getReloadingBundleStatus(state),
        newFoundAgent: getNewFoundAgent(state),
        isNewAgentFoundProcessed: getIsNewAgentFoundProcessed(state),
        isFindingNewAgent: getIsFindingNewAgent(state),
        isUpdatingEventInfo: getIsUpdatingEventInfo(state),
        isInformingVenue: getIsInformingVenue(state),
        isSendingUserEmail: getIsSendingUserEmail(state),
        isEditingVisit: getIsEditingVisit(state),
        isSchedulingVisit: getIsSchedulingVisit(state),
        selectedVisit: getSelectedVisit(state),
        scheduleVisitId: getScheduleVisitId(state),
        isSavingVisit: getIsSavingVisit(state),
        selectedMenus: getSelectedMenus(state),
        isEditingPackage: getIsEditingPackage(state),
        isViewingPackageMenu: getIsViewingPackageMenu(state),
        selectedPackage: getSelectedPackage(state),
        isSavingPackage: getIsSavingPackage(state),
        options: getAgentOptions(state),
        isSendingSmsToUser: getIsSendingSmsToUser(state),
        isSendingSmsToVenue: getIsSendingSmsToVenue(state),
        queryList: getQueryList(state),
        queryBundleSet: getQueryBundleSet(state),
        recoListCombined: getRecoListCombined(state),
        totalAgentMap : getTotalAgentMap(state),
    }
}

export default connect(mapStateToProps)(EventModalForm);


