import React from 'react';
import ReactDOM from 'react-dom';
import packageJson from './meta.json';
import App from './App'; // change to MegaApp Later
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import {Provider} from 'react-redux';
import store from './store';
global.appVersion = packageJson.version;
ReactDOM.render(<Provider store={store()}>
<React.StrictMode>
    <App/>
</React.StrictMode>
</Provider>, document.getElementById('root'));
registerServiceWorker();
