import callApi, {callApiAndCache} from "../../util/apiCaller";
import async from "async";
import moment from 'moment';
import callEventApi from "../../util/eventApiCaller";

export const UPDATING_TASKS_INTO_LIST = "UPDATING_TASKS_INTO_LIST";
export const UPDATE_TASKS_INTO_LIST = "UPDATE_TASKS_INTO_LIST";
export const FETCH_AGENT_LIST = "FETCH_AGENT_LIST";
export const FETCH_FUNNEL_DATA = "FETCH_FUNNEL_DATA";
export const FETCH_TASK_CONTENT = "FETCH_TASK_CONTENT";
export const FETCH_TASK_STATS = "FETCH_TASK_STATS";
export const FETCH_CURRENT_TASK = "FETCH_CURRENT_TASK";
export const FETCH_BASIC_PARAMETERS = "FETCH_BASIC_PARAMETERS";
export const FETCH_LOCATION_PARAMETERS = "FETCH_LOCATION_PARAMETERS";
export const NEW_EVENT_CREATED = "NEW_EVENT_CREATED";
export const UPDATE_EVENT = "UPDATE_EVENT";
export const UPDATE_CUSTOMER_EVENT_INFO = "UPDATE_CUSTOMER_EVENT_INFO";
export const FETCH_EVENT_INFO = "FETCH_EVENT_INFO";
export const LOAD_CUSTOMER_EVENT_NOTE_LIST = "LOAD_CUSTOMER_EVENT_NOTE_LIST";
export const ADD_CUSTOMER_EVENT_NOTE_LIST = "ADD_CUSTOMER_EVENT_NOTE_LIST";
export const REMOVE_CUSTOMER_EVENT_NOTE_LIST = "REMOVE_CUSTOMER_EVENT_NOTE_LIST";
export const UPDATE_CUSTOMER_EVENT_NOTE_LIST = "UPDATE_CUSTOMER_EVENT_NOTE_LIST";

export function fetchSalesAgentQueueData(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            isFetching: true
        });
        return callApi(
            `v3/admin-dashboard/agent/${agentId}/sales-task-list`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.tasks,
                    taskCount: res.data.total,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

export function fetchSalesAgentQueueDataV2(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            isFetching: true
        });
        return callEventApi(
            `v3/admin-dashboard/agent/${agentId}/leads-list-v3`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.events,
                    taskCount: res.data.total,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

// v2 api todo sudha to replace
export function fetchSalesAgentCallingLogicData(agentId, filters) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_TASKS_INTO_LIST,
            isFetching: true
        });
        return callApi(
            `v3/admin-dashboard/agent/${agentId}/v2-sales-task-list`,
            "POST",
            filters
        ).then((res) => {
            if (res.status === "Success") {
                dispatch({
                    type: UPDATE_TASKS_INTO_LIST,
                    tasks: res.data.tasks,
                    taskCount: res.data.total,
                    isFetching: false
                });
            } else {
                dispatch({
                    type: UPDATING_TASKS_INTO_LIST,
                    isFetching: false
                });
            }
        });
    };
}

export function fetchFunnelData(name) {
    return (dispatch) => {
        callEventApi(`v3/funnels/funnel/by-name`, "POST", {name: name}).then((res) => {
                if (res.status === "Success") {
                    dispatch({
                        type: FETCH_FUNNEL_DATA,
                        funnel_data: res.data,
                    });
                }
                ;
            }
        );
    }
}

function fetchContentByCode(code, next) {
    callApiAndCache(`v2/contents/code/${code}/`, "GET")
        .then((res) => {
            if (res.status === "Success") {
                return next(null, res.data);
            } else {
                return next(res.data);
            }
        })
        .catch((err) => {
            return next(err);
        });
}

export function fetchTaskStaticContent(id) {
    return (dispatch) => {
        let model = {};
        async.series(
            [
                (cb) => {
                    fetchContentByCode("sales-task-list", (err, data) => {
                        if (err) {
                            return cb();
                        } else {
                            console.log(data);
                            model.salestask = data.content.params.map((item) => item.label);
                            return cb();
                        }
                    });
                },
                (cb) => {
                    fetchContentByCode("closed-lost-reasons", (err, data) => {
                        if (err) {
                            console.log("error", err);
                            return cb();
                        } else {
                            console.log("closed-lost-reasons",data.content.params);
                            model.closedLostReasons = data.content.params;
                            return cb();
                        }
                    });
                },
                (cb) => {
                    fetchContentByCode("calling-status-list", (err, data) => {
                        if (err) {
                            return cb();
                        } else {
                            model.callingStatusList = data.content.params;
                            return cb();
                        }
                    });
                },
            ],
            (err) => {
                if (err) {
                    console.log("err" + err);
                } else {
                    dispatch({
                        type: FETCH_TASK_CONTENT,
                        taskContent: model,
                    });
                }
            }
        );
    };
}

export function fetchTaskStats(id) {
    return (dispatch) => {
        let model = {};
        async.series(
            [
                (cb) => {
                    fetchSalesAgentScores(id, (err, data) => {
                        if (err) {
                            return cb(err);
                        } else {
                            if (data && data.scores) {
                                model.scores = data.scores;
                                return cb();
                            } else {
                                return cb();
                            }
                        }
                    });
                },
                (cb) => {
                    handleFetchCallStats(id, (err, data) => {
                        if (err) {
                            model.agentCallData = {};
                            return cb();
                        } else {
                            model.agentCallData = data.callinfo;
                            return cb();
                        }
                    });
                },
                (cb) => {
                    handleFetchAgentStats(id, (err, data) => {
                        if (err) {
                            model.agentStats = {};
                            return cb();
                        } else {
                            model.agentStats = data.agentStats;
                            return cb();
                        }
                    });
                },
            ],
            (err) => {
                if (err) {
                    console.log("err" + err);
                } else {
                    dispatch({
                        type: FETCH_TASK_STATS,
                        taskStats: model,
                    });
                }
            }
        );
    };
};

function handleFetchCallStats(id, next) {
    if (id) {
        let startDate = moment().startOf("month");
        let endDate = moment().endOf("month");
        callEventApi(`v3/agentcalls/${id}/call-info`, "post", {
            startDate: startDate.toISOString(),
            endDate: endDate.toISOString(),
        }).then((res) => {
            if (res.status === "Success") {
                return next(null, res.data);
            } else {
                return next(res);
            }
        });
    }
}

function handleFetchAgentStats(id, next) {
    if (id) {
        callApi(
            `v3/admin-dashboard/agent/${id}/sales-funnel-agent-stats`,
            "post",
            {}
        ).then((res) => {
            if (res.status === "Success") {
                return next(null, res.data);
            } else {
                return next(res);
            }
        });
    }
};

function fetchSalesAgentScores(agentId, next) {
    callEventApi(`v3/admin-dashboard/agent/${agentId}/sales-funnel-task-count`, 'GET')
        .then(res => {
            if (res.status === 'Success') {
                return next(null, res.data);
            } else {
                return next(res.data);
            }
        }).catch(err => {
        return next(err);
    });
};

export function fetchCurrentTask(agentId, filters) {
    return dispatch => {
        return callEventApi(`v3/admin-dashboard/agent/${agentId}/sales-task-list`, 'POST', filters)
            .then(res => {
                if (res.status === 'Success') {
                    dispatch({
                        type: FETCH_CURRENT_TASK,
                        current_task: res.data,
                    });
                }
            })
    }
};

export function fetchBasicParameters(city) {
    return (dispatch) => {
        return callEventApi(`v3/dashboard/user/${city}/base-filters`, 'GET').then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_BASIC_PARAMETERS,
                    baseParams: res.data,
                });
            }
        });
    }
}

export function fetchLocationParameters(city) {
    return (dispatch) => {
        callEventApi(`v3/dashboard/user/${city}/subareas`, 'GET').then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_LOCATION_PARAMETERS,
                    subAreas: res.data.subareas,
                });
            }
        });
    };
};

export function createNewEvent(event) {
    return (dispatch) => {
        return callEventApi(`v3/events/plan/new`, 'post', event).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: NEW_EVENT_CREATED,
                    newEvent: true,
                });
            } else {
                dispatch({
                    type: NEW_EVENT_CREATED,
                    newEvent: false,
                });
            }
        });
    };
};

export function updateEvent(eventId, event, items) {
    return (dispatch) => {
        return callEventApi(`v3/admin-dashboard/event/${eventId}/event-update`, 'POST', {
            event: event,
        }).then(res => {
            if (res.status === 'Success') {
                let tasks = items.map(item => {
                    if (item._id === eventId) {
                        return res.data.event;
                    } else {
                        return item;
                    }
                });
                dispatch({
                    type: UPDATE_EVENT,
                    items: tasks,
                });
            }
        })
    };
};

export function updateCustomerEventInfo(event, _id, stateEvent) {
    return (dispatch) => {
        return callEventApi(`v3/admin-dashboard/customer-event/${_id}/event-update`, 'POST', {
            event: event
        }).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: UPDATE_CUSTOMER_EVENT_INFO,
                    event: res.data.event ? res.data.event : stateEvent,
                });
            }
        });
    };
};

export function fetchEventInfo(id) {
    return (dispatch) => {
        let model = {};
        model.eventId = id;
        callEventApi(`v3/admin-dashboard/event/${id}`, "GET").then((res) => {
            if (res.status === "Success") {
                model.event = res.data.event;
                dispatch({
                    type: FETCH_EVENT_INFO,
                    currentTaskEvent: model.event,
                });
            }
        });
    };
};
