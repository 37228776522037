import React, {Component} from 'react';
import {
    Button,
    FormGroup,
    FormControl,
    ControlLabel,
    Row,
    Col
} from 'react-bootstrap';

class AddBundleSetForm extends Component {

    constructor() {
        super();
        this.state = {
            qtext: "",
            city: ""
        };
    };

    componentDidMount() {
        // this.setState({
        //     city: this.capitalizeFirstLetter(this.props.event.city)
        // });
    }

    componentWillReceiveProps(nextProps) {
        // if(this.props.event.city !== nextProps.event.city) {
        //     this.setState({
        //         city: this.capitalizeFirstLetter(nextProps.event.city)
        //     });
        // }
    }


    changeCity = (e) => {
        this.setState({
            city: e.target.value
        });
    };

    queryText = (e) => {
        if (e.target.value && e.target.value !== '') {
            this.props.queryBundle( e.target.value);
        }
    };
    //
    // select = (id) => {
    //     let list = [];
    //     list.push({
    //         uid: id
    //     });
    //     this.props.selectVenue(this.props.bundleid, list);
    // };

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    render() {
        return (
            <div className="list-group-item">
                <Row>
                    {/*<Col xs={3}>*/}
                    {/*    <FormGroup>*/}
                    {/*        <ControlLabel>*/}
                    {/*            City*/}
                    {/*        </ControlLabel>*/}
                    {/*        <FormControl*/}
                    {/*            componentClass="select"*/}
                    {/*            placeholder="code"*/}
                    {/*            value={this.state.city}*/}
                    {/*            inputRef={ref => {*/}
                    {/*                this.queryCity = ref;*/}
                    {/*            }}*/}
                    {/*            onChange={this.changeCity}*/}
                    {/*        >*/}
                    {/*            <option value=""></option>*/}
                    {/*            {this.props.cities.map(item => (*/}
                    {/*                <option key={item.value} value={item.label}>{item.label}</option>*/}
                    {/*            ))}*/}
                    {/*        </FormControl>*/}
                    {/*    </FormGroup>*/}
                    {/*</Col>*/}
                    <Col xs={9}>
                        <FormGroup>
                            <ControlLabel>
                                Type Bundle name here
                            </ControlLabel>
                            <FormControl
                                type="text"
                                placeholder="search text"
                                defaultValue={this.state.qtext}
                                inputRef={ref => {
                                    this.querytext = ref;
                                }}
                                onChange={this.queryText}
                            >
                            </FormControl>
                        </FormGroup>
                    </Col>
                </Row>
                <div style={{maxHeight: "250px", overflowY: "scroll"}}>
                    {this.props.queryBundleSet && this.props.queryBundleSet.length > 0 &&
                    this.props.queryBundleSet.map(item => (
                        <Row key={item._id} style={{borderBottom: '1px solid #cdcdcd', padding: '3px', marginBottom: '5px'}}>
                            <Col xs={2}>
                                {item.name}
                            </Col>
                            <Col xs={2}>
                                {item.description}
                            </Col>
                            <Col xs={6}>
                                <div style={{width: '100%'}}>
                                    {
                                        item.venueList && item.venueList.length > 0 && item.venueList.map(venue => (
                                            <span style={{padding: '1px 2px', border: '1px solid #cdcdcd', borderRadius: '5px', marginRight: '3px'}}>{`${venue.uid && venue.uid.name ? venue.uid.name : ''}, `}</span>
                                        ))
                                    }
                                </div>
                            </Col>
                            <Col xs={2}>
                                <Button
                                    disabled={item.isLoading}
                                    onClick={() => this.props.selectBundle(item._id)}
                                >{item.isLoading ? 'Selecting' : 'Select'}</Button>
                            </Col>
                        </Row>
                    ))}
                </div>
            </div>
        );
    }
}

export default AddBundleSetForm;


