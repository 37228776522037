import React, {Component} from 'react';
import {
    FormControl,
    Button,
    ButtonGroup
} from 'react-bootstrap';
import shortid from 'shortid';
import _ from 'lodash';
import moment from "moment";
import Handlebars from 'handlebars';
import he from 'he';
import {connect} from 'react-redux';
import {
    getAsset,
    getBundle,
    getBundleId,
    getCities,
    getCorporateEvent,
    getEmailtasks,
    getEvent,
    getEventLoadingState,
    getEventStat,
    getEventtemplates,
    getFeedback,
    getFetchingLogsStatus,
    getIsClosingLead,
    getIsFindingNewAgent,
    getIsNewAgentFoundProcessed,
    getIsShowCloseWonBell,
    getIsUpdatingEventInfo,
    getLocation,
    getLogId,
    getLogs,
    getNewFoundAgent,
    getOccasions,
    getPackages,
    getPrevEmails,
    getPreviousEvents,
    getRecoList,
    getReloadingBundleStatus,
    getSelectedEventId,
    getSmstasks,
    getTasks,
    getUserSmsTemplates,
    getVenuebundleList,
    getVenueList,
    getVenueSmsTemplates,
    getVenuetypes,
    getIsInformingVenue,
    getIsSendingUserEmail,
    getIsEditingVisit,
    getIsSchedulingVisit,
    getSelectedVisit,
    getScheduleVisitId,
    getIsSavingVisit,
    getSelectedMenus,
    getIsEditingPackage,
    getIsViewingPackageMenu,
    getSelectedPackage,
    getIsSavingPackage,
    getIsSendingSmsToUser,
    getIsSendingSmsToVenue,
    getQueryList,
    getQueryBundleSet,
    getRecoListCombined,
    getSelectedVenue
} from "../../../redux/admin/EventReducer";

import {CopyToClipboard} from 'react-copy-to-clipboard';

class SingleVenueSubPackage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            packages: [],
            packname: "",
            packprice: "",
            isTemplateTextToBeUsed: true,
            templates: [
                {
                    label: 'Whatsapp Text',
                    value: 1
                },
                {
                    label: 'First Call - DNP',
                    value: 2
                },
                {
                    label: 'First Call - DNP | First Approach',
                    value: 3
                },
                {
                    label: 'FollowUp - Options Sent',
                    value: 4
                },
                {
                    label: 'FollowUp - Visit Scheduled',
                    value: 5
                },
                {
                    label: 'FollowUp - Venue Visit',
                    value: 6
                },
                {
                    label: 'FollowUp - Feedback',
                    value: 7
                },
                {
                    label: 'NoReply from Customer - 1',
                    value: 8
                },
                {
                    label: 'NoReply from Customer - 2',
                    value: 9
                }
            ],
            isTemplateViewVisible: false,
            smstext: '',
            selectedTemplate: ''
        };
    }

    componentDidMount() {
        let venues = [];
        // this.props.venueList.forEach(item => {
        //     let venue = {};
        //     venue.venueId = this.props.selectedVenueID;
        //     venue.selected = true;
        //     this.props.assets[this.props.selectedVenueID].subpackages.forEach(subpackage => {
        //         venue.packageId = subpackage._id;
        //         venues.push(venue);
        //     });
        // });
        this.setState({
            venues: venues
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.venues) {
            let venues = [];
            // nextProps.venues.forEach(item => {
            //     let venue = {};
            //     venue.venueId = this.props.selectedVenueID;
            //     venue.selected = true;
            //     this.props.assets[this.props.selectedVenueID].subpackages.forEach(subpackage => {
            //         venue.packageId = subpackage._id;
            //         venues.push(venue);
            //     });
            // });
            this.setState({
                venues: venues
            });
        }
    }

    handleSelectionChange = (venueId, subId, val) => {
        this.props.updateAssetData(venueId, 'subpackages' , subId, 'isPackageSelected', val);
    };

    handlePackName = (id, val) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.packname = val.name;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    handlePackPrice = (id, e) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.price = e.target.value;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    handleCommonPackage = (val) => {
        this.setState({
            packname: val.name
        });
    };

    handleCommonPrice = (e) => {
        this.setState({
            packprice: e.target.value
        });
    };

    flowPackage = () => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            item.packname = this.state.packname;
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    flowPrice = () => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            item.price = this.state.packprice;
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    sendEmail = () => {
        let packageList = [];
        this.props.venueList.forEach(venue => {
            if(!_.isEmpty(this.props.assets[venue.uid._id])) {
                this.props.assets[venue.uid._id].subpackages.filter(item => item.isPackageSelected).map(item => {
                    packageList.push({
                        packageId : item._id,
                        venueId: venue.uid._id
                    });
                });
            }
        });
        this.props.send(this.state.isTemplateTextToBeUsed, packageList);
    };

    toggleTemplateCopy = () => {
        this.setState({
            isTemplateTextToBeUsed: !this.state.isTemplateTextToBeUsed
        })
    };

    toggleUserShow = (venueId, subId, val) => {
        let subpackage = {};
        subpackage.userShow = !val;
        this.props.updatePackage(venueId, subId, subpackage);
    };

    getInputTemplateData = () => {
        let input = {};
        let event = _.clone(this.props.event);
        input.event = event;
        input.event.occasion = event.occasion[0] ? event.occasion[0] : '';
        input.event.venuetype = event.venuetype[0] ? event.venuetype[0] : '';
        input.event.event_date = event.event_date ? moment(event.event_date).format('DD MMM YYYY') : '';
        input.event.city = this.props.event.city ? _.startCase(this.props.event.city) : '';
        input.event.agent_name = this.props.activeAgent.name;
        input.event.agent_phone = this.props.activeAgent.phone;
        input.event.agent_email = this.props.activeAgent.email;
        let venueList = {};
        this.props.venueList.filter(data => data._id == this.props.selectedVenueID).forEach(venue => {
            if(!_.isEmpty(this.props.assets) && !_.isEmpty(this.props.assets[venue.uid._id])) {
                this.props.assets[venue.uid._id].subpackages.filter(item => item.isPackageSelected).map(item => {
                    if (venueList[venue.uid._id] && venueList[venue.uid._id].subpackages) {
                        venueList[venue.uid._id].subpackages.push({
                            packagename: item.name,
                            price: item.package_price,
                        });
                    } else {
                        venueList[venue.uid._id] = {
                            venueId: venue.uid._id,
                            packageId: item._id,
                            venueName: venue.uid.name,
                            manager_name: venue.manager_name,
                            manager_phone: venue.manager_phone,
                            shortlink: venue.uid.shortlink,
                            menuLink : venue.uid.menuLink,
                            visitDate: `${moment(venue.visit_date).format('Do MMM YYYY')} - ${moment(venue.visit_date).format('HH:mm')}`
                        };
                        venueList[venue.uid._id].subpackages = [];
                        venueList[venue.uid._id].subpackages.push({
                            packagename: item.name,
                            price: item.package_price,
                        });
                        if (!_.isEmpty(this.props.assets[venue.uid._id]) && !_.isEmpty(this.props.assets[venue.uid._id].contact)) {
                            venueList[venue.uid._id].contactName = `${this.props.assets[venue.uid._id].contact.first_name}`;
                            venueList[venue.uid._id].contactPhone = `${this.props.assets[venue.uid._id].contact.contact_phone}`;
                        } else {
                            venueList[venue.uid._id].contactName = '';
                            venueList[venue.uid._id].contactPhone = '';
                        }
                    }
                });
            }
        });
        let venues = [];
        for (let key in venueList) {
            venues.push(venueList[key]);
        }
        input.venues = venues;
        input.selectedVenueCount = _.keys(venueList).length;
        return input;
    };

    handleNewTemplateChange = (description, template) => {
        let input = this.getInputTemplateData();
        let templateBody = Handlebars.compile(template);
        let smstext = templateBody(input).toString();
        smstext = he.decode(smstext);
        this.setState({
            selectedTemplate: description,
            smstext: smstext
        });
    };

    toggleTemplateView = () => {
        this.setState({
            isTemplateViewVisible: !this.state.isTemplateViewVisible
        });
    };

    copyToClipboard = str => {
        const el = document.createElement('textarea');
        el.value = str;
        el.setAttribute('readonly', '');
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        document.body.appendChild(el);
        const selected =
            document.getSelection().rangeCount > 0
                ? document.getSelection().getRangeAt(0)
                : false;
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        if (selected) {
            document.getSelection().removeAllRanges();
            document.getSelection().addRange(selected);
        }
        this.props.showMessage('Copied');
    };

    handleSmsTextChange = (e) => {
        this.setState({
            smstext: e.target.value
        });
    };

    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Proposed Packages
                </div>
                {
                    this.state.isTemplateViewVisible ?
                        <div className={'panel-body'}>
                            <div className="row">
                                <div className="col-xs-4">
                                    {this.props.eventtemplates && this.props.eventtemplates.map(item => (
                                        <Button
                                            className={'full-width'}
                                            onClick={() => this.handleNewTemplateChange(item.description, item.template)}
                                        >
                                            <span>{item.description}</span>
                                        </Button>
                                    ))}
                                </div>
                                <div className="col-xs-8">
                                    <form className="form-horizontal tabular-form">
                                        <div className="form-group">
                                            <div className="col-xs-12">
                                                {this.state.selectedTemplate}
                                            </div>
                                            <div className="col-sm-12 tabular-border">
                                                <FormControl
                                                    onChange={(e) => this.handleSmsTextChange(e)}
                                                    style={{minHeight: "430px"}}
                                                    className="full-width"
                                                    componentClass="textarea"
                                                    value={this.state.smstext}
                                                />
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>:
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Select</th>
                                <th>Source</th>
                                {/* <th>Venue Details</th> */}
                                <th>User Show</th>
                                <th>Package</th>
                                <th>Price</th>
                                <th>Menu</th>
                                <th>Edit</th>
                                <th>Remove</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.venueList && this.props.venueList.length < 1 ? <tr><td>No Venues</td></tr> : <tr></tr>}
                            {/* <tr><td>No Venues</td></tr> */}
                            {this.props.venueList && this.props.venueList.filter(data => data._id == this.props.selectedVenueID).map((item) => (
                                this.props.assets && this.props.assets[item.uid._id] && this.props.assets[item.uid._id].subpackages && this.props.assets[item.uid._id].subpackages.length > 0 ?
                                    this.props.assets[item.uid._id].subpackages.map(subpackage => (
                                        <tr className="mngevent-email-details" key={shortid.generate()}>
                                            <td>
                                                <Button
                                                    onClick={() => this.handleSelectionChange(item.uid._id, subpackage._id, !subpackage.isPackageSelected)}
                                                    className={"btn-default"}
                                                >
                                                    {subpackage.isPackageSelected ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                                                </Button>
                                            </td>
                                            <td>
                                                {subpackage.isCreatedByAdmin ? <span className="badge badge-primary">Admin</span> : null}
                                                {subpackage.isCreatedByVenue ? <span className="badge badge-danger">Venue</span> : null}
                                                {subpackage.isCreatedByUser ? <span className="badge badge-info">User</span> : null}
                                            </td>
                                            {/* <td>
                                                {item.uid.name}, {item.uid.sub_area}, {item.uid.city} <br/>
                                                {this.props.assets[item.uid._id] && this.props.assets[item.uid._id].contact ? `${this.props.assets[item.uid._id].contact.first_name} ${this.props.assets[item.uid._id].contact.last_name}` : ""} ,
                                                {this.props.assets[item.uid._id] && this.props.assets[item.uid._id].contact ?
                                                    <a onClick = {() => this.props.connectCall(this.props.assets[item.uid._id].contact.contact_phone)}>
                                                        <i style={{color: "#491369"}} className={"fa fa-phone"} />
                                                    </a>
                                                     : ""}
                                            </td> */}
                                            <td>
                                                <Button
                                                    onClick={() => this.toggleUserShow(item.uid._id, subpackage._id, !!subpackage.userShow)}
                                                >{subpackage.userShow ? 'Yes' : 'No'}</Button>
                                            </td>
                                            <td>
                                                {subpackage.name}
                                            </td>
                                            <td>
                                                {subpackage.package_price}
                                            </td>
                                            <td>
                                                {
                                                    subpackage.isMenuAvailable ?
                                                        <React.Fragment>
                                                            <Button
                                                                onClick={() => this.props.showMenu(subpackage)}
                                                            >View</Button>
                                                        </React.Fragment>
                                                        :
                                                        <i className="fa fa-times" />
                                                }
                                            </td>
                                            <td>
                                                <Button
                                                    onClick={() => this.props.editPackage(subpackage)}
                                                ><i className="fa fa-edit" />
                                                </Button>
                                            </td>
                                            <td>
                                                <Button
                                                    disabled={item.isDeletingPackage}
                                                    onClick={() => this.props.removeSubpackage(item.uid._id, item._id, subpackage._id)}
                                                >{item.isDeletingPackage ? <i className="fa fa-refresh fa-spinner" /> : <i className="fa fa-trash-o" />}
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                    :
                                    null
                            ))}
                            </tbody>
                        </table>

                }
                <div>

                </div>
            </div>
        );
    }

}

function mapStateToProps(state, props) {
    return {
        id: getSelectedEventId(state),
        event: getEvent(state),

        bundleId: getBundleId(state),
        bundle: getBundle(state),

        userSmsTemplates: getUserSmsTemplates(state),
        venueSmsTemplates: getVenueSmsTemplates(state),
        venueList: getVenueList(state),
        assets: getAsset(state),
        recoList: getRecoList(state),
        prevEmails: getPrevEmails(state),
        packages: getPackages(state),
        occasions: getOccasions(state),
        venuetypes: getVenuetypes(state),
        cities: getCities(state),
        location: getLocation(state),
        eventStat: getEventStat(state),
        corporateEvent: getCorporateEvent(state),
        feedback: getFeedback(state),
        previousEvents: getPreviousEvents(state),
        venuebundleList: getVenuebundleList(state),
        isFetchingLogs: getFetchingLogsStatus(state),

        emailtasks : getEmailtasks(state),
        tasks : getTasks(state),
        logs : getLogs(state),
        isClosingLead: getIsClosingLead(state),
        isShowCloseWonBell: getIsShowCloseWonBell(state),
        isReLoadingBundle: getReloadingBundleStatus(state),
        newFoundAgent: getNewFoundAgent(state),
        isNewAgentFoundProcessed: getIsNewAgentFoundProcessed(state),
        isFindingNewAgent: getIsFindingNewAgent(state),
        isUpdatingEventInfo: getIsUpdatingEventInfo(state),
        isInformingVenue : getIsInformingVenue(state),
        isSendingUserEmail : getIsSendingUserEmail(state),
        isEditingVisit : getIsEditingVisit(state),
        isSchedulingVisit : getIsSchedulingVisit(state),
        selectedVisit : getSelectedVisit(state),
        scheduleVisitId : getScheduleVisitId(state),
        isSavingVisit : getIsSavingVisit(state),
        selectedMenus : getSelectedMenus(state),
        isEditingPackage : getIsEditingPackage(state),
        isViewingPackageMenu : getIsViewingPackageMenu(state),
        selectedPackage: getSelectedPackage(state),
        isSavingPackage : getIsSavingPackage(state),
        isSendingSmsToUser: getIsSendingSmsToUser(state),
        isSendingSmsToVenue: getIsSendingSmsToVenue(state),
        queryList: getQueryList(state) ,
        selectedVenueID: getSelectedVenue(state)
    }
}

export default connect(mapStateToProps)(SingleVenueSubPackage);
