import React, { Component } from 'react';
import Select from 'react-select';
import moment from 'moment';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
// import DateRangePicker from 'react-bootstrap-daterangepicker';
// import { DateRangePicker } from 'react-date-range';
import { DateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Grid from '@material-ui/core/Grid';
import { TextField } from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { connect } from 'react-redux';
import { getEvent, getSelectedVenue, getVenueList } from '../../../redux/admin/EventReducer';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { getTotalAgentMap } from '../../../redux/admin/UserReducer';
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

class VisitInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            visit: {},
            date: new Date(),
            visit_agent_id:''
        };
    }


    componentDidMount() {
        let venues = [];
        venues = this.props.venueList.filter(data => data._id == this.props.selectedVenueID).map(item => {
            item.selected = true;
            return item;
        });
        this.setState({
            venues: venues
        });
        this.getVisitAgentOptionsList(this.props.totalAgentMap, this.props.events);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.venueList !== this.props.venueList || prevProps.selectedVenueID !== this.props.selectedVenueID  ) {
            let venues = [];
            venues = this.props.venueList.filter(data => data._id == this.props.selectedVenueID).map(item => {
                item.selected = true;
                return item;
            });
            this.setState({
                venues: venues
            });
        }
        if(prevProps.events !== this.props.events){
            this.getVisitAgentOptionsList(this.props.totalAgentMap, this.props.events);
        }
    }
    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.venues) {
    //         let venues = [];
    //         venues = nextProps.venues.map(item => {
    //             item.selected = true;
    //             return item;
    //         });
    //         this.setState({
    //             venues: venues
    //         });
    //     }
    // }
    
    getVisitAgentOptionsList = (totalAgentMap, event) => {
    let visitAgentsOptions = [];
    if(this.props.event && this.props.event.visit_agents && this.props.event.visit_agents.length > 0){
        visitAgentsOptions = this.props.event.visit_agents.map((id) => {
            return {
                label: totalAgentMap[id]?.name,
                value: id,
            }
        })
    }
    if(this.props.event && this.props.event.agent_id){
        visitAgentsOptions.push({
            label: this.props.event.agent_name,
            value:  this.props.event.agent_id
        })
    }
    this.setState({
        visitAgentsOptions: visitAgentsOptions
    })
}

    // handleOnAgentChange = (value) => {
    //     console.log("value", value)
    //     let id = value[0].value ? value[0].value : "";
    //     this.setState({
    //         visit_agent_id: id
    //     });
    // }

    handleOnAgentChange = (value) => {
        let oldAgentId = this.state.visit_agent_id;
        let newAgentId = [];
        if (oldAgentId.length < 1) {
            newAgentId = value;
        } else {
            value.forEach(function (item1) {
                let ifExists = false;
                oldAgentId.forEach(function (item2) {
                    if (item1.label !== item2.label) {
                        ifExists = true;
                    }
                });
                if (ifExists) {
                    newAgentId.push(item1);
                }
            });
        }
        this.setState({
            visit_agent_id: newAgentId
        });
    };

    onDateChange = (id, value, dateString) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.visit_date = dateString;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
      };

    planVisit = (venueId) => {
        if(this.state.visit_agent_id && this.state.visit_agent_id.length > 0){
            let venueList = this.state.venues.filter(item => item.uid._id === venueId);
            let visit = {};
            let date = new Date(venueList[0].visit_date);
            let visit_date = date.toISOString();
            visit.agentId = this.state.visit_agent_id[0].value;
            visit.eventId = this.props.event._id;
            visit.venueId = venueId;
            visit.visit_date = visit_date;
            visit.isCreatedByAdmin = true;
            visit.isConfirmedByVenue = true;
            visit.isConfirmedByUser = true;
            visit.venue_name = this.state.venues[0].venue_name;
            this.props.planVisit(venueId, visit);
        }else{
            this.props.showMessage("Select Agent first!")
        }
    };

    updateVisitDate = (venueId, id, date) => {
        let visit = {};
        visit.visit_date = date;
        this.props.updateVisit(venueId, id, visit);
    };

    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Add Visits
                </div>
                <table className="table">
                    <thead>
                        <tr>
                            <th style={{ width: '50px' }}>Venue Name</th>
                            <th style={{ width: '50px' }}>Venue Agents</th>
                            <th style={{ width: '150px' }}>Scheduled Visit</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.venues.length < 1 ? <tr>
                            <td>No Venues</td>
                        </tr> : <tr></tr>}
                        {this.state.venues.map((item) => (
                            <tr className="mngevent-email-details" key={item._id}>
                                <td>
                                    {item.uid.name}
                                </td>
                                <td>
                                    <Select
                                        name="Agents"
                                        placeholder="Agents"
                                        options={this.state.visitAgentsOptions}
                                        multi={true}
                                        openOnFocus={true}
                                        value={this.state.visit_agent_id}
                                        onChange={this.handleOnAgentChange}
                                        clearable={false}
                                    />
                                </td>
                                <td>
                                    <div className="col-xs-10">
                                        {/* <TextField
                                            id="datetime-local"
                                            label="Next appointment"
                                            type="datetime-local"
                                            defaultValue= {moment().toString()}
                                            value={item.visit_date}
                                            onChange={(e)=>this.handleDateChange(item._id, e.target.value)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            // minDateTime={dayjs().minute(59)}
                                            // minDateTime={moment().add(1, 'hours')}
                                        /> */}

                                        <Space direction="vertical" size={12}>
                                            <DatePicker 
                                                showTime 
                                                onChange={(value, dateString) => this.onDateChange(item._id, value, dateString)} 

                                                />
                                        </Space>
                                    </div>
                                    <div className="col-xs-2">
                                        <Button
                                            disabled={item.isPlanningVisit}
                                            onClick={() => this.planVisit(item.uid._id)}
                                        >
                                            {item.isPlanningVisit ?
                                                <i className="fa fa-refresh fa-spin" /> :
                                                <i className="fa fa-plus" />
                                            }
                                        </Button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

}

function mapStateToProps(state, props) {
    return {
        event: getEvent(state),
        venueList: getVenueList(state),
        selectedVenueID: getSelectedVenue(state),
        totalAgentMap: getTotalAgentMap(state)
    }
}

export default connect(mapStateToProps)(VisitInfo);
