import React, { Component } from 'react';

class CloseWonBell extends Component{
    constructor(props){
        super(props);
    }

    render() {
        return(
            <div style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: '#fff',
                minHeight: '400px', background: 'linear-gradient(#e66465, #9198e5)', textAlign: 'center'
            }}>
                <div style={{color: '#f0ecf6', fontFamily: 'cursive', fontSize: '40px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}> {"It's Bell Time!"} </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '80%'}}>
                    <img style={{width: '200px', height: '200px', borderRadius: '100px'}} src={`https://cdn.venuemonk.com/images/bell.gif`} alt="Won Bell"/>
                </div>
            </div>
        );
    }
}

export default CloseWonBell;
