import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {Button, FormControl, InputGroup} from 'react-bootstrap';
import {addEventNote, updateEventNote} from "../../../../redux/admin/EventActions";
import {getActiveAgentId} from "../../../../redux/admin/UserReducer";
import {getEventCreationState} from "../../../../redux/admin/EventReducer";

export default function AddEventNote({event_id, editNote, isUpdatingNote}) {

    useEffect(() => {
        setText(editNote?.text);
    }, [editNote])

    const [text, setText] = useState(editNote?.text);

    const isCreatingNote = useSelector(state => getEventCreationState(state));
    const agent_id = useSelector(state => getActiveAgentId(state));

    const dispatch = useDispatch();

    const addNote = () => {
        if (text) {
            if (editNote) {
                let note = {};
                note.text = text;
                dispatch(updateEventNote(event_id, editNote._id, note))
            } else {
                let note = {};
                note.agent_id = agent_id;
                note.event_id = event_id;
                note.text = text;
                dispatch(addEventNote(event_id, note))
            }
        }
    };

    return (<div id="useremail-packages" className="panel panel-default">
        <div className="panel-heading">
            {editNote ? "Edit Note" : "Add Note"}
        </div>
        <table className="table">
            <thead>
            <tr>
                <th style={{width: '150px'}}>Text</th>
            </tr>
            </thead>
            <tbody>
            <tr className="mngevent-email-details" key={'1'}>
                <td>
                    <div className="col-xs-10">
                            <textarea
                                className={'form-control'}
                                id="datetime-local"
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                                // minDateTime={dayjs().minute(59)}
                                // minDateTime={moment().add(1, 'hours')}
                                style={{minHeight: '200px', minWidth: '300px'}}
                            />
                    </div>
                    <div className="col-xs-2">
                        <Button
                            disabled={isUpdatingNote || isCreatingNote}
                            onClick={() => addNote()}
                        >
                            {isUpdatingNote || isCreatingNote ? <i className="fa fa-refresh fa-spin"/> : <>   <p>{editNote ? 'Save' : 'Add'}</p></>}
                        </Button>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
    </div>);
}
