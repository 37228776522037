import React from 'react'
import {Skeleton, Space} from "antd";

const CustomSkeletonLoader = (props) => {
    let LOADER_ARRAY = new Array(11).fill(0)

    return(
            <>
                <Space direction={'vertical'} size={'middle'} style={{width: '100%', marginTop: '16px', overflow: 'hidden'}}>
                    <Skeleton.Input block={true} style={{height: '62px'}} size={"large"} active={true} />
                    <Skeleton.Input block={true} style={{height: '62px'}} size={"large"} active={true} />
                    <Skeleton.Input block={true} style={{height: '62px'}} size={"large"} active={true} />
                </Space>
                <Space direction={'vertical'} size={'middle'} style={{width: '100%', marginTop: '20px', alignItems: 'flex-end'}}>
                    <Skeleton.Button block={true} size={"default"} active={true} style={{width: '110px'}}/>
                </Space>
                <Skeleton.Input style={{marginTop: '20px', height: '62px'}} block={true} size={"large"} active={true} />
                <Space direction={'horizontal'} size={'small'} style={{width: '100%', marginTop: '10px', wordWrap: 'wrap', flexWrap: 'wrap'}}>
                        {
                            LOADER_ARRAY.map(item => {
                                return LOADER_ARRAY.map(_ => <Skeleton.Input block={false} size={"small"} style={{height: '14px', width: '10px !important', marginTop: '10px'}} active={true} />)
                            })
                        }
                </Space>
            </>
    )
}

export default CustomSkeletonLoader
