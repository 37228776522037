import React from 'react';
import moment from 'moment';
import {
    Button
} from 'react-bootstrap';

function PrevMails(props) {
    if (props.mails && props.mails.length > 0) {
        return (
            <div className="panel panel-default">
                <div className="panel-heading" style={{color: "#000"}}>
                    Email To Users
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Sent Date</th>
                        <th>Venue Name</th>
                        <th>Address</th>
                        <th>Package</th>
                        <th>Price</th>
                    </tr>
                    </thead>
                    {props.mails.map((mail) => (
                        <tbody key={mail._id}>
                        <tr>
                            <td>{moment(mail.heading).format("Do MMM YYYY")}</td>
                            <td>
                                <Button
                                    disabled={props.isSending}
                                    onClick={() => props.send(mail.list)}
                                    className="btn btn-md vm-orange-btn">
                                    {props.isSending ? "Sending Mail" : "Send Again"}
                                </Button>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        {mail.list.map(item => (
                            <tr className="prev-details" key={item._id}>
                                <td><i className="prev-city hide">{item.city}</i><i
                                    className="prev-vmid hide">{item.vm_id}</i></td>
                                <td className="prev-venuename">{item.venuename}</td>
                                <td className="prev-address">{item.address}</td>
                                <td className="prev-packagename">{item.packagename}</td>
                                <td className="prev-price">{item.price}</td>
                            </tr>
                        ))}
                        </tbody>
                    ))}
                </table>
            </div>
        );
    } else {
        return (
            <div className="panel panel-default">
                <div className="panel-heading" style={{color: "#000"}}>
                    Email To Users
                </div>
                <div className="panel-body">
                    <div className="text-center">
                        No Previous Mails
                    </div>
                </div>
            </div>
        );
    }
}

export default PrevMails;
