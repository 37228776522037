import React, { Component } from 'react';
import _ from 'lodash';
import Pubnub from 'pubnub';
import { connect } from 'react-redux';
import callEventApi from './util/eventApiCaller';

import { fetchSalesAgentQueueData, fetchSalesAgentQueueDataV2 } from './redux/admin/SalesAgentQueueActions';
import { CHANGE_AGENT_FOR_EVENT, IS_USER_ONLINE_OFFLINE, NEW_EVENT, SHORTLIST_VENUE_BY_CUSTOMER , AGENT_LOGOUT} from "./constants/realtimeUpdates";
import EventModalForm from './components/EventModalForm/EventModalForm';
import { closeEditPackageForm, closeQueryForVenueList, fetchEventInfo, toggleEventModalForm } from './redux/admin/EventActions';
import { getEventModalState, getEventSelectedTab } from './redux/admin/EventReducer';
import {setAgent} from "./redux/admin/UserActions";
import { getActiveAgent } from './redux/admin/UserReducer';

export const GLOBAL_CHANNEL = 'global';

class Connector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            credentials: {}
        }
    }

    componentDidMount() {
        callEventApi(`config`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                const credentials = {
                    publishKey: res.data.pubnub_publisher_key,
                    subscribeKey: res.data.pubnub_subscriber_key,
                    uuid: `agent-${this.props.agentid}`
                }
                this.setState({ credentials }, () => {
                    const hereNowObject = {
                        channels: [this.props.agentid, GLOBAL_CHANNEL],
                        includeUUIDs: true,
                        includeState: false
                    };
                    const pubnub = new Pubnub(this.state.credentials);
                    // pubnub.hereNow(hereNowObject)
                    //     .then(success => {
                    //         this.props.dispatch(fetchOnlineUsers(success))
                    //     }).catch(err => {
                    //         console.log('error occured while getting online users >> pubnub', err)
                    //     });
                    // pubnub.subscribe({
                    //     channels: [this.props.agentid, GLOBAL_CHANNEL]
                    // });
                    pubnub.addListener({
                        message: (msg) => {
                            this.getMessages(msg.message)
                        },
                    })
                })
            }
        }).catch(err => {
            if (err) {
                console.log(err);
            }
        })
    }

    logoutTheAgent(agentId) {
        if (agentId === this.props.agentid) {
            localStorage.clear();
            this.isAuthenticated = false;
            window.location = '/';
        }
    }


    checkForOnlineUsers(eventId) {
        const hereNowObject = {
            channels: [this.props.agentid, GLOBAL_CHANNEL],
            includeUUIDs: true,
            includeState: false
        };
        const pubnub = new Pubnub(this.state.credentials);
        // pubnub.hereNow(hereNowObject)
        //     .then(success => {
        //         this.props.dispatch(fetchOnlineUsers(success))
        //     }).catch(err => {
        //         console.log('error occured while getting online users >> pubnub', err)
        //     });
    }

    getMessages = msg => {
        switch (msg.event) {
            case AGENT_LOGOUT:
                this.logoutTheAgent(msg.agent_id)
                break;

                case NEW_EVENT:
                    {
                        this.props.activeAgent?.sales_app_version === 'v2' ?
                        this.props.dispatch(fetchSalesAgentQueueDataV2(this.props.agentid, {
                            "pageNum": 1,
                            "pageSize": 50,
                            "isMoreFiltersAllowed": false,
                            "isSearching": false
                        }))
                        :
                        this.props.dispatch(fetchSalesAgentQueueData(this.props.agentid, {
                            "pageNum": 1,
                            "pageSize": 50,
                            "isMoreFiltersAllowed": false,
                            "isSearching": false
                        }))
                    }
                    break;
    
                case SHORTLIST_VENUE_BY_CUSTOMER:
                    this.props.dispatch(fetchEventInfo(msg.event_id))
                    break;
    
                case CHANGE_AGENT_FOR_EVENT:
                    {
                        this.props.activeAgent?.sales_app_version === 'v2' ?
                            this.props.dispatch(fetchSalesAgentQueueDataV2(this.props.agentid, {
                                "pageNum": 1,
                                "pageSize": 50,
                                "isMoreFiltersAllowed": false,
                                "isSearching": false
                            }))
                            :
                        this.props.dispatch(fetchSalesAgentQueueData(this.props.agentid, {
                            "pageNum": 1,
                            "pageSize": 50,
                            "isMoreFiltersAllowed": false,
                            "isSearching": false
                        }))
                    }
                    break;
                    case IS_USER_ONLINE_OFFLINE:
                        this.checkForOnlineUsers(msg.event_id)
                        break;

            default: {
                return null
            }
        }
    }

    closeEventModal = (isTableRefreshNeeded) => {
        this.props.dispatch(toggleEventModalForm(false, 1));
        this.props.dispatch(closeEditPackageForm());
        this.props.dispatch(closeQueryForVenueList());
    };


    render() {
        return (
            <div style={{ color: "green" }}>
                {this.props.isEventModalVisible ?
                    <EventModalForm
                        selectedTab={this.props.selectedTab}
                        showModal={this.props.isEventModalVisible}
                        close={this.closeEventModal}
                        showMessage={this.props.showMessage}
                        userPermissions={this.props.userPermissions}
                        cities={this.props.cities}
                    />
                    :
                    null
                }
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isEventModalVisible: getEventModalState(state),
        selectedTab: getEventSelectedTab(state),
        activeAgent: getActiveAgent(state)
    }
}

export default connect(mapStateToProps)(Connector);