import React, {useState} from "react";
import {Button} from "react-bootstrap";
import moment from "moment/moment";
import {getAgentName} from "../../../util/utility";

export default function SingleEventNote({
                                            activeAgentId,
                                            note,
                                            totalAgentMap,
                                            removeNote,
                                            isRemovingNote,
                                            setEditNote,
                                            isUpdatingNote
                                        }) {

    const [isEditable, setEditable] = useState(false);

    const [text, setText] = useState(note?.text);

    return (
        <tr className="mngevent-email-details" key={note._id}>
            <td>
                <p>
                    {moment(new Date(note.created_at)).format("DD-MM-YYYY - HH:mm")}
                </p>
                <p>
                    {getAgentName(totalAgentMap, note.agent_id)}
                </p>
            </td>
            <td>
                <p style={{minWidth: '300px'}}>
                    {note.text}
                </p>
            </td>
            <td>
                <Button
                    disabled={activeAgentId !== note.agent_id || isUpdatingNote}
                    onClick={() => setEditNote(note)}
                >{isEditable ? 'Edit' : 'Edit'}</Button>
                <Button
                    disabled={activeAgentId !== note.agent_id || isRemovingNote}
                    onClick={() => removeNote(note._id)}
                >{isRemovingNote ? 'Deleting' : 'Delete'}</Button>
            </td>
        </tr>
    );
}
