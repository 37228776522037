import React from 'react';
import moment from 'moment';
import {
} from 'react-bootstrap';

function SmsSent(props) {
    if (props.smstasks && props.smstasks.length > 0) {
        return (
            <div className="panel panel-default">
                <div className="panel-heading" style={{color: "#000"}}>
                    Sms Logs
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Sent Date</th>
                        <th>Sent To</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.smstasks.map((mail) => (
                        <tr className="prev-details" key={mail._id}>
                            <td>{moment(mail.created_on).format("Do MMM YYYY")}</td>
                            <td>
                                {mail.sendTo && mail.sendTo !== '' ? mail.sendTo : ''}
                            </td>
                            <td>
                                {mail.completed ? 'Sent' : 'Undelivered'}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    } else {
        return (
            <div className="panel panel-default">
                <div className="panel-heading" style={{color: "#000"}}>
                    Sms Logs
                </div>
                <div className="panel-body">
                    <div className="text-center">
                        No Sms Yet
                    </div>
                </div>
            </div>
        );
    }
}

export default SmsSent;
