import React, { Component } from 'react';
import Select from 'react-select';
import Slider from "react-slick";
import Moment from 'moment';
import _ from 'lodash';
import { Tab, Tabs , Button, FormControl, FormGroup, ControlLabel, Checkbox, InputGroup, ButtonGroup } from 'react-bootstrap';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import SearchSelect from './SearchSelect';
import EventNotes from './EventNotes';
import SwitchControl from "../../PermissionLocks/SwitchControl";
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={(e) => handleNextClick(e, onClick, props.page)}
        >
            <div style={{ position: 'absolute', top: '-15px', right: -10 }}>
                {/* <VmIcon
            targetClass={'header-city-select-next-prev-icon'}
            iconName={'icon-vm-right-arrow'}
          /> */}
                <img className={''} src={"https://cdn.venuemonk.com/icon-vm-right-arrow.svg"} />
            </div>
        </div>
    );
}

function handleNextClick(e, callback, page) {
    // gtmCustomEvent(`${page}`, 'TestimonialCarousel', 'Next arrow');
    callback(e);
}

function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style }}
            onClick={(e) => handlePrevClick(e, onClick, props.page)}
        >
            <div style={{ position: 'absolute', top: '-15px', left: -10 }}>
                {/* <VmIcon
            targetClass={'header-city-select-next-prev-icon'}
            iconName={'icon-vm-left-arrow'}
          /> */}
                <img className={''} src={"https://cdn.venuemonk.com/icon-vm-left-arrow.svg"} />
            </div>
        </div>
    );
}


function handlePrevClick(e, callback, page) {
    // gtmCustomEvent(`${page}`, 'TestimonialCarousel', 'Prev arrow');
    callback(e);
}

class EventEditForm extends Component {
    constructor(props) {
        super(props);
        this.startdate = Moment();
        this.state = {
            eventDate: Moment(this.props.event.event_date),
            selectedoccasiontypes: this.props.selectedoccasion,
            selectedcitytypes: this.props.selectedcity,
            selectedvenuetypes: this.props.selectedvenue,
            selectedlocationtypes: this.props.selectedlocation,
            selectedpackagetypes: this.props.selectedpackage,
            agent: {},
            visit_agents: [],
            new_agent: "",
            previousEventDetail: {},
            eventCheck: {},
            services: {},
            temperature: ["Cold", "Warm", "Hot"],
            quality: ["Low", "Medium", "High"],
            contactTime: ["Anytime", "9 am to 12 pm", "12 pm to 3 pm", "3 pm to 6 pm", "6 pm to 9 pm"],
            description: ["Sole person planning the event", "One out of many people planning the event", "Just looking for options to help a friend/relative plan the event"],
            booking: ["Immediate", "In 2-3 Days", "In Next Week", "Later Sometime", "Just Checking Price"],

        }
    };


    componentDidMount() {
        let agent = {};
        agent._id = this.props.event.agent_id;
        agent.name = this.props.event.agent_name;
        this.setState({
            eventDate: Moment(this.props.event.event_date),
            selectedoccasiontypes: this.props.selectedoccasion,
            selectedcitytypes: this.props.selectedcity,
            selectedvenuetypes: this.props.selectedvenue,
            selectedlocationtypes: this.props.selectedlocation,
            selectedpackagetypes: this.props.selectedpackage,
            eventquality: this.props.event.quality,
            eventprospect: this.props.event.prospect,
            bookingUrgency: this.props.event.bookingUrgency,
            bestWayToDescribeMe: this.props.event.bestWayToDescribeMe,
            bestTimeToContactMe: this.props.event.bestTimeToContactMe,
            agent: agent,
            // visit_agents : this.props.event.visit_agents ? this.props.event.visit_agents : []
        });
        this.setCheckBox();
        this.getAgentName(this.props.totalAgentMap, this.props.event.visit_agents);
    }

    setCheckBox = () => {
        let data = {};
           data.useEmailToContactMe = this.props.event.useEmailToContactMe ? this.props.event.useEmailToContactMe : false
           data.usePhoneToContactMe = this.props.event.usePhoneToContactMe ? this.props.event.usePhoneToContactMe : false
           data.useWhatsAppToContactMe = this.props.event.useWhatsAppToContactMe ? this.props.event.useWhatsAppToContactMe : false
           data.useSmsToContactMe = this.props.event.useSmsToContactMe ? this.props.event.useSmsToContactMe : false

           let services = {};
           services.isVenueNeeded = this.props.event.isVenueNeeded ? this.props.event.isVenueNeeded : false
           services.isPhotographerNeeded = this.props.event.isPhotographerNeeded ? this.props.event.isPhotographerNeeded : false
           services.isDecoratorNeeded = this.props.event.isDecoratorNeeded ? this.props.event.isDecoratorNeeded : false
           services.isArtistNeeded = this.props.event.isArtistNeeded ? this.props.event.isArtistNeeded : false
       this.setState({
            eventCheck: data,
            services: services
       })
    }

    getAgentName = (totalAgentMap, visitAgents) => {
        let visit_agents = visitAgents.map((id) => {
            return {
                _id: id,
                name: totalAgentMap[id]?.name
            }
        })
        this.setState({
            visit_agents: visit_agents
        })
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.event, this.props.event)) {
            let agent = {};
            agent._id = this.props.event.agent_id;
            agent.name = this.props.event.agent_name;
            this.setState({
                eventDate: Moment(this.props.event.event_date),
                agent: agent,
            });
            this.setCheckBox();
            this.getAgentName(this.props.totalAgentMap, this.props.event.visit_agents)
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.event.event_date !== nextProps.event.event_date) {
            this.setState({
                eventDate: Moment(nextProps.event.event_date)
            });
        }
        let agent = {};
        agent._id = nextProps.event.agent_id;
        agent.name = nextProps.event.agent_name;
        this.setState({
            agent: agent,
        });
        this.getAgentName(this.props.totalAgentMap, this.props.event.visit_agents)
    }

    handleOnVenueChange = (value) => {
        this.setState({
            selectedvenuetypes: value
        });
    };

    handleOnOccasionChange = (value) => {
        let oldoccasion = this.state.selectedoccasiontypes;
        let newoccasion = [];
        if (oldoccasion.length < 1) {
            newoccasion = value;
        } else {
            value.forEach(function (item1) {
                let ifExists = false;
                oldoccasion.forEach(function (item2) {
                    if (item1.label !== item2.label) {
                        ifExists = true;
                    }
                });
                if (ifExists) {
                    newoccasion.push(item1);
                }
            });
        }
        this.setState({
            selectedoccasiontypes: newoccasion
        });
    };


    handleOnPackageChange = (value) => {
        let oldpackage = this.state.selectedpackagetypes;
        let newpackage = [];
        if (oldpackage.length < 1) {
            newpackage = value;
        } else {
            value.map(function (item1) {
                let ifExists = false;
                oldpackage.map(function (item2) {
                    if (item1.label !== item2.label) {
                        ifExists = true;
                    }
                });
                if (ifExists) {
                    newpackage.push(item1);
                }
            });
        }
        this.setState({
            selectedpackagetypes: newpackage
        });
    };

    handleOnLocationChange = (value) => {
        let oldlocation = this.state.selectedlocationtypes;
        let newlocation = [];
        if (oldlocation.length < 1) {
            newlocation = value;
        } else {
            value.forEach(function (item1) {
                let ifExists = false;
                oldlocation.forEach(function (item2) {
                    if (item1.label !== item2.label) {
                        ifExists = true;
                    }
                });
                if (ifExists) {
                    newlocation.push(item1);
                }
            });
        }
        this.setState({
            selectedlocationtypes: newlocation
        });
    };

    handleOnCityChange = (value) => {
        let oldarray = this.state.selectedcitytypes;
        let newarray = [];
        if (oldarray.length < 1) {
            newarray = value;
        } else {
            value.map(function (item1) {
                let ifExists = false;
                oldarray.map(function (item2) {
                    if (item1.label !== item2.label) {
                        ifExists = true;
                    }
                });
                if (ifExists) {
                    newarray.push(item1);
                }
            });
        }
        this.setState({
            selectedcitytypes: newarray,
            selectedlocationtypes: []
        });
        if (newarray.length > 0) {
            this.props.handleCityChange(newarray[0].label);
        }
    };

    handleQualityChange = (event) => {
        this.setState({
            eventquality: event.target.value
        })
    }

    handleTemperatureChange = (event) => {
        this.setState({
            eventprospect: event.target.value
        })
    }

    handleContactTimeChange = (event) => {
        this.setState({
            bestTimeToContactMe: event.target.value
        })
    }

    handleBookingChange = (event) => {
        this.setState({
            bookingUrgency: event.target.value
        })
    }

    handleDescriptionChange = (event) => {
        this.setState({
            bestWayToDescribeMe: event.target.value
        })
    }

    getEventDetails = () => {
        let event = {};
        event.occasion = this.state.selectedoccasiontypes.map(item => item.label);
        event.city = this.state.selectedcitytypes.map(item => item.value);
        if (event.city && event.city.length > 0) {
            event.city = _.toString(event.city[0]);
        }
        event.package_code = this.state.selectedpackagetypes.map(item => item.value).join('');
        event.venuetype = this.state.selectedvenuetypes.map(item => item.label);
        event.location = this.state.selectedlocationtypes.map(item => item.value).join('');
        event.budget_start = this.eventminbudget.value;
        event.budget_end = this.eventmaxbudget.value;
        event.guests = this.eventguests.value;
        event.contact_name = this.contactname.value;
        event.contact_email = this.contactemail.value;
        event.contact_phone = this.contactphone.value;
        event.isVenueNeeded = this.state.services.isVenueNeeded;
        let date = new Date(this.state.eventDate);
        let eventDate = date.toISOString();
        event.event_date = eventDate;
        event.isPhotographerNeeded =  this.state.services.isPhotographerNeeded;
        event.isDecoratorNeeded =  this.state.services.isDecoratorNeeded;
        event.isArtistNeeded =  this.state.services.isArtistNeeded;
        event.useWhatsAppToContactMe = this.state.eventCheck.useWhatsAppToContactMe;
        event.useEmailToContactMe = this.state.eventCheck.useEmailToContactMe;
        event.useSmsToContactMe = this.state.eventCheck.useSmsToContactMe;
        event.usePhoneToContactMe = this.state.eventCheck.usePhoneToContactMe;
        event.bestTimeToContactMe = this.state.bestTimeToContactMe;
        event.bestWayToDescribeMe = this.state.bestWayToDescribeMe;
        event.bookingUrgency = this.state.bookingUrgency;
        event.quality = this.state.eventquality;
        event.prospect = this.state.eventprospect;

        return event;
    };

    updateEvent = () => {
        let event = this.getEventDetails();
        if (_.isEmpty(event.city)) {
            this.props.showMessage("City cannot be empty", "error");
        } else {
            this.props.update(this.props.customerEventId, event);
        }

    };

    handleAgentChange = (val) => {
        this.setState({
            agent: val
        });
        if (val && val._id !== "") {
            this.props.changeAgent(this.props.id, val._id);
        }
    };

    handleVisitAgentChange = (val) => {
        if(this.state.agent._id !== ''){
        let newAgent = val;
        let visitAgents = this.state.visit_agents;
        visitAgents.push(val);

        this.setState({
            visit_agents: visitAgents,
            new_agent: newAgent
        });
        if (val && val._id !== "") {
            this.props.changeVisitAgent(this.props.id, val._id);
        }
    }else{
        this.props.showMessage("Please assign inside sales agent first!", "error");
    }
    };

    handleRemovalVisitAgent = (id) => {
        console.log("id", id)
        if (id !== "") {
            let visitAgents = this.state.visit_agents.filter(item => item._id !== id);
            this.setState({
                visit_agents: visitAgents
            });
            this.props.removeVisitAgent(this.props.id, id);
        }
    }

    handleEventDateChange = (event, picker) => {
        this.setState({
            eventDate: picker.startDate
        });
    };

    onDateChange = (dateString) => {
        this.setState({
            eventDate: dateString
        });
      };

    handleShowPreviousEventDetail = (event) => {
        let previousEventDetail = this.state.previousEventDetail;
        if (_.isEmpty(previousEventDetail)) {
            this.setState({ previousEventDetail: event });
        } else if (!_.isEqual(previousEventDetail._id, event._id)) {
            this.setState({ previousEventDetail: event });
        } else {
            this.setState({ previousEventDetail: {} });
        }
    };

    handleOnChange = (keyName, keyValue) => {
        let eventCheck = {...this.state.eventCheck}
        eventCheck[keyName] = !keyValue;
        this.setState({
            eventCheck : eventCheck
        })
    }

    handleOnServiceChange = (keyName, keyValue) => {
        let services = {...this.state.services}
        services[keyName] = !keyValue;
        this.setState({
            services : services
        })
    }

    render() {
        let settings = {
            dots: true,
            infinite: true,
            slidesToShow: 6,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
            // responsive: [
            //   {
            //     breakpoint: 768,
            //     settings: {
            //       arrows: false,
            //       slidesToShow: 6,
            //       slidesToScroll: 1,
            //       infinite: true,
            //       dots: false,
            //     }
            //   },
            // ]
        }
        let eventDate = this.state.eventDate ? this.state.eventDate.format('YYYY-MM-DD'): '';
        let eventDateLabel = `${eventDate}`;
        let phoneStyle;
        if (this.props.event.isVerified) {
            phoneStyle = { color: "green" };
        } else {
            phoneStyle = { color: "red" };
        }
        return (
            <div id="searchFilters">
                <div className="" style={{ paddingBottom: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div>
                            <h4>
                                <span> Enquiry Number: {this.props.event.enquiry_num ? this.props.event.enquiry_num : null}</span>
                            </h4>
                        </div>
                        <div >
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <span style={{ paddingRight: "6px" }}><b>IS Agent</b></span>

                                <SwitchControl
                                    userPermissions={this.props.userPermissions}
                                    shouldHavePermissions={["lead-agent:change"]}
                                    yes={
                                        <>
                                            <SearchSelect
                                                values={this.state.agent}
                                                getSelectedValue={(val) => this.handleAgentChange(val)}
                                                placeholder={"Agent"}
                                                isCreatable={false}
                                                valueKey={'_id'}
                                                labelKey={'name'}
                                                isMultiSelect={false}
                                                searchAPI={this.props.getAgentOptions}
                                            />
                                            <div style={{ border: "1px solid #E0E0E0", borderRadius: "1px", padding: "4px 9px", marginLeft: "7px" }}
                                                onClick={() => this.handleAgentChange("")}>
                                                <i className="fa fa-pencil" aria-hidden="true" style={{ color: "#03A9F5" }} ></i>

                                            </div>
                                        </>}
                                    no={
                                        <>
                                            <FormControl
                                                type="text"
                                                disabled={true}
                                                defaultValue={this.state.agent.name}
                                                inputRef={ref => {
                                                    this.agentname = ref;
                                                }}
                                                className={'width-content'}
                                            />
                                            <div style={{ border: "1px solid #E0E0E0", borderRadius: "1px", padding: "4px 9px", marginLeft: "7px" }}>
                                                <i className="fa fa-pencil" aria-hidden="true" style={{ color: "#03A9F5" }}></i>
                                            </div>
                                        </>
                                    }
                                />
                            </div>

                        </div>
                        <div>
                            <h4>
                                {/*<a className="pull-left">*/}
                                {/*    {!_.isEmpty(this.props.corporateEvent) ? 'Corporate Lead' : ''}*/}
                                {/*</a>*/}
                                <span>
                                    Lead Potential: {this.props.event.budget_end && this.props.event.guests ? parseFloat(this.props.event.budget_end) * parseFloat(this.props.event.guests) * 0.1 : ""}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: "flex", alignItems: "center" }}>

                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ paddingRight: "6px" }}><b>Venue Agent</b></span>
                            <div style={{minWidth:'140px'}}>
                        <SwitchControl
                            userPermissions={this.props.userPermissions}
                            shouldHavePermissions={["lead-agent:change"]}
                            yes={
                                <>
                                    <SearchSelect
                                        values={this.state.new_agent}
                                        getSelectedValue={(val) => this.handleVisitAgentChange(val)}
                                        placeholder={"Agent"}
                                        isCreatable={false}
                                        valueKey={'_id'}
                                        labelKey={'name'}
                                        isMultiSelect={false}
                                        searchAPI={this.props.getAgentOptions}
                                    />
                                </>}
                            no={
                                <>
                                </>
                            }
                        />
                        </div>
</div>
                        {
                        this.state.visit_agents && this.state.visit_agents.length > 0 ?
                            <div className='row'>
                            <div className="col-lg-12"
                                 style={{
                                     width: '100%',
                                     display: 'inline-flex',
                                     flexWrap: 'wrap',
                                     marginTop: '10px'
                                 }}>
                                {
                                    this.state.visit_agents.map((item, index) => {
                                        return(
                                        <div
                                             style={{
                                                 minWidth: '100px',
                                                 borderRadius: '5px',
                                                 margin: '3px',
                                                 padding: '3px',
                                                 border: '1px solid #cdcdcd',
                                                 cursor: 'pointer',
                                                 backgroundColor: `#fff`
                                             }}>
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-evenly'
                                            }}>
                                                <span style={{ fontWeight: 'bold' }}>{item.name}</span>
                                                    <SwitchControl
                                                        userPermissions={this.props.userPermissions}
                                                        shouldHavePermissions={["lead-agent:change"]}
                                                        yes={
                                                            <i class="fa fa-times" aria-hidden="true"
                                                                style={{ padding: "4px 0px 0px 4px" }}
                                                                onClick={() => this.handleRemovalVisitAgent(item._id)}></i>
                                                        }
                                                        no={
                                                            <></>
                                                        }
                                                    />
                                                </div>
                                        </div>
                                    )})
                                }
                            </div>
                            </div>
                            : null
                    }
                    </div>
                    <div>
                        <div style={{ display: 'flex', flexDirection: 'row', minHeight: '15px', margin: '12px 0px' }}>
                            {
                                this.props.eventStat?.landingPage ? <div className='tags'><i className="fa fa-calendar space-right"
                                    aria-hidden="true"></i>LP :{this.props.eventStat.landingPage}
                                </div> : null
                            }
                            {
                                this.props.event?.lf_source ? <div className='tags'><i className="fa fa-cutlery space-right"
                                    aria-hidden="true"></i>LS :{this.props.event.lf_source}
                                </div> : null
                            }
                            {
                                this.props.event?.category ? <div className='tags'><i className="fa fa-inr space-right"
                                    aria-hidden="true">Cat: </i>{this.props.event.category}
                                </div> : null
                            }
                            {
                                this.props.event?.marketing_source ? <div className='tags'><i className="fa fa-users space-right"
                                    aria-hidden="true"></i>{this.props.event.marketing_source}
                                </div> : null
                            }
                            {
                                this.props.event?.source_app ?
                                    <div className='tags'>
                                        <i className="fa fa-map-marker space-right" aria-hidden="true"></i>
                                        D: {this.props.event.source_app}
                                    </div> : null
                            }
                            {
                                this.props.event.city ?
                                    <div className='tags'>
                                        <i className="fa fa-map-marker space-right" aria-hidden="true"></i>
                                        L: {this.props.event.city}
                                    </div> : null
                            }
                        </div>
                    </div>

                    {/* <Slider {...settings}>
            {
                        this.props.previousEvents && this.props.previousEvents.length > 0 ?
                            <div className="col-lg-12"
                                 style={{
                                     width: '100%',
                                     display: 'inline-flex',
                                     flexWrap: 'wrap',
                                     marginBottom: '20px',
                                     marginTop: '20px'
                                 }}>
                                <div className="col-lg-12"
                                     style={{padding: 0, fontWeight: 'bold', marginBottom: '10px'}}>Previous Events
                                </div>
                                {
                                    this.props.previousEvents.map(item => (
                                        <div onClick={() => this.handleShowPreviousEventDetail(item)}
                                             style={{
                                                 minWidth: '100px',
                                                 borderRadius: '5px',
                                                 margin: '3px',
                                                 padding: '3px',
                                                 border: '1px solid #cdcdcd',
                                                 cursor: 'pointer',
                                                 backgroundColor: `${!_.isEmpty(this.state.previousEventDetail) && this.state.previousEventDetail._id === item._id ? '#cdcdcd' : '#fff'}`
                                             }}>
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-evenly'
                                            }}>
                                                <span style={{fontWeight: 'bold'}}>{item.enquiry_num}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div> : null
                    }


                    </Slider> */}

                    {
                        this.props.previousEvents && this.props.previousEvents.length > 0 ?
                            <div className='row'>
                            <div className="col-lg-12"
                                 style={{
                                     width: '100%',
                                     display: 'inline-flex',
                                     flexWrap: 'wrap',
                                     marginBottom: '20px',
                                     marginTop: '20px'
                                 }}>
                                <div className="col-lg-12 col-xs-12"
                                     style={{padding: 0, fontWeight: 'bold', marginBottom: '10px'}}>Previous Events
                                </div>
                                {
                                    this.props.previousEvents.map(item => (
                                        <div onClick={() => this.handleShowPreviousEventDetail(item)}
                                             style={{
                                                 minWidth: '100px',
                                                 borderRadius: '5px',
                                                 margin: '3px',
                                                 padding: '3px',
                                                 border: '1px solid #cdcdcd',
                                                 cursor: 'pointer',
                                                 backgroundColor: `${!_.isEmpty(this.state.previousEventDetail) && this.state.previousEventDetail._id === item._id ? '#cdcdcd' : '#fff'}`
                                             }}>
                                            <div style={{
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-evenly'
                                            }}>
                                                <span style={{fontWeight: 'bold'}}>{item.enquiry_num}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            </div>
                            : null
                    }
                    {
                        this.state.previousEventDetail && !_.isEmpty(this.state.previousEventDetail) ?
                            <div className="col-lg-12">
                                <div className="col-lg-12" style={{
                                    padding: '15px 10px',
                                    border: '1px solid #cdcdcd',
                                    borderRadius: '5px',
                                    marginBottom: '20px',
                                    marginTop: '20px'
                                }}>
                                    <div className="col-xs-3">
                                        <span>{this.state.previousEventDetail.enquiry_num}</span>
                                    </div>
                                    <div className="col-xs-3">
                                        <span>{this.state.previousEventDetail.occasion ? this.state.previousEventDetail.occasion[0] : ''}</span>
                                    </div>
                                    <div className="col-xs-3">{_.startCase(this.state.previousEventDetail.city)}</div>
                                    <div
                                        className="col-xs-3">{_.startCase(this.state.previousEventDetail.location)}</div>
                                    <div
                                        className="col-xs-3">{`Budget: ${this.state.previousEventDetail.budget_start} - ${this.state.previousEventDetail.budget_end}`}</div>
                                    <div className="col-xs-3">
                                        <span>{`LD: ${Moment(this.state.previousEventDetail.created_at).format('DD MMM YYYY')}`}</span>
                                    </div>
                                    <div className="col-xs-3">
                                        <span>{`ED: ${Moment(this.state.previousEventDetail.event_date).format('DD MMM YYYY')}`}</span>
                                    </div>
                                    <div className="col-xs-3">
                                        <span>{`Agent: ${this.state.previousEventDetail.agent_name}`}</span>
                                    </div>
                                    <div className="col-xs-3">
                                        <span>{`Status: ${this.state.previousEventDetail.status}`}</span>
                                    </div>
                                </div>
                            </div> : null
                    }
                    <div className="col-xs-12">
                        <div className={!_.isEmpty(this.props.corporateEvent) ? 'alert alert-success' : 'hide'}
                            role="alert">
                            {!_.isEmpty(this.props.corporateEvent) ?
                                <div className={'row'}>
                                    <div className="col-xs-3">
                                        Corporate Agent
                                    </div>
                                    <div className="col-xs-3">
                                        Corporate Admin
                                    </div>
                                    <div className="col-xs-3">
                                        Admin Phone
                                    </div>
                                    <div className="col-xs-3">
                                        Admin Email
                                    </div>
                                    <div className="col-xs-3">
                                        {this.props.corporateEvent.corporate_agent_name}
                                    </div>
                                    <div className="col-xs-3">
                                        {this.props.corporateEvent.contact_name}
                                    </div>
                                    <div className="col-xs-3">
                                        {this.props.corporateEvent.contact_phone}
                                    </div>
                                    <div className="col-xs-3">
                                        {this.props.corporateEvent.contact_email}
                                    </div>
                                </div> :
                                null
                            }
                        </div>
                    </div>
                    <div className="col-xs-12 zero-padding">
                        <div
                            className={!_.isEmpty(this.props.event.missing_details_keys_list) && this.props.event.missing_details_keys_list.length > 0 && this.props.event.missing_details_count > 0 ? 'alert alert-warning missing-details-padding' : 'hide missing-details-padding'}
                            role="alert">
                            {!_.isEmpty(this.props.event.missing_details_keys_list) ?
                                <div className={'row'}>
                                    <div className="col-xs-3">
                                        {`Missing Details Count: ${this.props.event.missing_details_count}`}
                                    </div>
                                    <div className="col-xs-9">
                                        {`Missing Details: `}
                                        {this.props.event.missing_details_keys_list.map(item => (
                                            <span style={{ marginLeft: '7px' }}>{`${_.startCase(item)}, `}</span>))}
                                    </div>
                                </div> :
                                null
                            }
                        </div>
                    </div>
<div className='row'>
                    <div className="col-lg-4">
                        <FormGroup>
                            <ControlLabel>
                                Name {this.props.event.repeatUser ?
                                    <span>(Repeat <i className="fa fa-check" />)</span> : null}
                            </ControlLabel>
                            <FormControl
                                type="text"
                                defaultValue={this.props.event.contact_name}
                                inputRef={ref => {
                                    this.contactname = ref;
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-4">
                        <FormGroup>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl
                                type="text"
                                defaultValue={this.props.event.contact_email}
                                inputRef={ref => {
                                    this.contactemail = ref;
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-4">
                        <FormGroup>
                            <ControlLabel>Mobile</ControlLabel>
                            <InputGroup>
                                <FormControl
                                    type="text"
                                    defaultValue={this.props.event.contact_phone}
                                    inputRef={ref => {
                                        this.contactphone = ref;
                                    }}
                                />
                                <InputGroup.Addon style={{ borderRadius: 0 }}>
                                    <a onClick={() => this.props.connectCall(this.props.event.contact_phone)}
                                        target="_blank">
                                        <i style={{ color: "#491369" }} className={"fa fa-phone"} />
                                    </a>
                                </InputGroup.Addon>
                                <InputGroup.Addon style={{ borderRadius: 0 }}>
                                    <a href={`https://api.whatsapp.com/send?phone=91${this.props.event.contact_phone}`}
                                        target="_blank">
                                        <i style={{ color: "#491369" }} className={"fa fa-whatsapp"} />
                                    </a>
                                </InputGroup.Addon>

                                <InputGroup.Addon style={{ borderRadius: 0 }}>
                                    <i style={phoneStyle}
                                        className={this.props.event.isVerified ? "fa fa-check" : "fa fa-times"} />
                                </InputGroup.Addon>
                            </InputGroup>

                        </FormGroup>
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-lg-3" >
                        {/* <DateRangePicker
                            onEvent={this.handleEventDateChange}
                            autoApply={true}
                            timePicker={true}
                            singleDatePicker={true}
                        >
                            <>
                                <ControlLabel>Event Date</ControlLabel>
                                <InputGroup>
                                    <FormControl type="text" value={eventDateLabel}/>
                                    <InputGroup.Addon style={{borderRadius: "0px"}}>
                                        <i className="fa fa-calendar" aria-hidden="true"/>
                                    </InputGroup.Addon>
                                </InputGroup>
                            </>
                        </DateRangePicker> */}
                            <Space direction="vertical" size={12}>
                                <DatePicker
                                    showTime
                                    onChange={(dateString) => this.onDateChange( dateString)}

                                    defaultValue={dayjs(_.toString(eventDateLabel), 'YYYY-MM-DD')}
                                />
                            </Space>
                    </div>
                    <div className="col-lg-3">
                        <div><label>Occasion</label></div>
                        <Select
                            name="OccasionType"
                            placeholder="Occasion"
                            options={this.props.occasiontypes}
                            multi={true}
                            openOnFocus={true}
                            clearable={false}
                            value={this.state.selectedoccasiontypes}
                            onChange={this.handleOnOccasionChange}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div><label>City</label></div>
                        <Select
                            name="CityType"
                            placeholder="City"
                            options={this.props.citytypes}
                            multi={true}
                            openOnFocus={true}
                            clearable={false}
                            value={this.state.selectedcitytypes}
                            onChange={this.handleOnCityChange}
                        />
                    </div>
                    <div className="col-lg-3">
                        <div><label>Location</label></div>
                        <Select
                            name="LocationType"
                            placeholder="Location"
                            options={this.props.locationtypes}
                            multi={true}
                            openOnFocus={true}
                            value={this.state.selectedlocationtypes}
                            onChange={this.handleOnLocationChange}
                            clearable={false}
                        />
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-lg-3" style={{ marginTop: '10px' }}>
                        <FormGroup style={{ marginBottom: 0 }}>
                            <ControlLabel>Guests</ControlLabel>
                            <FormControl
                                type="number"
                                defaultValue={this.props.event.guests}
                                inputRef={ref => {
                                    this.eventguests = ref;
                                }}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-lg-3" style={{ marginTop: '10px' }}>
                        <div><label>VenueType</label></div>
                        <Select
                            name="Venuetype"
                            placeholder="Venue"
                            options={this.props.venuetypes}
                            multi={true}
                            openOnFocus={true}
                            value={this.state.selectedvenuetypes}
                            onChange={this.handleOnVenueChange}
                            clearable={false}
                        />
                    </div>
                    <div className="col-lg-3" style={{ marginTop: '10px' }}>
                        <div><label>PackageType</label>
                        </div>
                        <Select
                            name="PackageType"
                            placeholder="Package"
                            options={this.props.packagetypes}
                            multi={true}
                            openOnFocus={true}
                            value={this.state.selectedpackagetypes}
                            onChange={this.handleOnPackageChange}
                            clearable={false}
                        />
                    </div>
                    <div className="col-lg-3" style={{ marginTop: '10px' }}>
                        <div className="row">
                            <div className="col-xs-12">
                                <label>Budget</label>
                            </div>
                            <div className="col-xs-6">
                                <FormGroup>
                                    <FormControl
                                        type="number"
                                        defaultValue={this.props.event.budget_start}
                                        inputRef={ref => {
                                            this.eventminbudget = ref;
                                        }}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-xs-6">
                                <FormControl
                                    type="number"
                                    defaultValue={this.props.event.budget_end}
                                    inputRef={ref => {
                                        this.eventmaxbudget = ref;
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-6" style={{ marginBottom: "10px", paddingLeft: "0px" }}>
                        <label className='font-weigth-details'>Services Needed</label>

                        <div className={'row'}>
                            <div className={"col-xs-3"} style={{ paddingRight: "0px" }}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Email'}
                                    value={this.state.services.isVenueNeeded}
                                    checked={this.state.services.isVenueNeeded}
                                    onChange={() => this.handleOnServiceChange("isVenueNeeded", this.state.services.isVenueNeeded)}
                                />
                                <span style={{ paddingLeft: "4px" }}>Venue</span>
                            </div>

                            <div className={"col-xs-3"} style={{ padding: "0px" }}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Email'}
                                    value={this.state.services.isPhotographerNeeded}
                                    checked={this.state.services.isPhotographerNeeded}
                                    onChange={() => this.handleOnServiceChange("isPhotographerNeeded", this.state.services.isPhotographerNeeded)}
                                />
                                <span style={{ paddingLeft: "4px" }}>Photographer</span>
                            </div>

                            <div className={"col-xs-3"} style={{ padding: "0px" }}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Email'}
                                    value={this.state.services.isDecoratorNeeded}
                                    checked={this.state.services.isDecoratorNeeded}
                                    onChange={() => this.handleOnServiceChange("isDecoratorNeeded", this.state.services.isDecoratorNeeded)}
                                />
                                <span style={{ paddingLeft: "4px" }}>Decorator</span>
                            </div>

                            <div className={"col-xs-3"} style={{ padding: "0px" }}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Email'}
                                    value={this.state.services.isArtistNeeded}
                                    checked={this.state.services.isArtistNeeded}
                                    onChange={() => this.handleOnServiceChange("isArtistNeeded", this.state.services.isArtistNeeded)}
                                />
                                <span style={{ paddingLeft: "4px" }}>Makeup Artist</span>
                            </div>

                        </div>
                    </div>
                    <div className="col-lg-6" style={{ marginBottom: "10px" }}>
                        <ControlLabel className='font-weigth-details'>Best Way to Contact Me</ControlLabel>
                        <div className={'row'}>

                            <div className={"col-xs-3"} style={{paddingRight: "0px"}}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Email'}
                                    value={this.state.eventCheck.useEmailToContactMe}
                                    checked={this.state.eventCheck.useEmailToContactMe}
                                    onChange={() => this.handleOnChange("useEmailToContactMe", this.state.eventCheck.useEmailToContactMe)}
                                />
                                 <span style={{ paddingLeft: "4px" }}>Email</span>
                            </div>

                            <div className={"col-xs-3"} style={{paddingRight: "0px"}}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Call'}
                                    value={this.state.eventCheck.usePhoneToContactMe}
                                    checked={this.state.eventCheck.usePhoneToContactMe}
                                    onChange={() => this.handleOnChange("usePhoneToContactMe", this.state.eventCheck.usePhoneToContactMe)}
                                />
                                 <span style={{ paddingLeft: "4px" }}>Call</span>
                            </div>

                            <div className={"col-xs-3"} style={{paddingRight: "0px"}}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Call'}
                                    value={this.state.eventCheck.useWhatsAppToContactMe}
                                    checked={this.state.eventCheck.useWhatsAppToContactMe}
                                    onChange={() => this.handleOnChange("useWhatsAppToContactMe", this.state.eventCheck.useWhatsAppToContactMe)}
                                />
                                 <span style={{ paddingLeft: "4px" }}>Whatsapp</span>
                            </div>

                            <div className={"col-xs-3"} style={{paddingRight: "0px"}}>

                                <input
                                    type="checkbox"
                                    className={`custom-checkbox-event`}
                                    name={'Call'}
                                    value={this.state.eventCheck.useSmsToContactMe}
                                    checked={this.state.eventCheck.useSmsToContactMe}
                                    onChange={() => this.handleOnChange("useSmsToContactMe", this.state.eventCheck.useSmsToContactMe)}
                                />
                                 <span style={{ paddingLeft: "4px" }}>Sms</span>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-lg-6">
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel className='font-weigth-details'>Quality</ControlLabel>
                            <div>
                                {
                                    this.state.quality.map((item) => (
                                        <>
                                            <input type="radio" class="form-check-input" id="radio1" name="quality"
                                                value={item}
                                                checked={this.state.eventquality === item}
                                                onChange={this.handleQualityChange}
                                            /><span style={{ padding: "4px" }}>{item}</span>
                                        </>
                                    ))
                                }
                            </div>
                        </FormGroup>
                    </div>
                    <div className="col-lg-6">
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel className='font-weigth-details'>Temperature</ControlLabel>
                            <div>
                                {
                                    this.state.temperature.map((item) => (
                                        <>
                                            <input type="radio" class="form-check-input" id="radio1" name="temperature"
                                                value={item}
                                                checked={this.state.eventprospect === item}
                                                onChange={this.handleTemperatureChange}
                                            /><span style={{ padding: "4px" }}>{item}</span>
                                        </>
                                    ))
                                }
                            </div>
                        </FormGroup>
                    </div>
                    </div>

                    {/* <div className="col-lg-2">
                        <FormGroup controlId="formControlsSelect">
                            <ControlLabel>Priority Score</ControlLabel>
                            <FormControl
                                type="text"
                                defaultValue={this.props.event.priority}
                                inputRef={ref => {
                                    this.priority = ref;
                                }}
                            />
                        </FormGroup>
                    </div> */}
                    <div className='row'>
                    <div className="col-lg-3">

                        <ControlLabel className='font-weigth-details'>Best Time to Contact Me</ControlLabel>
                        {
                            this.state.contactTime.map((item) => (
                                <div className='display-flex'>
                                    <input type="radio" class="form-check-input" id="radio1" name="contactTime"
                                        value={item}
                                        checked={this.state.bestTimeToContactMe === item}
                                        onChange={this.handleContactTimeChange}
                                    /><span className={'radio-content-styling'}>{item}</span>
                                </div>
                            ))
                        }


                    </div>

                    <div className="col-lg-3">

                        <ControlLabel className='font-weigth-details'>How soon will you book?</ControlLabel>

                        {
                            this.state.booking.map((item) => (
                                <div className='display-flex'>
                                    <input type="radio" class="form-check-input" id="radio1" name="booking"
                                        value={item}
                                        checked={this.state.bookingUrgency === item}
                                        onChange={this.handleBookingChange}
                                    /><span className={'radio-content-styling'}>{item}</span>
                                </div>
                            ))
                        }

                    </div>

                    <div className="col-lg-6">

                        <ControlLabel className='font-weigth-details'>What Describes Me the Best?</ControlLabel>
                        {
                            this.state.description.map((item) => (
                                <div className='display-flex'>
                                    <input type="radio" class="form-check-input" id="radio1" name="description"
                                        value={item}
                                        checked={this.state.bestWayToDescribeMe === item}
                                        onChange={this.handleDescriptionChange}
                                    /><span className={'radio-content-styling'}>{item}</span>
                                </div>
                            ))
                        }

                    </div>
                    </div>
                    <div className='row'>
                    <div className="col-xs-12" style={{ marginTop: "10px" }}>
                        <label className="col-lg-2 font-weigth-details" style={{ paddingLeft: "0px" }} >Company Name</label>
                        <div className="col-lg-10">
                            <FormControl
                                type="text"
                                defaultValue={this.props.event.company_name}
                                inputRef={ref => {
                                    this.companyName = ref;
                                }}

                            />
                        </div>
                    </div>
                    </div>
                    {/* <div className="col-xs-6">
                        Previous Event Count - {this.props.event.previous_event_count}
                    </div>
                    <div className="col-xs-6">
                        Lead Source - {this.props.event.lead_source}
                    </div> */}
                <div className="row">
                    <div className="col-xs-12">
                        <EventNotes
                            event_id={this.props.event._id}
                        />
                    </div>
                </div>
                    {/* <div className='row'>*/}
                    {/*<div className="col-xs-12">*/}
                    {/*    <FormGroup>*/}
                    {/*        <ControlLabel className="font-weigth-details">Notes</ControlLabel>*/}
                    {/*        <FormControl*/}
                    {/*            componentClass="textarea"*/}
                    {/*            defaultValue={this.props.event.requirements}*/}
                    {/*            style={{ minHeight: "120px" }}*/}
                    {/*            inputRef={ref => {*/}
                    {/*                this.eventnotes = ref;*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </FormGroup>*/}
                    {/*</div>*/}
                    {/*</div>*/}
                    <div className='row'>
                    <div className="col-xs-12 text-center" style={{ marginTop: "24px" }}>
                        <Button
                            disabled={this.props.isUpdating}
                            className="vm-purple-btn"
                            onClick={() => this.updateEvent()}>
                            {this.props.isUpdating ? "Updating Details" : "Update Details"}
                        </Button>
                        <div className="text-center" style={{ marginTop: "20px" }}>
                            <p>You can update details and ensure a re-allotment to a different agent:</p>
                        </div>
                        <div>
                            <div className={'text-center'}>
                                <Button
                                    disabled={this.props.isFindingNewAgent}
                                    onClick={() => this.props.findNewAgent(this.props.event._id)}
                                >
                                    {this.props.isFindingNewAgent ? 'Finding' : 'Find New Agent'}
                                </Button>
                                <div className="col-xs-12">
                                    {this.props.isNewAgentFoundProcessed ?
                                        <span>
                                            {!_.isEmpty(this.props.newFoundAgent) ?
                                                <div className={'text-center'}>
                                                    <div className={'row'}>
                                                        <div className="col-xs-6">
                                                            New Agent Name
                                                        </div>
                                                        <div className="col-xs-6">
                                                            Action
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-xs-6">
                                                            {this.props.newFoundAgent.name}
                                                        </div>
                                                        <div className="col-xs-6">
                                                            <ButtonGroup>
                                                                <Button
                                                                    onClick={() => this.props.clearAgent()}
                                                                >
                                                                    Clear
                                                                </Button>
                                                                <Button
                                                                    onClick={() => this.props.changeAgent(this.props.event._id, this.props.newFoundAgent._id)}
                                                                >
                                                                    Select
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <p>No Agent Found</p>
                                                </div>}
                                        </span>
                                        :
                                        <span></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            // </div>
        );
    }
}

export default EventEditForm;
