import React, {Component} from 'react';
import {
    Button,
    FormGroup,
    FormControl,
    ControlLabel,
    Row,
    Col
} from 'react-bootstrap';

class AddVenueModalForm extends Component {

    constructor() {
        super();
        this.state = {
            qtext: "",
            city: ""
        };
    };

    componentDidMount() {
        if (this.props.event && this.props.event.city) {
            this.setState({
                city: this.capitalizeFirstLetter(this.props.event.city)
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.event.city && (this.props.event.city !== nextProps.event.city)) {
            this.setState({
                city: this.capitalizeFirstLetter(nextProps.event.city)
            });
        }
    }


    changeCity = (e) => {
        this.setState({
            city: e.target.value
        });
    };

    queryText = (e) => {
        if (e.target.value && e.target.value !== '') {
            this.props.query(this.state.city, e.target.value);
        }
    };

    select = (id) => {
        let list = [];
        list.push({
            uid: id
        });
        this.props.selectVenue(this.props.bundleid, list);
    };

    shortList = (id) => {
        this.props.shortlistVenue(this.props.bundleid, id);
    };

    capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    render() {
        return (
            <div className='recommended-box'>
                <div className="list-group-item">
                    <Row>
                        <Col xs={3}>
                            <FormGroup>
                                <ControlLabel>
                                    City
                                </ControlLabel>
                                <FormControl
                                    componentClass="select"
                                    placeholder="code"
                                    value={this.state.city}
                                    inputRef={ref => {
                                        this.queryCity = ref;
                                    }}
                                    onChange={this.changeCity}
                                >
                                    <option value=""></option>
                                    {this.props.cities.map(item => (
                                        <option key={item.value} value={item.label}>{item.label}</option>
                                    ))}
                                </FormControl>
                            </FormGroup>
                        </Col>
                        <Col xs={9}>
                            <FormGroup>
                                <ControlLabel>
                                    Type venue name here
                                </ControlLabel>
                                <FormControl
                                    type="text"
                                    placeholder="search text"
                                    defaultValue={this.state.qtext}
                                    inputRef={ref => {
                                        this.querytext = ref;
                                    }}
                                    onChange={this.queryText}
                                >
                                </FormControl>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div style={{maxHeight: "250px", overflowY: "scroll"}}>
                        {this.props.venues && this.props.venues.length > 0 &&
                            this.props.venues.map(item => (
                                <Row key={item._id}>
                                    <Col xs={6}>
                                        {item.name} , {item.sub_area}
                                    </Col>
                                    <Col xs={6}>
                                        <Button
                                            onClick={() => this.select(item._id)}
                                        >Select</Button>
                                        <Button
                                            disabled={item.isShortlisting}
                                            onClick={() => this.shortList(item._id)}
                                        >{item.isShortlisting ? 'Shortlisting ...' : 'Shortlist'}</Button>
                                    </Col>
                                </Row>
                            ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default AddVenueModalForm;


