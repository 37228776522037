import callEventApi from "../../util/eventApiCaller";
import showToastMessage from "../../util/notificationUtil";
import utility from "../../util/utility";
import moment from 'moment';

export const PROCESS_DIALER_CALL = "PROCESS_DIALER_CALL";

export function processCallToDialer(phone) {
    return (dispatch, getState) => {
        if (phone && utility.isValidIndianMobile(phone)) {
            dispatch({
                type: PROCESS_DIALER_CALL,
                isProcessingCall: true
            });
            let agentid = getState().user?.agent?._id;
            callEventApi(`dialer/send-message`, 'post', {
                "message": {
                    "event": "connectToCall",
                    "agent_id": agentid,
                    "phone_number": phone.toString(),
                    "expiry_time": new Date(moment().add(10, 'seconds').toISOString()).getTime()
                }
            }).then(res => {
                if (res.status === "Success") {
                    showToastMessage('Call Processed', 'success');
                    dispatch({
                        type: PROCESS_DIALER_CALL,
                        isProcessingCall: false
                    })
                } else {
                    showToastMessage('Call Failed', 'error');
                    dispatch({
                        type: PROCESS_DIALER_CALL,
                        isProcessingCall: false
                    })
                }
            }).catch((e) => {
                console.log(e);
            });
        } else {
            showToastMessage('Please enter valid Indian Number', 'error');
        }
    }
}

