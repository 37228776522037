import {
    PROCESS_DIALER_CALL
} from "./DialerActions";

const initialState = {
    isProcessingCall: false,
};

const DialerReducer = (state = initialState, action) => {

    switch (action.type) {
        case PROCESS_DIALER_CALL:
            return {
                ...state,
                isProcessingCall: action.isProcessingCall
            };

        default:
            return state;
    }
};

/* Selectors */
export const getCallProcessingState = (state) => state.dialer.isProcessingCall;

export default DialerReducer;
