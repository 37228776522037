import React, {Component} from 'react';
import {
    FormControl,
    Button
} from 'react-bootstrap';
import SearchSelect from './SearchSelect';
import {connect} from 'react-redux';
import {
    getAsset,
    getEvent,
    getEventtemplates,
    getIsSavingPackage,
    getIsSendingUserEmail,
    getSelectedMenus,
    getSelectedPackage,
    getVenueList
} from '../../../redux/admin/EventReducer';

class PackageInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            packages: [],
            packname: "",
            packprice: "",
            isSelectAll: true,
        };
    }

    componentDidMount() {
        let venues = [];
        venues = this.props.venueList.map(item => {
            item.packname = item.package_name ? item.package_name : "";
            item.price = item.package_price ? item.package_price : "";
            item.selected = true;
            return item;
        });
        this.setState({
            venues: venues
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.venues) {
            let venues = [];
            venues = nextProps.venues.map(item => {
                item.packname = item.package_name ? item.package_name : "";
                item.price = item.package_price ? item.package_price : "";
                item.selected = true;
                return item;
            });
            this.setState({
                venues: venues
            });
        }
    }

    handleSelectionChange = (id) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.selected = !item.selected;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    handlePackName = (id, val) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.packname = val.name;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    handlePackPrice = (id, e) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.price = e.target.value;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    handleCommonPackage = (val) => {
        this.setState({
            packname: val.name
        });
    };

    handleCommonPrice = (e) => {
        this.setState({
            packprice: e.target.value
        });
    };

    flowPackage = () => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            item.packname = this.state.packname;
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    flowPrice = () => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            item.price = this.state.packprice;
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    addSelectedPackages = () => {
        let venueList = this.state.venues.filter(item => item.selected).map(item => {
            return item;
        });
        venueList.forEach(venue => {
            let subpackage = {};
            subpackage.eventId = this.props.event._id;
            subpackage.venueId = venue.uid._id;
            subpackage.name = venue.packname;
            subpackage.package_price = venue.price;
            subpackage.isMenuAvailable = false;
            subpackage.menuItems = [];
            this.props.addPackage(venue.uid._id, venue._id, subpackage);
        });
    };

    addProposal = (venueId, subId, venue) => {
        let subpackage = {};
        subpackage.eventId = this.props.event._id;
        subpackage.venueId = venueId;
        let venueSelected = this.state.venues.filter(item => item._id === subId);
        subpackage.name = venueSelected[0].packname;
        subpackage.package_price = venueSelected[0].price;
        subpackage.isMenuAvailable = false;
        subpackage.menuItems = [];
        this.props.addPackage(venueId, subId, subpackage);
        let newVenues = this.state.venues.map((data) => {
            if (data?._id.toString() === venue?._id.toString()) {
                data.price = '';
                data.packname = '';
                return data;
            }
            return data
        })
        this.setState({
            venues: newVenues,
            packprice: '',
            packname: ''
        })

        console.log("proposal", this.state.venues)
    };

    getProcessingStateForButton = (venues) => {
        let isProcessing = false;
        venues.forEach(item => {
            if (item.isProcessingPackages) {
                isProcessing = true;
            }
        });
        return isProcessing;
    };

    toggleAll = () => {
        let isSelect = !this.state.isSelectAll;
        let newArray = this.state.venues.map(item => {
            item.selected = isSelect;
            return item;
        });
        this.setState({
            isSelectAll: isSelect,
            venues: newArray
        });
    };

    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Package Information
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Select</th>
                        <th>Venue Details</th>
                        <th>Package</th>
                        <th>Price</th>
                        <th>Add</th>
                    </tr>
                    <tr>
                        <th></th>
                        <th></th>
                        <th>
                            <SearchSelect
                                values={[{name: this.state.packname}]}
                                getSelectedValue={(val) => this.handleCommonPackage(val)}
                                placeholder={<span>&nbsp; Select Package Type </span>}
                                isCreatable={true}
                                valueKey={'name'}
                                labelKey={'name'}
                                isMultiSelect={false}
                                searchAPI={this.props.getPackageOptions}
                            />
                        </th>
                        <th>
                            <FormControl
                                type="number"
                                value={this.state.packprice}
                                placeholder="Price"
                                onChange={(e) => this.handleCommonPrice(e)}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th>
                            <Button
                                onClick={() => this.toggleAll()}
                            >
                                {this.state.isSelectAll ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}
                            </Button>
                        </th>
                        <th></th>
                        <th>
                            <Button
                                onClick={() => this.flowPackage()}
                            >
                                Flow Package
                            </Button>
                        </th>
                        <th>
                            <Button
                                onClick={() => this.flowPrice()}
                            >
                                Flow Price
                            </Button>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.venues.length < 1 ? <tr>
                        <td>No Venues</td>
                    </tr> : <tr></tr>}
                    {this.state.venues.map((item) => (
                        <React.Fragment key={item._id}>
                            <tr className="mngevent-email-details">
                                <td>
                                    <Button
                                        onClick={() => this.handleSelectionChange(item._id)}
                                        className={"btn-default"}
                                    >
                                        {item.selected ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}
                                    </Button>
                                </td>
                                <td>
                                    {item.uid.name}, {item.uid.sub_area}, {item.uid.city} - {this.props.assets[item.uid._id] && this.props.assets[item.uid._id].subpackages && this.props.assets[item.uid._id].subpackages.length ? this.props.assets[item.uid._id].subpackages.length : 0}
                                </td>
                                <td style={{width: '25%'}}>
                                    <SearchSelect
                                        values={[{name: item.packname}]}
                                        getSelectedValue={(val) => this.handlePackName(item._id, val)}
                                        placeholder={<span>&nbsp; Select Package Type </span>}
                                        isCreatable={true}
                                        valueKey={'name'}
                                        labelKey={'name'}
                                        isMultiSelect={false}
                                        searchAPI={this.props.getPackageOptions}
                                    />
                                </td>
                                <td>
                                    <FormControl
                                        type="number"
                                        value={item.price}
                                        placeholder="Price"
                                        onChange={(e) => this.handlePackPrice(item._id, e)}
                                    />
                                </td>
                                <td>
                                    <Button
                                        disabled={item.isProcessingPackages}
                                        onClick={() => this.addProposal(item.uid._id, item._id, item)}
                                    >{item.isProcessingPackages ? <i className="fa fa-refresh fa-spinner"/> :
                                        <i className="fa fa-plus"/>}
                                    </Button>
                                </td>
                            </tr>
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
                <div className="panel-footer">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2">
                            <Button
                                disabled={this.getProcessingStateForButton(this.state.venues)}
                                onClick={() => this.addSelectedPackages()}
                                className="btn-default"
                            >{this.getProcessingStateForButton(this.state.venues) ? "Adding" : "Add Packages"}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

function mapStateToProps(state, props) {
    return {

        selectedMenus: getSelectedMenus(state),
        selectedPackage: getSelectedPackage(state),
        isSavingPackage: getIsSavingPackage(state),
        event: getEvent(state),
        venueList: getVenueList(state),
        assets: getAsset(state),
        isSendingUserEmail: getIsSendingUserEmail(state),
        eventtemplates: getEventtemplates(state),

    }
}

export default connect(mapStateToProps)(PackageInfo);
