import React, {Component} from 'react';
import Select from 'react-select';

class SearchSelect extends Component {

    constructor() {
        super();
        this.state = {
            values: []
        };
    }

    componentDidMount() {
        this.setState({
            values: this.props.values
        });
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            values: nextProps.values
        });
    }

    onChange = (value) => {
        if (!this.props.isMultiSelect) {
            if(value.length > 1) {
                this.setState({values: value[1]});
                this.props.getSelectedValue(value[1]);
            } else if(value.length === 1) {
                this.setState({values: value[0]});
                this.props.getSelectedValue(value[0]);
            } else {
                this.setState({values: null});
            }
        } else {
            this.setState({values: value});
            this.props.getSelectedValue(value);
        }
    };

    getOptions = (input) => {
        return this.props.searchAPI(input);
    };

    render() {
        const SelectComponent = this.props.isCreatable ? Select.AsyncCreatable : Select.Async;
        return (
            <SelectComponent
                style={{paddingLeft: "10px", minWidth:'140px'}}
                multi={true}
                value={this.state.values}
                onChange={this.onChange}
                valueKey={this.props.valueKey}
                labelKey={this.props.labelKey}
                loadOptions={this.getOptions}
                backspaceRemoves={true}
                placeholder={this.props.placeholder}
            />
        );
    }
}

export default SearchSelect;
