import React from 'react';
import moment from 'moment';

function EventLogs(props) {
    if (props.logs && props.logs.length > 0) {
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="panel">
                        <div className="panel-heading">
                            Logs
                        </div>
                        <div className="list-group" style={{maxHeight: "300px", overflowY: "scroll"}}>
                            {props.logs.reverse().map((item, i) => (
                                <div className="list-group-item" key={item._id}>
                                    <h6 className="media-heading">{item.message}</h6>
                                    <p><i>{moment(item.date).format("Do MMM YYYY")}</i></p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="row">
                <div className="col-xs-12">
                    <div className="text-center">
                        No log found.
                    </div>
                </div>
            </div>
        );
    }
}

export default EventLogs;
