import React, { useState, useEffect } from 'react';
import moment from "moment";
import {
    Button, Tab, Tabs
} from 'react-bootstrap';
import SubpackageInfo from './SubpackageInfo';
import EditPackageSection from './EditSubpackageSection';
import VenueMenu from './VenueMenu';
import PackageInfo from './PackageInfo';
import BookedVisitsInfo from './BookedVisitsInfo';
import VisitInfo from './VisitInfo';
import {useSelector, useDispatch} from 'react-redux';
import { setSelectedVenue } from '../../../redux/admin/EventActions';
import SingleVenueSubPackage from './SingleVenueSubPackage';
import RackRate from './RackRate';
import { getAgentName } from '../../../util/utility';
import { getActiveAgent } from '../../../redux/admin/UserReducer';
import EventTemplate from './EventTemplates';

function ShortlistedVenues(props) {
    const [shortlisted, setShortlisted] = useState(0);
    const [activepProposalTab, setProposalTabChange] = useState(1);
    const [activeVisitTab, setVisitTabChange] = useState(1);
    const [eventTemplate, setEventTemplate] = useState(false);

    

    const selectedVenueID = useSelector(state => state.event.selectedVenueID)
    const activeAgent = useSelector(state => getActiveAgent(state));
    const dispatch = useDispatch();

    const handleProposalTabChange = (key) => {
        setProposalTabChange(key)
    }

    const handlevisitTabChange = (key) => {
        setVisitTabChange(key)
    }

    if (props.venues && props.venues.length > 0) {

        return (
            <React.Fragment>
                <div className="panel panel-default shortlist-panel-overall">
                    {/* <div className="shortlist-heading-1 ">
                    </div> */}
                    <div className="shortlist-heading-2" style={{ minHeight: '50px', overflowY: 'auto' }}>
                        <span className={'btn tags click-shortlist-venue margin-heading'} onClick={() => dispatch(setSelectedVenue(''))}>View All</span>
                        {props.venues.map((item) => (
                            <span className='btn tags click-shortlist-venue' onClick={() => dispatch(setSelectedVenue(item._id))}>{item.uid.name}</span>
                        ))}
                    </div>
                </div>
                <div className="panel panel-default shortlist-panel-overall switch-template">
                    <span className='btn tags' onClick={() => setEventTemplate(!eventTemplate)}>{'Switch to templates'}</span>
                {
                    eventTemplate ? 
                    <EventTemplate
                    eventtemplates={props.eventtemplates}
                    send={props.send} 
                    showMessage={props.showMessage}
                    event={props.event}
                    />
                    :
                    <>
                    </>
                }
                </div>
                {
                    selectedVenueID.length > 0 ?
                        <>
                            {props.venues.map((item) => {
                                if (item._id == selectedVenueID) {
                                    return <div className="panel" key={item._id}>
                                        <div className="panel-heading">
                                            <span>{item.uid.name} Shortlisted Packages - {props.assets[item.uid._id] && props.assets[item.uid._id].subpackages && props.assets[item.uid._id].subpackages.length ? props.assets[item.uid._id].subpackages.length : 0} </span>
                                            <span className="pull-right display-flex display-flex-shortlist">
                                                {props.assets && props.assets[item.uid._id] && props.assets[item.uid._id].menus && props.assets[item.uid._id].menus.length > 0 ?
                                                    <div className="mright-1">
                                                        <Button
                                                            onClick={() => props.showVenueMenu(item.uid._id, !!!item.isVenueMenuVisible)}
                                                        >View Venue Menu</Button>
                                                    </div> :
                                                    null
                                                }
                                                <Button
                                                    onClick={() => props.removeVenue(props.bundleid, item.uid._id)}
                                                    className="">
                                                    <i className="fa fa-trash-o"></i>
                                                </Button>
                                            </span>
                                        </div>
                                        <div className="panel-body">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <i className="fa fa-map-marker fa-fw"></i> {item.uid.sub_area}, {item.uid.city}
                                                    <br />
                                                    {item.isFeatured ? <span><i className="fa fa-star"></i> Featured</span> : ""} <br />
                                                    <i className="fa fa-users fa-fw"></i> Maximum
                                                    Capacity: {item.uid.cap_max} people <br />
                                                    <i className="fa fa-power-off fa-fw"></i> Show to
                                                    User: {item.userShow ? "Yes" : "No"} <br />
                                                    <i className="fa fa-times fa-fw"></i> User Reject: {item.userReject ? "Yes" : "No"}
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                    <i className="fa fa-calendar fa-fw"></i> Availability : {item.availability}
                                                    <br />
                                                    <i className="fa fa-tasks fa-fw"></i> Packages Request: <br />
                                                    <i className="fa fa-clock-o fa-fw"></i> Scheduled Visit {item.venue_visit} <br />
                                                    <i className="fa fa-check fa-fw"></i> User
                                                    Shortlisted: {item.userSelected ? "Yes" : "No"}
                                                </div>
                                                <div className="col-xs-12">
                                                    Manager Details - {props.assets[item.uid._id] && props.assets[item.uid._id].contact ? `${props.assets[item.uid._id].contact.first_name} ${props.assets[item.uid._id].contact.last_name}` : ""} ,
                                                    {
                                                        props.assets[item.uid._id] && props.assets[item.uid._id].contact ?
                                                            <a onClick={() => props.connectCall(props.assets[item.uid._id].contact.contact_phone)}>
                                                                <i style={{ color: "#491369" }} className={"fa fa-phone"} />
                                                            </a>
                                                            : ""}

                                                </div>
                                                <br />

                                            </div>
                                        </div>
                                        {!!item.isVenueMenuVisible ?
                                            <div className="list-group">
                                                {props.assets && props.assets[item.uid._id] && props.assets[item.uid._id].menus && props.assets[item.uid._id].menus.length > 0 ?
                                                    props.assets[item.uid._id].menus.map(menu => (
                                                        <div key={menu._id} className={'list-group-item'}>
                                                            <div className="row">
                                                                <div className={'col-xs-4'}>
                                                                    {menu.name}
                                                                </div>
                                                                <div className={'col-xs-8'}>
                                                                    {menu.items ?
                                                                        menu.items.map(menuItem => `${menuItem.name} ${menuItem.price ? `- ${menuItem.price}, ` : `, `}`).join('')
                                                                        :
                                                                        <span>No Menu Added</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )) :
                                                    <div className={'list-group-item'}>
                                                        <div className="row">
                                                            <div className={'col-xs-12'}>
                                                                No Menu Added
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                }
                            })}

                {props.isEditingPackage ?
                    <EditPackageSection
                        connectCall={props.connectCall}
                        updatePackage={props.updatePackage}
                        closeEditPackageForm={props.closeEditPackageForm}
                        showMessage={props.showMessage}
                    /> :
                    null
                }
                {props.isViewingPackageMenu ?
                    <VenueMenu
                        package={props.selectedPackage}
                        closeVenueMenu={props.closeVenueMenu}
                    /> :
                    null
                }
                            {/* {this.props.isEditingVisit ?
                                <EditVisitSection
                                    updateVisit={this.updateVisit}
                                    closeEditVisitForm={this.closeEditVisitForm}
                                    showMessage={this.props.showMessage}
                                /> :
                                null
                            }
                            {
                                this.props.isSchedulingVisit ?
                                    <ScheduleVisitSection
                                        visitId={this.props.scheduleVisitId}
                                        showMessage={this.props.showMessage}
                                        handleScheduleVisit={this.handleScheduleVisit}
                                    />
                                    : null
                            } */}
                <div className="panel panel-default shortlist-panel-overall">

                    <Tabs
                        activeKey={activepProposalTab}
                        animation={false}
                        id='noanim-tab-example'
                        onSelect={handleProposalTabChange}
                    >
                        <Tab eventKey={1} title='Proposed Packages'>
                            <div style={{ position: 'relative', display: 'block', width: '100%' }}>
                                <SingleVenueSubPackage
                                    venues={props.venueList}
                                    activeAgent={activeAgent}
                                    send={props.send}
                                    getPackageOptions={props.getPackageOptions}
                                    addPackage={props.addPackage}
                                    editPackage={props.editPackage}
                                    showMenu={props.showMenu}
                                    removeSubpackage={props.removeSubpackage}
                                    updatePackage={props.updatePackage}
                                    updateAssetData={props.updateAssetData}
                                    eventtemplates={props.eventtemplates}
                                    event={props.event}
                                />
                            </div>
                        </Tab>

                        <Tab eventKey={2} title='Add Packages'>
                            <PackageInfo
                                showMessage={props.showMessage}
                                send={props.sendEmailForPackageInfoToUser}
                                getPackageOptions={props.getPackageOptions}
                                addPackage={props.addPackage}
                            />                </Tab>
                        
                        <Tab eventKey={3} title='Rack Rate'>
                            <div className="">
                                <RackRate 
                                 removeVenue={props.removeVenue}
                                 showMenuForVenuePackage={props.showMenuForVenuePackage}
                                 showVenueMenu={props.showVenueMenu}
                                 clonePackage={props.clonePackage}
                                 checkIfAdded={props.checkIfAdded} // TODO utility
                                 showMessage={props.showMessage}
                                />
                            </div>

                        </Tab>
                    </Tabs>
                </div>

                <div>
                    <Tabs 
                     activeKey={activeVisitTab}
                     animation={false}
                     id='noanim-tab-example-visit'
                     onSelect={handlevisitTabChange}
      >
                    <Tab eventKey={1} title='Planned Visits'>
                        <BookedVisitsInfo
                            showMessage={props.showMessage}
                            sendEmail={props.sendEmailForVisitInfoToUser}
                            editVisit={props.editVisit}
                            openScheduleVisit={props.openScheduleVisit}
                            removeVisit={props.removeVisit}
                            updateVisit={props.updateVisit}
                            updateAssetData={props.updateAssetData}
                        />
                    </Tab>
                    <Tab eventKey={2} title='Add Visits'>
                        <VisitInfo
                            showMessage={props.showMessage}
                            planVisit={props.planVisit}
                        />
                    </Tab>
                    </Tabs>
                </div>

                        </>
                        :
                        <>
                            {props.venues.map((item) => (
                                <div className="panel" key={item._id}>
                                    <div className="panel-heading">
                                        <span>{item.uid.name} Shortlisted Packages - {props.assets[item.uid._id] && props.assets[item.uid._id].subpackages && props.assets[item.uid._id].subpackages.length ? props.assets[item.uid._id].subpackages.length : 0} </span>
                                        <span className="pull-right display-flex display-flex-shortlist">
                                            <Button
                                                onClick={() => props.removeVenue(props.bundleid, item.uid._id)}
                                                className="">
                                                <i className="fa fa-trash-o"></i>
                                            </Button>
                                        </span>
                                    </div>
                                    <div className="panel-body">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <i className="fa fa-map-marker fa-fw"></i> {item.uid.sub_area}, {item.uid.city}
                                                <br />
                                                {/* {item.isFeatured ? <span><i className="fa fa-star"></i> Featured</span> : ""} <br /> */}
                                                <i className="fa fa-users fa-fw"></i> Maximum
                                                Capacity: {item.uid.cap_max} people <br />
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                                <i className="fa fa-power-off fa-fw"></i> Show to
                                                User: {item.userShow ? "Yes" : "No"} <br />
                                                <i className="fa fa-times fa-fw"></i> User Reject: {item.userReject ? "Yes" : "No"}<br />
                                                <i className="fa fa-tasks fa-fw"></i> Packages Request: <br />
                                            </div>

                                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                            <i className="fa fa-check fa-fw"></i> User
                                                Shortlisted: {item.userSelected ? "Yes" : "No"}<br />
                                                <i className="fa fa-calendar fa-fw"></i> Availability : {item.availability}
                                                <br />
                                                
                                                <i className="fa fa-clock-o fa-fw"></i> Scheduled Visit {item.venue_visit} <br />
                                                
                                            </div>
                                            <div className="col-xs-12">
                                            <b> Manager Details -</b>{props.assets[item.uid._id] && props.assets[item.uid._id].contact ? `${props.assets[item.uid._id].contact.first_name} ${props.assets[item.uid._id].contact.last_name}` : ""} ,
                                                {
                                                    props.assets[item.uid._id] && props.assets[item.uid._id].contact ?
                                                        <a onClick={() => props.connectCall(props.assets[item.uid._id].contact.contact_phone)}>
                                                            <i style={{ color: "#491369" }} className={"fa fa-phone"} />
                                                        </a>
                                                        : ""}

                                            </div>
                                            <br />

                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-8 col-xs-8'> 
                                            <div className="row" style={{marginTop:"6px", marginBottom:"6px"}}>
                                            <div className="col-lg-12 col-xs-12">
                                            <b>Planned Visit</b>
                                            </div>
                                            {props.assets[item.uid._id] && props.assets[item.uid._id].visits && props.assets[item.uid._id].visits.length ?
                                                props.assets[item.uid._id].visits.filter(data => data.isVisitCompleted == false).map(visit => (
                                                    <div className="row" style={{margin: "0px"}}>
                                                    
                                                        <div className="col-lg-4 col-xs-4">
                                                            {moment(new Date(visit.visit_date)).format('DD-MMM-YYYY - HH:mm')}
                                                        </div>
                                                        <div className="col-lg-3 col-xs-3">
                                                            Status: {visit.isVisitCompleted ? 'Completed' : ''}
                                                            {visit.isVisitCancelled ? 'Cancelled' : ''}
                                                        </div>
                                                        <div className="col-lg-5 col-xs-5">
                                                            Agent: {getAgentName(props.totalAgentMap, visit.agentId)}
                                                        </div>
                                                    
                                                    </div>
                                                ))
                                                :
                                                null
                                            }
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12 col-xs-12">
                                                <b>Recent Visit</b>
                                            </div>
                                            {props.assets[item.uid._id] && props.assets[item.uid._id].visits && props.assets[item.uid._id].visits.length ?
                                                props.assets[item.uid._id].visits.filter(data => data.isVisitCompleted == true).map(visit => (
                                                    <div className="row" style={{margin: "0px"}}>
                                                        <div className="col-lg-4 col-xs-4">
                                                            {moment(new Date(visit.visit_date)).format('DD-MMM-YYYY - HH:mm')}
                                                        </div>
                                                        <div className="col-lg-3 col-xs-3">
                                                            Status: {visit.isVisitCompleted ? 'Completed' : ''}
                                                            {visit.isVisitCancelled ? 'Cancelled' : ''}
                                                        </div>
                                                        <div className="col-lg-5 col-xs-5">
                                                            Agent: {getAgentName(props.totalAgentMap, visit.agentId)}
                                                        </div>
                                                    </div>
                                                ))
                                                :
                                                null
                                            }
                                        </div>
                                            </div>
                                            <div className='col-lg-4 col-xs-4'>

                                                <b>Proposed Packages</b><br/>
                                                {props.assets[item.uid._id] && props.assets[item.uid._id].subpackages && props.assets[item.uid._id].subpackages.length ?
                                                props.assets[item.uid._id].subpackages.map(subpackage => (
                                                    <div className="row">
                                                    {/* <div className="col-lg-9 col-xs-9"> */}
                                                        <div className="col-lg-6 col-xs-6">
                                                            Package: {subpackage.name}
                                                        </div>
                                                        <div className="col-lg-6 col-xs-6">
                                                            Price: {subpackage.package_price}
                                                        </div>
                                                    {/* </div> */}
                                                    </div>
                                                ))
                                                :
                                                null
                                            }
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            ))}
                        </>
                }                
            </React.Fragment>
        );
    } else {
        return (
            <div className="list-group text-center">
                <div className="list-group-item">
                    No Venues Found
                </div>
            </div>
        );
    }
}

export default ShortlistedVenues;
