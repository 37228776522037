import React, {Component} from 'react';
import moment from 'moment';
import {
    FormControl,
    Button
} from 'react-bootstrap';
import _ from 'lodash';
import {CopyToClipboard} from "react-copy-to-clipboard";
import Handlebars from "handlebars";
import he from "he";

Handlebars.registerHelper("inc", function(value, options)
{
    return parseInt(value) + 1;
});

class SmsVenueSegment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            text: '',
            selectedOption: '',
            selectedUserOption: '',
        };
    }

    componentDidMount() {
        let venues = [];
        venues = this.props.venues.map(item => {
            return item;
        });
        this.setState({
            venues: venues
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.venues) {
            let venues = [];
            venues = nextProps.venues.map(item => {
                return item;
            });
            this.setState({
                venues: venues
            });
        }
    }

    handleSelectionChange = (value) => {
        if (value) {
            let selectedTemplate = this.props.venueSmsTemplates.filter(item => item._id === value);
            let input = this.getInputTemplateData();
            let templateBody = Handlebars.compile(selectedTemplate[0].template);
            let smstext = templateBody(input).toString();
            smstext = he.decode(smstext);
            this.setState({
                selectedOption: value,
                text: smstext
            });
        }
    };

    getInputTemplateData = () => {
        let input = {};
        let event = _.clone(this.props.event);
        input.event = event;
        input.event.occasion = event.occasion[0] ? event.occasion[0] : '';
        input.event.event_date = moment(event.event_date).format('DD MMM YYYY');
        input.event.city = this.props.event.city ? _.startCase(this.props.event.city) : '';
        input.venues = this.props.venues.map(venue => {
            return {
                venueName: venue.uid.name,
                manager_name: venue.manager_name,
                manager_phone: venue.manager_phone,
            };
        });
        return input;
    };

    handleUserSelectionChange = (value) => {
        if (value) {
            let selectedTemplate = this.props.userSmsTemplates.filter(item => item._id === value);
            let input = this.getInputTemplateData();
            let templateBody = Handlebars.compile(selectedTemplate[0].template);
            let smstext = templateBody(input).toString();
            smstext = he.decode(smstext);
            this.setState({
                text: smstext,
                selectedUserOption: value
            });
        }
    };

    sendVenueSms = (cid) => {
        let text = this.smstext.value;
        this.props.sendSms(cid, text);
    };

    sendUserSms = () => {
        let data = {
            phone: this.props.event.contact_phone,
            text: this.smstext.value
        };
        this.props.sendUserSms(this.props.bundleid, data);
    };

    handleChange = (e) => {
        this.setState({
            text: e.target.value
        });
    };


    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Sms Templates Information
                </div>
                <div className="panel-body">
                    <div className="row">
                        <div className="col-xs-8">
                            <form className="form-horizontal tabular-form">
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">Venue Sms Options</label>
                                    <div className="col-sm-8 tabular-border">
                                        <FormControl
                                            value={this.state.selectedOption}
                                            componentClass="select"
                                            placeholder="select"
                                            onChange={(e) => this.handleSelectionChange(e.target.value)}
                                        >
                                            <option value=""></option>
                                            {this.props.venueSmsTemplates && this.props.venueSmsTemplates.map(item => (
                                                <option value={item._id}>{item.description}</option>
                                            ))}
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label className="col-sm-4 control-label">User Sms Options</label>
                                    <div className="col-sm-8 tabular-border">
                                        <FormControl
                                            defaultValue={this.state.selectedUserOption}
                                            componentClass="select"
                                            placeholder="select"
                                            onChange={(e) => this.handleUserSelectionChange(e.target.value)}
                                        >
                                            <option value=""></option>
                                            {this.props.userSmsTemplates && this.props.userSmsTemplates.map(item => (
                                                <option value={item._id}>{item.description}</option>
                                            ))}
                                        </FormControl>
                                        <Button
                                            disabled={this.props.isSending}
                                            onClick={() => this.sendUserSms()}
                                        >
                                            {this.props.isSending ? "Sending Sms" : "Send User Sms"}
                                        </Button>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <div className="col-sm-2">
                                        <CopyToClipboard text={this.state.text}
                                                         onCopy={() => this.props.showMessage('copied', 'success')}>
                                            <Button>Copy <i className="fa fa-clipboard"/></Button>
                                        </CopyToClipboard>
                                        <a
                                            className="btn btn-default"
                                            href={`https://api.whatsapp.com/send?phone=91${this.props.event.contact_phone}`} target="_blank">
                                            <i style={{color: "#491369"}} className={"fa fa-whatsapp"} />
                                        </a>
                                    </div>
                                    <div className="col-sm-10 tabular-border">
                                        <FormControl
                                            style={{minHeight: "130px"}}
                                            className="full-width"
                                            componentClass="textarea"
                                            value={this.state.text}
                                            onChange={(e) => this.handleChange(e)}
                                            inputRef={ref => {
                                                this.smstext = ref;
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="col-xs-4">
                            {this.state.venues.length < 1 ? "No Venues" : ""}
                            {this.state.venues.map(item => (
                                <div className="well" key={item._id}>
                                    <div className="row">
                                        <div className="col-xs-6">
                                            <p>{item.uid.name}</p>
                                        </div>
                                        <div className="col-xs-6">
                                            {item.uid.venuecontact ?
                                                <Button
                                                    disabled={this.props.isSending}
                                                    block
                                                    onClick={() => this.sendVenueSms(item.uid.venuecontact)}
                                                >
                                                    {this.props.isSending ? " Sending Sms" : " Send Sms"}
                                                </Button>
                                                :
                                                <Button
                                                    block
                                                    href={`/venues/${item.uid.city}/${item.uid.vm_id}/update`}
                                                >
                                                    Add Contacts
                                                </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default SmsVenueSegment;
