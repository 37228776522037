import React from 'react';
import BasicLoader from "../Loader/BasicLoader";

export default function PageAccessControl(props) {

    const hasPermission = () => {
        if (props.shouldHavePermissions && props.shouldHavePermissions.length > 0) {
            if (props.userPermissions) {
                let userPermissions = props.userPermissions;
                let shouldHavePermissions = props.shouldHavePermissions;
                let leftPermissions = shouldHavePermissions.filter(item => userPermissions.indexOf(item) !== -1);
                return leftPermissions && leftPermissions.length > 0;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    if(props.userPermissions && props.userPermissions.length > 0) {
        if(hasPermission()) {
            return props.children;
        } else {
            return(
                <div className={'text-center'}>
                    <h1>You Don't Have Permission To Access This Page</h1>
                </div>
            );
        }
    } else {
        return(
            <div className={'text-center'}>
                <BasicLoader />
            </div>
        );
    }
    // if(hasPermission()) {
    //     return props.children;
    // } else {
    //     return(
    //         <div className={'text-center'}>
    //             <h1>You Don't Have Permission To Access This Page</h1>
    //         </div>
    //     );
    // }
}

