import React, {Component} from 'react';
import {
    DropdownButton,
    MenuItem,
} from 'react-bootstrap';
import cities from '../../constants/cities.json';
import callApi from '../../util/apiCaller';

const selectFrom = {_id: 1, contact_name: 1, contact_email: 1, contact_phone: 1, enquiry_num: 1};

const list = [
    "/manager/users/list",
    "/manager/agents/list",
    "/manager/enquiry/leads-board",
    "/manager/tasks/list",
    "/manager/venue/list",
    "/manager/venue/report",
    "/manager/venue/create-venue",
    "/manager/venue/onboarding-list",
    "/manager/venue/featured",
    "/manager/venue/add",
    "/manager/venue/update",
    "/manager/venue/image",
    "/manager/venue/menu",
    "/manager/venue/package/add",
    "/manager/venue/package/update",
    "/manager/vendors/caterer/add",
    "/manager/vendors/caterer/update",
    "/manager/vendors/decorator/add",
    "/manager/vendors/decorator/update",
    "/manager/vendors/makeupartist/add",
    "/manager/vendors/makeupartist/update",
    "/manager/vendors/planner/add",
    "/manager/vendors/planner/update",
    "/manager/vendors/photographer/add",
    "/manager/vendors/photographer/update",
    "/manager/expressbook/add",
    "/manager/expressbook/update",
    "/manager/expressbook/package-buyers/list",
    "/manager/expressbook/birthday-party/add",
    "/manager/expressbook/birthday-party/list",
    "/manager/expressbook/party/add",
    "/manager/services/sms/templates",
    "/manager/services/sms/tasks",
    "/manager/services/email/templates",
    "/manager/services/email/tasks",
    "/manager/content/recurringevents/list",
    "/manager/services/scripts/file-upload",
    "/manager/content/subareas/list",
    "/manager/content/pagetags/list",
    "/manager/content/package-suggestions/list",
    "/manager/content/occasions/list",
    "/manager/content/newsletters/list",
    "/manager/content/jobs/list",
    "/manager/content/imagetags/list",
    "/manager/content/footerlinks/list",
    "/manager/content/footerblogs/list",
    "/manager/content/categories/list",
    "/manager/content/cuisines/list",
    "/manager/content/listbizs/list",
    "/manager/content/colorlist/list",
    "/manager/collection/list",
    "/manager/collection/add",
    "/manager/collection/update",
    "/manager/enquiry/list",
    "/manager/enquiry/closed-won",
    "/manager/enquiry/feedback",
    "/manager/enquiry/kanban",
    "/manager/enquiry/calendar",
    "/manager/enquiry/list/newenquiry",
    "/manager/enquiry/list/verified",
    "/manager/enquiry/list/send-options",
    "/manager/enquiry/list/call-back",
    "/manager/enquiry/list/call-later",
    "/manager/enquiry/list/did-not-pick",
    "/manager/enquiry/list/already-booked",
    "/manager/enquiry/list/not-interested",
    "/manager/enquiry/list/converted",
    "/manager/enquiry/list/not-converted",
    "/manager/enquiry/list/fake",
    "/manager/enquiry/list/repeat",
    "/manager/realevents/create",
    "/manager/realevents/list",
    "/manager/bundle/add",
    "/manager/bundle/update",
    "/manager/bundle/standards/list",
    "/manager/analytics/date",
    "/manager/reports/realtime",
    "/manager/reports/monthly",
    "/manager/content/dropdowns/list",
    "/manager/content/pagedatas/list",
];

const listBox = {
    "width": "450px",
    "position": "absolute",
    "minHeight" : '0px',
    "maxHeight": "150px",
    "overflowY": "scroll",
    "top": "56px",
    "backgroundColor": "#fff",
    "border": "solid 1px #ececec"
};

const listBoxItem = {
    "padding": "3px",
    "borderBottom": "solid 1px #ececec",
    "listStyle": 'none'
};

class SearchSelect extends Component {

    constructor() {
        super();
        this.state = {
            list: list,
            cities: cities,
            searchBy: 'List',
            eventSearchList: [],
            venueSearchList: [],
            listSearch: [],
            pageNum: 1,
            pageSize: 50,
            isSearchEnabled: false
        }
    }

    componentDidMount() {
    }

    handleSearchChange = (value) => {
        this.setState({
            searchBy: value
        });
    };

    handleQueryChange = (e) => {
        let data = {};
        data.pageNum = this.state.pageNum;
        data.pageSize = this.state.pageSize;
        data.qtext = e.target.value;
        if (this.state.searchBy === 'List') {
            if (data.qtext.length >= 1) {
                let newList = this.state.list.filter(item => {
                    if (item.indexOf(data.qtext) > -1) {
                        return item;
                    }
                });
                this.setState({
                    listSearch: newList
                });
            } else {
                this.setState({
                    listSearch: [],
                    isSearchEnabled: false
                });
            }
        } else if (this.state.searchBy === 'Events') {
            if (data.qtext.length >= 3) {
                this.fetchEventsForSearch(data, (err, resp) => {
                    if (!err) {
                        this.setState({
                            eventSearchList: resp.events
                        });
                    }
                });
            } else {
                this.setState({
                    eventSearchList: [],
                    isSearchEnabled: false
                });
            }
        } else {
            if (data.qtext.length >= 2) {
                data.city = this.state.searchBy.toLowerCase();
                this.fetchVenuesForSearch(data, (err, resp) => {
                    if (!err) {
                        this.setState({
                            venueSearchList: resp
                        });
                    }
                });
            } else {
                this.setState({
                    venueSearchList: [],
                    isSearchEnabled: false
                });
            }
        }
    };

    fetchEventsForSearch = (params, next) => {
        callApi(`v3/events/search`, 'POST', {
            pageNum: params.pageNum,
            pageSize: params.pageSize,
            qtext: params.qtext,
            selectFrom: selectFrom
        }).then(res => {
            return next(null, res.data);
        }).catch(err => {
            return next(err);
        });
    };

    fetchVenuesForSearch = (params, next) => {
        callApi(`venueslistquery/${params.city}/${params.qtext}`, 'GET')
            .then(res => {
                return next(null, res.data);
            }).catch(err => {
            return next(err);
        });
    };

    handleSearching = (value) => {
        this.setState({
            isSearchEnabled: value
        });
    };

    render() {
        return (
            <div className="">
                <div className="input-group" id="searchbox">
                    <div className="input-group-btn">
                        <DropdownButton title={`Search by: ${this.state.searchBy}`} id="dropdown-size-medium"
                                        style={{width: '175px', borderRadius: '30px', borderColor: '#ccc'}}>
                            <MenuItem eventKey="1"
                                      onClick={() => this.handleSearchChange('Events', null)}>Events</MenuItem>
                            <MenuItem divider/>
                            <MenuItem eventKey="2">Venues</MenuItem>
                            {this.state.cities.map(item => (
                                <MenuItem key={item.name} onClick={() => this.handleSearchChange(item.name)}>{item.name}</MenuItem>
                            ))}
                            <MenuItem divider/>
                            <MenuItem eventKey="3" onClick={() => this.handleSearchChange('List')}>List</MenuItem>
                        </DropdownButton>
                    </div>
                    <input
                        style={{width: '300px'}}
                        type="text"
                        className="form-control"
                        placeholder={`Search here by ${this.state.searchBy}...`}
                        ref="searchText"
                        onChange={(e) => this.handleQueryChange(e)}
                        onFocus={() => this.handleSearching(true)}
                    />
                </div>
                <div style={listBox}>
                    {this.state.searchBy === 'Events' && this.state.eventSearchList.length > 0 ?
                        <ul>
                            {this.state.eventSearchList && this.state.eventSearchList.map(item => (
                                <li key={item._id} style={listBoxItem}>
                                    <a href={`/events/${item._id}/update`}>{`${item.enquiry_num}, ${item.contact_name}, ${item.contact_email}`}</a>
                                </li>
                            ))}
                        </ul>
                        : null}
                    {this.state.searchBy === 'List' && this.state.listSearch.length > 0 ?
                        <ul>
                            {this.state.listSearch && this.state.listSearch.map((item, i) => (
                                <li key={i} style={listBoxItem}><a href={item}>{item}</a></li>
                            ))}
                        </ul>
                        : null}
                    {this.state.searchBy !== '' && this.state.venueSearchList.length > 0 ?
                        <ul>
                            {this.state.venueSearchList && this.state.venueSearchList.map(item => (
                                <li key={item.vm_id} style={listBoxItem}><a
                                    href={`/Venues/${item.city}/${item.vm_id}/update`}>{item.name}
                                    ,{item.sub_area}</a></li>
                            ))}
                        </ul>
                        : null}
                </div>
            </div>
        );
    }
}

export default SearchSelect;
