import React, {Component} from 'react';
import _ from 'lodash';
import {
    FormGroup,
    FormControl,
    Col,
    Row,
    Button,
    Panel,
    ListGroup,
    ListGroupItem,
    ControlLabel
} from 'react-bootstrap';
import BasicLoader from '../../../components/Loader/BasicLoader';
import {connect} from 'react-redux';
import { getIsSavingPackage, getIsViewingPackage, getIsViewingPackageMenu, getSelectedMenus, getSelectedPackage } from '../../../redux/admin/EventReducer';

export class EditPackageSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedMenus: [],
            packmenus: [],
            package: this.props.selectedPackage,
        };
        this.basePackage = this.props.selectedPackage;
        this.baseMenus = this.props.selectedMenus;
    }

    componentDidMount() {
        if (!_.isEmpty(this.props.selectedPackage)) {
            let newMenus = this.props.selectedMenus.map(item => {
                item.isSelected = false;
                return item;
            });
            let selectedMenus = [];
            if (this.props.selectedPackage.menuItems && this.props.selectedPackage.menuItems.length > 0) {
                newMenus = newMenus.map(item2 => {
                    this.props.selectedPackage.menuItems.forEach((item1) => {
                        if (_.toString(item1.menuId) === _.toString(item2._id)) {
                            let menuItem = item2;
                            menuItem.count = _.isFinite(parseInt(item1.count, 10)) ? parseInt(item1.count, 10) : 0;
                            selectedMenus.push(menuItem);
                            item2.isSelected = true;
                        }
                    });
                    return item2;
                });
            }
            this.setState({
                selectedMenus: selectedMenus,
                packmenus: newMenus,
                package: this.props.selectedPackage,
                pack: this.props.selectedPackage,
            });
        }
    }

   
    componentWillReceiveProps(nextProps) {
        if(this.props.selectedPackage._id !== nextProps.selectedPackage._id) {
            let menus = nextProps.selectedMenus.map(item => {
                item.isSelected = false;
                return item;
            });
            let newMenus = [];
            let selectedMenus = [];
            if (nextProps.selectedPackage.menuItems && nextProps.selectedPackage.menuItems.length > 0) {
                newMenus = menus.map(item2 => {
                    nextProps.selectedPackage.menuItems.forEach((item1) => {
                        if (_.toString(item1.menuId) === _.toString(item2._id)) {
                            let menuItem = item2;
                            menuItem.count = _.isFinite(parseInt(item1.count, 10)) ? parseInt(item1.count, 10) : 0;
                            selectedMenus.push(menuItem);
                            item2.isSelected = true;
                        }
                    });
                    return item2;
                });
            }
            this.setState({
                selectedMenus: selectedMenus,
                packmenus: newMenus,
                package: nextProps.selectedPackage,
                pack: nextProps.selectedPackage,
            });
        }
    }

    handleChange = (keyName, value, isSelected) => {
        if (this.state[keyName]) {
            let tempStore = this.state[keyName].map(item => {
                if (_.toString(item.value) === _.toString(value)) {
                    item.isSelected = isSelected;
                } else {
                    item.isSelected = false;
                }
                return item;
            });
            let newState = this.state;
            newState[keyName] = tempStore;
            this.setState(newState);
        }
    };

    savePackage = () => {
        let pack = this.state.package;
        pack.name = this.state.pack.name;
        if (_.isFinite(parseFloat(this.state.pack.package_price))) {
            pack.package_price = this.state.pack.package_price;
        }
        pack.menuItems = this.state.selectedMenus.map(item => {
            return {
                name: item.name,
                menuId: item._id,
                count: item.count
            };
        });
        this.props.updatePackage(this.props.selectedPackage.venueId, this.props.selectedPackage._id, pack);
        this.closeForm();
    };

    incrementCount = (id) => {
        let oldMenus = this.state.selectedMenus;
        let newMenus = [];
        oldMenus.forEach((item, i) => {
            if (item._id === id && (item.count < item.items.length)) {
                item.count += 1;
            }
            newMenus.push(item);
        });
        this.setState({
            selectedMenus: newMenus
        });
    };

    handleInputChange = (e, id) => {
        if (_.isFinite(parseInt(e.target.value, 10))) {
            let oldMenus = this.state.selectedMenus;
            let newMenus = [];
            oldMenus.forEach((item, i) => {
                if (item._id === id) {
                    let count = parseInt(e.target.value, 10);
                    if (count > 0 && (count <= item.items.length)) {
                        item.count = parseInt(e.target.value, 10);
                    } else {
                        item.count = item.items.length;
                    }
                }
                newMenus.push(item);
            });
            this.setState({
                selectedMenus: newMenus
            });
        }
    };

    decrementCount = (id) => {
        let oldMenus = this.state.selectedMenus;
        let newMenus = [];
        oldMenus.forEach((item, i) => {
            if (item._id === id) {
                if (item.count > 0) {
                    item.count -= 1;
                }
            }
            newMenus.push(item);
        });
        this.setState({
            selectedMenus: newMenus
        });
    };

    handleDeselect = (id) => {
        let newMenus = this.state.selectedMenus.filter(item => item._id !== id);
        let oldMenus = this.state.packmenus.map((item, i) => {
            if (item._id === id) {
                item.isSelected = false;
            }
            return item;
        });
        this.setState({
            selectedMenus: newMenus,
            menus: oldMenus
        });
    };

    handleSelectionChange = (id) => {
        let newMenus = this.state.selectedMenus;
        let oldMenus = this.state.packmenus.map((item, i) => {
            if (item._id === id) {
                item.count = 0;
                newMenus.push(item);
                item.isSelected = true;
            }
            return item;
        });
        this.setState({
            selectedMenus: newMenus,
            menus: oldMenus
        });
    };

    resetForm = () => {
        let newMenus = this.props.selectedMenus.map(item => {
            item.isSelected = false;
            return item;
        });
        let selectedMenus = [];
        if (this.props.selectedPackage.menuItems && this.props.selectedPackage.menuItems.length > 0) {
            newMenus = newMenus.map(item2 => {
                this.props.selectedPackage.menuItems.forEach((item1) => {
                    if (_.toString(item1.menuId) === _.toString(item2._id)) {
                        let menuItem = item2;
                        menuItem.count = _.isFinite(parseInt(item1.count, 10)) ? parseInt(item1.count, 10) : 0;
                        selectedMenus.push(menuItem);
                        item2.isSelected = true;
                    }
                });
                return item2;
            });
        }
        this.setState({
            selectedMenus: selectedMenus,
            packmenus: newMenus,
            package: this.props.selectedPackage,
        });
    };

    closeForm = () => {
        this.resetForm();
        this.props.closeEditPackageForm();
    };

    handlePackageChange = (keyname, keyValue) => {
        let pack = this.state.pack;
        pack[keyname] = keyValue;
        this.setState({
            pack: pack
        })
    }

    render() {
        let isLoading = this.props.isLoading;
        let menus = this.state.packmenus;
        let selectedMenus = this.state.selectedMenus;
        let pack = this.state.package;
        console.log("package",pack)
        let menuSection, priceSection;
        if (this.state.package) {
            menuSection = <Panel header="Select Menus For Package">
                <Col xs={6}>
                    <div className="list-group">
                        {
                            menus.filter(item => !item.isSelected).map((menu) => {
                                return (
                                    <a className="list-group-item" key={menu._id}
                                       onClick={e => this.handleSelectionChange(menu._id)}>
                                        {menu.items.length > 0 ? <span
                                            className="badge badge-success"> {menu.items.length} </span> : ''}
                                        {menu.name}
                                    </a>
                                )
                            })
                        }
                    </div>
                </Col>
                <Col xs={6}>
                    {selectedMenus.length === 0 && <p>(nothing selected yet)</p>}
                    {selectedMenus.length > 0 && <div className="list-group">
                        {selectedMenus.map((ship, i) =>
                            <div key={ship._id} className="list-group-item">
                                <Row>
                                    <Col xs={5}>
                                        <i className="fa fa-chevron-right icon-muted"></i>
                                        {`${ship.name} (${ship.items.length}) `}
                                    </Col>
                                    <Col xs={5}>
                                        <a className="btn btn-xs btn-info"
                                           onClick={e => this.decrementCount(ship._id)}>
                                            <i className="fa fa-minus"/>
                                        </a>
                                        <input type="text"
                                               style={{
                                                   width: "40px",
                                                   display: "inline",
                                                   height: "25px",
                                                   marginLeft: "10px",
                                                   marginRight: "10px",
                                                   textAlign: "center"
                                               }}
                                               value={ship.count}
                                               onChange={e => this.handleInputChange(e, ship._id)}/>
                                        <a className="btn btn-xs btn-info"
                                           onClick={e => this.incrementCount(ship._id)}>
                                            <i className="fa fa-plus"></i>
                                        </a>
                                    </Col>
                                    <Col xs={2}>
                                        <a className="btn btn-xs btn-danger pull-right"
                                           onClick={e => this.handleDeselect(ship._id)}>
                                            <i className="fa fa-times"></i>
                                        </a>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>}

                </Col>
            </Panel>;
            priceSection = <Panel header="Basic Details">
                <ListGroup fill>
                    <ListGroupItem>
                        <Row>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <ControlLabel>Name</ControlLabel>
                                    <FormControl
                                        type="text"
                                        placeholder="Enter package name"
                                        onChange={(e)=> this.handlePackageChange("name", e.target.value)}
                                        value={this.state.pack?.name}
                                    />
                                </FormGroup>
                            </Col>
                            <Col xs={12} lg={6}>
                                <FormGroup>
                                    <ControlLabel>Price</ControlLabel>
                                    <FormControl
                                        type="text"
                                        placeholder="Enter package price"
                                        onChange={(e)=> this.handlePackageChange("package_price", e.target.value)}
                                        value={this.state.pack?.package_price}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Panel>;
        } else {
            menuSection = <BasicLoader/>;
            priceSection = '';
        }
        return (
            <div className={'panel'}>
                <div className={'panel-body'}>
                    <div className="step-pane">
                        {priceSection}
                    </div>
                    <div className="step-pane">
                        {menuSection}
                    </div>
                </div>
                <div className={'panel-footer'}>
                    <div className="text-center">
                        <Button style={{marginRight: "20px"}}
                                onClick={() => this.props.closeEditPackageForm()}>Close</Button>
                        <Button className="btn btn-md btn-primary"
                                onClick={() => this.savePackage()}
                                disabled={this.props.isSaving}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
     
        selectedMenus : getSelectedMenus(state),
        selectedPackage: getSelectedPackage(state),
        isSavingPackage: getIsSavingPackage(state),
        isViewingPackage : getIsViewingPackage(state)
    }
}

export default connect(mapStateToProps)(EditPackageSection);
