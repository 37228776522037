import React, {Component} from 'react';
import {
    FormControl, FormGroup, InputGroup,
} from 'react-bootstrap';
import async from 'async';
import UploadImage from './Components/UploadImage';

import callApi from '../../../../util/apiCaller';

const boxStyle = {
    backgroundColor: '#fff',
    height: '100px',
    textAlign: 'center',
    fontSize: '18px',
    border: 'solid 1px #ccc',
    paddingTop: '35px',
    marginBottom: '15px'
};

class ImageUploader extends Component {

    constructor() {
        super();
        this.state = {
            images: [],
            pageNum: 1,
            pageSize: 9,
            totalCount: 0,
            isSaving: false,
            uploadedCount: 0,
            totalUploadCount: 0,
            isUploading: false,
            intervalId: 0,
            uploadingImages: [],
            url: '',
            isbaseUrlNotEditable: false,
            isBaseUrlPresent: false,
        };
    };

    componentDidMount() {
        this.setState({
            baseUrl: this.props.baseUrl,
            isBaseUrlPresent : !!this.props.isBaseUrlPresent,
        });
    }

    scrollStep = () => {
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - 200);
    };

    scrollToTop = (next) => {
        let intervalId = setInterval(this.scrollStep.bind(this), 20);
        this.setState({intervalId: intervalId}, () => {
            next();
        });
    };

    uploadFile = (data, next) => {
        if (data.s3_url) {
            //TODO v1 to v3 change ritwik
            callApi(`v3/assets/new`, 'post', {
                url: data.s3_url,
                filetype: data.filetype
            }).then(res => {
                const xhr = new XMLHttpRequest();
                xhr.upload.addEventListener("progress", ((e) => {
                    this.displayUploadProgress(data.preurl, e);
                }), false);
                xhr.onreadystatechange = (e) => {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200) {
                            return next(true);
                        } else {
                            return next(null);
                        }
                    }
                };
                xhr.open("PUT", res.signed_request);
                xhr.setRequestHeader('x-amz-acl', 'public-read');
                xhr.send(data.file);
            });
        }
    };

    displayUploadProgress = (url, event) => {
        let percent;
        if (event) {
            percent = Math.floor((event.loaded / event.total) * 100);
            let uploadingImages = this.state.uploadingImages.map(item => {
                if (item.url === url) {
                    item.progress = percent;
                }
                return item;
            });
            this.setState({
                uploadingImages: uploadingImages
            });
        }
    };

    handleNewImageUpload = (files, next) => {
        let uploadingImages = files.map(item => {
            let object = {};
            object.url = item.preurl;
            object.name = item.name;
            object.hasErrors = item.meta.hasErrors;
            object.errors = item.meta.errors;
            object.progress = 0;
            object.imageurl = item.meta.imageurl;
            return object;
        });
        this.setState({
            isUploading: true,
            uploadedCount: 0,
            totalUploadCount: files.length,
            uploadingImages: uploadingImages
        });
        async.each(files, (file, callback) => {
            if (!!file.meta.hasErrors) {
                return callback();
            } else {
                if(this.state.baseUrl === '') {
                    this.showMessage("Please Provide Uploading Directory","error");
                    this.setState({
                        isUploading: false,
                    });
                    return callback();
                } else {
                    this.uploadFile(file, (resp) => {
                        if (resp) {
                            this.props.createImage(file, () => {
                                return callback();
                            });
                        } else {
                            return callback();
                        }
                        this.setState({
                            uploadedCount: this.state.uploadedCount + 1
                        });
                    });
                }
            }
        }, (err) => {
            if (err) {
                this.showMessage("Please try later", "danger");
            } else {
                this.setState({
                    isUploading: false,
                    uploadedCount: 0,
                    totalUploadCount: 0,
                    uploadingImages: []
                });
                this.showMessage("Image Uploaded", "success");
            }
        });
    };

    showMessage = (msg, msgcode) => {
        this.props.showMessage(msg, msgcode);
    };

    handlebaseUrlChange = (e) => {
        this.setState({
            baseUrl: e.target.value
        });
    };


    render() {
        let segment;
        if (this.state.isLoading) {
            segment = <div className="row">
                <div className="col-xs-12">
                    Loading...
                </div>
            </div>;
        } else {
            segment =
                <div>
                    <div>
                        {this.props.isBaseUrlVisible ?
                            <FormGroup>
                                <InputGroup>
                                    <InputGroup.Addon>{`https://venuemonk-images.s3.ap-south-1.amazonaws.com/`}</InputGroup.Addon>
                                    <FormControl
                                        readOnly={!!this.props.isbaseUrlNotEditable}
                                        type="text"
                                        value={this.state.baseUrl}
                                        placeholder="Enter base URL"
                                        onChange={(e) => this.handlebaseUrlChange(e)}
                                    />
                                </InputGroup>
                            </FormGroup>:
                            null
                        }
                    </div>
                    {this.state.baseUrl !=='' ? <UploadImage
                            baseUrl={this.state.baseUrl}
                            url={this.props.url}
                            uploadFiles={this.handleNewImageUpload}
                            uploadCount={this.state.uploadCount}
                            uploadedCount={this.state.uploadedCount}
                            totalUploadCount={this.state.totalUploadCount}
                            isUploading={this.state.isUploading}
                            showMessage={this.showMessage}
                            uploadingImages={this.state.uploadingImages}
                            isMultiFileUploadAllowed={this.props.isMultiFileUploadAllowed}
                            postFixLength={this.props.postFixLength ? this.props.postFixLength : 5}
                            isImageConvertible={!!this.props.isImageConvertible}
                        />
                        :
                        <div style={boxStyle}>
                            <span>The Uploading Directory Is Missing!</span>
                        </div>
                    }
                </div>;
        }
        return (
            <div id="updator-container" style={{marginTop: "60px"}}>
                {segment}
            </div>
        );
    }
}

export default ImageUploader;
