import React, { Component } from 'react';

import NotificationItem from './NotificationItem';

const DEFAULT_TIMER_COUNTDOWN = 3;
const DEFAULT_TIME_STEP = 1000;

const hideStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 50000,
    padding: '25px',
    display: 'none'
};

const noHideStyle = {
    position: 'fixed',
    top: 0,
    right: 0,
    zIndex: 50000,
    padding: '25px'
};

class NotificationWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countDown: 0,
            message: '',
            link: '',
            msgId: ''
        };
    };

    componentDidMount () {
        this.setState({
            countDown: 0,
            message: '',
            msgId: '',
        });
    }

    componentDidUpdate(prevProps) {
        if (this.state.msgId !== this.props.notification.msgId) {
            this.setState({
                message: this.props.notification.message,
                link: this.props.notification.link,
                msgId: this.props.notification.msgId,
            }, () => {
                this.startTimer(this.props.notification.timeout);
            });
        }
    }

    startTimer = (timeout) => {
        this.setState({
            countDown: timeout ? timeout : DEFAULT_TIMER_COUNTDOWN,
        }, () => {
            this.timer = setInterval(this.countDownTime, DEFAULT_TIME_STEP);
        });
    };

    clearTimer = () => {
        this.setState({
            countDown: 0,
        }, () => {
            clearInterval(this.timer);
        });
    };

    countDownTime = () => {
        if (this.state.countDown > 0) {
            this.setState({
                countDown: this.state.countDown - 1,
            });
        } else {
            this.clearTimer();
        }
    };

    handleVisibility = () => {
        if (this.state.countDown > 0) {
            return noHideStyle;
        } else {
            return hideStyle;
        }
    };

    render() {
        return (
            <div style={this.handleVisibility()}>
                <NotificationItem
                    link={this.state.link}
                    message={this.state.message}
                />
            </div>
        );
    }
}

export default NotificationWrapper;

