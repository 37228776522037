import { views } from "./venues/ViewList.json";

let Qclist = views.map((view) => {
  let link = {};
  link.isNormalLink = true;
  link.url = `/manager/venue/content-quality-list/${view.value}`;
  link.name = view.name;
  link.icon = "";
  link.shouldHavePermissions = ["venues:read"];

  return link;
});

export default [
  {
    isNormalLink: false,
    name: "Business Development",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["bd-role:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/business-development/leads",
        name: "Partner Leads",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/partner-app/users/list",
        name: "Partner App Users",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/mypartner-approval/list",
        name: "My Partner Approval",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/business-development/lead-analytics",
        name: "Lead Analytics",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/business-development/venue-partners",
        name: "Venue Partners List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/business-development/package-cost",
        name: "Package Cost List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/algo-test",
        name: "Algo Graph",
        icon: "",
        shouldHavePermissions: ["algograph:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Catalog Mgmt",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["bd-role:read"],
    list: [
      ...Qclist,
      {
        isNormalLink: true,
        url: "/manager/venue/onboarding-list",
        name: "Venue Onboarding List",
        icon: "",
        shouldHavePermissions: ["venueonboardings:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/list",
        name: "Venue Admin List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/content",
        name: "Venue Content List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/expert-list",
        name: "Venue Expert List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/searchlinks/list",
        name: "List of Searchlinks",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/realevents/create",
        name: "Create Real Event ",
        icon: "",
        shouldHavePermissions: ["realevents:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/realevents/list",
        name: "List of Real Events",
        icon: "",
        shouldHavePermissions: ["realevents:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/collections/list",
        name: "Collections List",
        icon: "",
        shouldHavePermissions: ["collections:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Vendors",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: [],
    list: [
      {
        isNormalLink: true,
        url: "/manager/vendor/photographers-list",
        name: "Photographers",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/mehendi-artists-list",
        name: "Mehendi Artists",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/makeup-artists-list",
        name: "Makeup Artists",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/decorator-list",
        name: "Decorators",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/event-planner-list",
        name: "Event Planner",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/wedding-stylist-list",
        name: "Weddding Stylist",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/caterer-list",
        name: "Caterer",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/choreographer-list",
        name: "Choreographer",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/dj-list",
        name: "Dj",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/anchor-list",
        name: "Anchor",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/wedding-entertainer-list",
        name: "Wedding Entertainer",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/pandit-list",
        name: "Pandit",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/car-list",
        name: "Car",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/bartender-list",
        name: "Bartender",
        icon: "",
        shouldHavePermissions: [],
      },
      {
        isNormalLink: true,
        url: "/manager/vendor/event-equipment-list",
        name: "Event Equipment",
        icon: "",
        shouldHavePermissions: [],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Corporate Accounts",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["bd-role:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/corporate/signups",
        name: "Corporate Signups",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/corporate/account/list",
        name: "Corporate Account List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/corporate/event/list",
        name: "Corporate Events",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/corporate/event/won-list",
        name: "Closed-Won Corporate Events",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/corporate/coupons",
        name: "Corporate App Coupons",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/corporate/redemption/list",
        name: "Corporate Redemptions List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "PurplePatch",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["bd-role:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/purplepatch/lead/list",
        name: "PurplePatch Lead List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Approvals",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["admin:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/approvals/approval-agent/list",
        name: "Approval Agent List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/approvals/approval/list",
        name: "Approval List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/approvals/partner-approval/list",
        name: "Partner Approval List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Admin",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["admin:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/users/list",
        name: "User List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/agents/list",
        name: "Agents List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/agentonline/list",
        name: "Agent Online List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/agents/call-data",
        name: "Agents Call Data",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/role/list",
        name: "Role List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/module/list",
        name: "Module List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/permission/list",
        name: "Permission List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/rolegroup/list",
        name: "Role Group List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/partner-app/module/list",
        name: "Partner App Module List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/partner-app/venueroles-list",
        name: "VenueRole List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/agenttaskview-list",
        name: "AgentTask View List",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/invalidate-cache",
        name: "Invalidate Cache",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/admin/deployment",
        name: "Deploy",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      
     
    ],
  },
  {
    isNormalLink: false,
    name: "Onboarding",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["onboarding:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/venue/onboarding-list",
        name: "Venue Onboarding List",
        icon: "",
        shouldHavePermissions: ["venueonboardings:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/tracker-list",
        name: "Venue Tracker List",
        icon: "",
        shouldHavePermissions: ["venuetrackers:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/photographer/onboarding-list",
        name: "Photo Onboarding List",
        icon: "",
        shouldHavePermissions: ["photoonboardings:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/photographer/tracker-list",
        name: "Photo Tracker List",
        icon: "",
        shouldHavePermissions: ["phototrackers:read"],
      },
    ],
  },
  // {
  //     isNormalLink: false,
  //     name: 'Photographer',
  //     icon: 'ti ti-view-list-alt',
  //     shouldHavePermissions: [],
  //     list: [
  //         {
  //             isNormalLink: true,
  //             url: '/manager/photographer/list',
  //             name: 'Photographer List',
  //             icon: '',
  //             shouldHavePermissions: []
  //         },
  //     ]
  // },

  {
    isNormalLink: false,
    name: "Venues",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["venues:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/venue/list",
        name: "Venue Admin List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/content",
        name: "Venue Content List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/expert-list",
        name: "Venue Expert List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/drive-list",
        name: "Venue Drive List",
        icon: "",
        shouldHavePermissions: ["venues:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/report",
        name: "Reports",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue/venue-leads",
        name: "Venue Leads List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/venues/data-transfer",
        name: "Venue Data Transfer",
        icon: "",
        shouldHavePermissions: ["venues:update"],
      },
      {
        isNormalLink: true,
        url: "/venues/bulk-data-updator",
        name: "Venue Bulk Data Updator",
        icon: "",
        shouldHavePermissions: ["venues:update"],
      },
      {
        isNormalLink: true,
        url: "/venues/faq-list",
        name: "Venue FAQ List",
        icon: "",
        shouldHavePermissions: ["venues:update"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Expressbook",
    icon: "ti ti-view-list-alt",
    shouldHavePermissions: ["expressbook:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/expressbook/package-buyers/list",
        name: "Package Buyers",
        icon: "",
        shouldHavePermissions: ["expressbook:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/expressbook/birthday/list",
        name: "Bday Party Package List",
        icon: "",
        shouldHavePermissions: ["expressbook:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/expressbook/party/list",
        name: "Party Package List",
        icon: "",
        shouldHavePermissions: ["expressbook:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/expressbook/expurl/list",
        name: "Expressbook Urls",
        icon: "",
        shouldHavePermissions: ["expressbook:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Marketing",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["marketing:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/marketing/semcampaigns/list",
        name: "Sem Campaign List",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/metacampaigns/list",
        name: "Meta Campaign List",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/events-dumper/list",
        name: "Events Dumper",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/partner-lead-dumper/list",
        name: "Partner Lead Dumper",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/smstoolkit/events",
        name: "Events Sms List",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/smstoolkit/users",
        name: "Users Sms List",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/smstoolkit/venues",
        name: "Venues Sms List",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/adlinks/list",
        name: "Ad Linked Shortlisting",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/searchlinks/list",
        name: "List of Searchlinks",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/venues/list",
        name: "List of Venues",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/marketing/emailtask/list",
        name: "List of Marketing Email Task",
        icon: "",
        shouldHavePermissions: ["marketing:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Services",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["services:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/services/alertsystem/list",
        name: "Alerts List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/corporate/offers",
        name: "Offers",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/city/list",
        name: "City List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/content/list",
        name: "Content List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/event-templates/list",
        name: "Event Template List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/venue-templates/list",
        name: "Venue Summary Template ",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/venue-faq-template/list",
        name: "Venue Faq Template List ",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/searchlink-templates/list",
        name: "Searchlink Summary Template",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/email/templates",
        name: "Email Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/email/tasks",
        name: "Email Tasks List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/smstoolkit/events",
        name: "Events Sms List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/keywords/list",
        name: "Keywords List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/leadform/list",
        name: "Lead Forms List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/paid-partner/list",
        name: "Paid Partner List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/redirect/list",
        name: "Redirect List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/scripts/file-upload",
        name: "Script Uploads",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/short-url/list",
        name: "Short Url List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/sms/tasks",
        name: "Sms Tasks List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/sms/templates",
        name: "Sms Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/tasktypes/list",
        name: "Task Codes List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/whatsapp/templates",
        name: "WhatsApp Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/whatsapp/tasks",
        name: "WhatsApp Tasks List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/summary/templates",
        name: "Summary Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/pdf/templates",
        name: "Pdf Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/notification/templates",
        name: "Notification Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "PartnerApp Services",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["services:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/partner-app/services/email/templates",
        name: "Email Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/partner-app/services/sms/templates",
        name: "Sms Templates List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Base",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["services:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/base/pagedata/list",
        name: "Page Data List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Finance",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: [],
    list: [
      {
        isNormalLink: true,
        url: "/manager/finance/venue-pending-payments",
        name: "Venue Pending Payments",
        icon: "",
        shouldHavePermissions: [],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Subscriptions",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["services:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/services/subscriptions/create",
        name: "Subscription Create",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/subscriptions/list",
        name: "List of Subscriptions",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/plans/list",
        name: "List of Plans",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/payments/list",
        name: "List of Payments",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/services/cheques/list",
        name: "List of Cheques",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Content",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["content:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/content/occasions/list",
        name: "List of occasions",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/cuisines/list",
        name: "List of cuisines",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/extrapermissions/list",
        name: "List of Extra Permissions",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/promocode/list",
        name: "List of Promocodes",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/facilities/list",
        name: "List of facilities",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/venuetypes/list",
        name: "List of Venuetypes",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/subareas/list",
        name: "List of subareas",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/associatedsubareas/list",
        name: "List of Associated Subareas",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/regions/list",
        name: "List of regions",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/package-suggestions/list",
        name: "List of Package Suggestions",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/newsletters/list",
        name: "List of newsletters",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/jobs/list",
        name: "List of jobs",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/imagetags/list",
        name: "List of imagetags",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/footerlinks/list",
        name: "List of footerlinks",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/footerblogs/list",
        name: "List of footerblogs",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/categories/list",
        name: "List of categories",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/listbizs/list",
        name: "List of listbiz",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/colorlist/list",
        name: "List of Colors",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/dropdowns/list",
        name: "List of Dropdowns",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/pagedatas/list",
        name: "List of Pagedatas",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/eventquality/list",
        name: "List of Event Qualities",
        icon: "",
        shouldHavePermissions: ["eventquality:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/content/eventpriority/list",
        name: "List of Event Priorities",
        icon: "",
        shouldHavePermissions: ["content:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Blog",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["admin:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/blog/blog-category/list",
        name: "List of Blog Categories",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/blog/featured-blog/list",
        name: "List of Featured Blogs",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/blog/pinned-post/list",
        name: "List of Pinned Posts",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Collections",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["collections:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/collections/list",
        name: "Collections List",
        icon: "",
        shouldHavePermissions: ["collections:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Leads",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["leads:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/enquiry/closed-won",
        name: "Closed Won Enquiries ",
        icon: "",
        shouldHavePermissions: ["leads:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/enquiry/closed-lost",
        name: "Closed Lost Enquiries ",
        icon: "",
        shouldHavePermissions: ["leads:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/enquiry/feedback",
        name: "Feedback",
        icon: "",
        shouldHavePermissions: ["leads:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venuetarget/venuetargetlist",
        name: "Venue Leads Target List",
        icon: "",
        shouldHavePermissions: ["leads:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Venue Filters",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["services:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/venue-filters/filter/list",
        name: "Filter List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/venue-filters/filtermodulelist",
        name: "Filter Module List",
        icon: "",
        shouldHavePermissions: ["services:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Real Events",
    icon: "ti ti-stats-up",
    shouldHavePermissions: ["realevents:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/realevents/create",
        name: "Create Real Event ",
        icon: "",
        shouldHavePermissions: ["realevents:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/realevents/list",
        name: "List of Real Events",
        icon: "",
        shouldHavePermissions: ["realevents:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Analytics",
    icon: "ti ti-stats-up",
    shouldHavePermissions: ["analytic:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/analytics/date",
        name: "Date Wise ",
        icon: "",
        shouldHavePermissions: ["analytic:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Scheduler",
    icon: "ti ti-control-shuffle",
    shouldHavePermissions: ["scheduler:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/scheduler/onetimeevent/list",
        name: "Onetime Event",
        icon: "",
        shouldHavePermissions: ["scheduler:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/scheduler/recurringevent/list",
        name: "Recurring Event",
        icon: "",
        shouldHavePermissions: ["scheduler:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/scheduler/worker-jobs/list",
        name: "Worker Jobs List",
        icon: "",
        shouldHavePermissions: ["scheduler:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Reports",
    icon: "ti ti-stats-up",
    shouldHavePermissions: ["reports:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/reports/realtime",
        name: "Realtime Reports",
        icon: "",
        shouldHavePermissions: ["reports:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/reports/monthly",
        name: "Monthly Reports",
        icon: "",
        shouldHavePermissions: ["reports:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/reports/automated",
        name: "Automated Reports",
        icon: "",
        shouldHavePermissions: ["reports:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Sales CRM",
    icon: "ti ti-stats-up",
    shouldHavePermissions: ["mytasks:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/salescrm/bundleset/list",
        name: "Bundle Set List",
        icon: "",
        shouldHavePermissions: ["mytasks:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/crm/funnelsystem/list",
        name: "Funnel System List",
        icon: "",
        shouldHavePermissions: ["mytasks:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Caching",
    icon: "ti ti-stats-up",
    shouldHavePermissions: ["admin:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/weblink/list",
        name: "Web Links",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/apilink/list",
        name: "API Links",
        icon: "",
        shouldHavePermissions: ["admin:read"],
      },
    ],
  },
  {
    isNormalLink: false,
    name: "Feedback",
    icon: "ti ti-stats-up",
    shouldHavePermissions: ["venues:read"],
    list: [
      {
        isNormalLink: true,
        url: "/manager/notfoundvenue/list",
        name: "Not Found Venue List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
      {
        isNormalLink: true,
        url: "/manager/futureevent/list",
        name: "Future Event List",
        icon: "",
        shouldHavePermissions: ["bd-role:read"],
      },
    ],
  },
];
