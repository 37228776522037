import {
    FETCH_AGENT_LIST,
    FETCH_FUNNEL_DATA,
    FETCH_TASK_CONTENT,
    FETCH_TASK_STATS,
    FETCH_CURRENT_TASK,
    FETCH_BASIC_PARAMETERS,
    FETCH_LOCATION_PARAMETERS,
    NEW_EVENT_CREATED,
    UPDATE_EVENT,
    UPDATE_CUSTOMER_EVENT_INFO,
    UPDATE_TASKS_INTO_LIST,
    UPDATING_TASKS_INTO_LIST,
    FETCH_EVENT_INFO
} from "./SalesAgentQueueActions";

const initialState = {
    agent_list: [],
    isFetching: false,
    tasks: [],
    taskCount: 0,
    funnel_data: {},
    salestask: [],
    closedLostReasons: [],
    callingStatusList: [],
    agentStats: [],
    agentCallData: [],
    scores: [],
    current_task: {},
    items: [],
    counts: "",
    baseParams: [],
    subAreas: [],
    newEvent: false,
    event: [],
    currentTaskEvent: []
};

const SalesReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_AGENT_LIST:
            return {
                ...state,
                agent_list: action.agent_list,
            };

        case FETCH_FUNNEL_DATA:
            return {
                ...state,
                funnel_data: action.funnel_data,
            };

        case FETCH_TASK_CONTENT:
            return {
                ...state,
                salestask: action.taskContent.salestask,
                closedLostReasons: action.taskContent.closedLostReasons,
                callingStatusList: action.taskContent.callingStatusList,
            };

        case FETCH_TASK_STATS:
            return {
                ...state,
                scores: action.taskStats.scores,
                agentCallData: action.taskStats.agentCallData,
                agentStats: action.taskStats.agentStats,
            };

        case FETCH_CURRENT_TASK:
            return {
                ...state,
                count: action.current_task.total,
                items: action.current_task.tasks,
            };

        case FETCH_BASIC_PARAMETERS:
            return {
                ...state,
                baseParams: action.baseParams,
            };

        case FETCH_LOCATION_PARAMETERS:
            return {
                ...state,
                subAreas: action.subAreas,
            };

        case NEW_EVENT_CREATED:
            return {
                ...state,
                newEvent: action.newEvent,
            };

        case UPDATE_EVENT:
            return {
                ...state,
                items: action.items,
            };

        case UPDATE_CUSTOMER_EVENT_INFO:
            return {
                ...state,
                event: action.event,
            };

        case UPDATE_TASKS_INTO_LIST:
            return {
                ...state,
                tasks: action.tasks,
                taskCount: action.taskCount,
                isFetching: action.isFetching,
            };

        case UPDATING_TASKS_INTO_LIST:
            return {
                ...state,
                isFetching: action.isFetching,
            };
            case FETCH_EVENT_INFO:
                return {
                    ...state,
                    currentTaskEvent: action.currentTaskEvent,
                };

            
        default:
            return state;
    }
};

/* Selectors */

export const getAgentTaskLoadingState = (state) => state.sales.isFetching;
export const getAgentTasks = (state) => state.sales.tasks;
export const getAgentTaskCount = (state) => state.sales.taskCount;
export const getAgentList = (state) => state.sales.agent_list;
export const getFunnelData = (state) => state.sales.funnel_data;
export const getSalestask = (state) => state.sales.salestask;
export const getClosedLostReasons = (state) => state.sales.closedLostReasons;
export const getCallingStatusList = (state) => state.sales.callingStatusList;
export const getSalesAgentScore = (state) => state.sales.scores;
export const getSalesCallData = (state) => state.sales.agentCallData;
export const getSalesAgentStats = (state) => state.sales.agentStats;
export const getCurrentTaskCount = (state) => state.sales.count;
export const getCurrentTaskTasks = (state) => state.sales.items ? state.sales.items : [];
export const getBaseParams = (state) => state.sales.baseParams;
export const getSubAreas = (state) => state.sales.subAreas;
export const getNewEventStatus = (state) => state.sales.newEvent;
export const getUpdateEventInfo = (state) => state.sales.event;
export const getCurrentEventInfo = (state) => state.sales.currentTaskEvent;

export default SalesReducer;
