import React from 'react';
import moment from 'moment';
import {Button} from 'react-bootstrap';

function EventTasks (props) {
   if(props.tasks && props.tasks.length > 0) {
       return (
           <div className="row">
               <div className="col-xs-12">
                   <div className="panel">
                       <div className="panel-heading">
                           Tasks
                       </div>
                       <div className="list-group" style={{maxHeight: "300px", overflowY: "scroll"}}>
                           {props.enableTaskAddition ?
                               <div className="list-group-item">
                                   <Button
                                       block
                                       onClick={() => props.newtask()}
                                   >
                                       Add New Task
                                   </Button>
                               </div> :
                               null
                           }
                           {props.tasks.map((item, i) => (
                               <div className="list-group-item" key={item._id}>
                                   <h6 className="media-heading">
                                       <i className={item.completed ? "fa fa-check" : "fa fa-times"}></i>  {item.heading} - {item.completed ? "Close" : "Open"}
                                   </h6>
                                   <p>Note - {item.note}</p>
                                   <p>Due - <i>{moment(item.due_date).format("Do MMM YYYY")}</i></p>
                               </div>
                           ))}
                       </div>
                   </div>
               </div>
           </div>
       );
   } else {
       return (
           <div className="row">
               <div className="col-xs-12">
                   <div className="text-center">
                       No tasks found.
                   </div>
               </div>
           </div>
       );
   }
}

export default EventTasks;
