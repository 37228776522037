import React, { Component } from 'react';
import _ from 'lodash';
import {Button} from "react-bootstrap";
import {connect} from 'react-redux';
import { getSelectedMenus, getSelectedPackage } from '../../../redux/admin/EventReducer';
class VenueMenu extends Component {

    constructor() {
        super();
        this.state = {
            showMenus: []
        };
    };

    componentDidMount() {
        if (!_.isEmpty(this.props.selectedPackage)) {
            let menus = this.props.selectedMenus;
            let selectedMenus = this.props.selectedPackage?.menuItems;
            console.log("selectedMenus",selectedMenus )
            let showMenus = [];
            selectedMenus && selectedMenus.forEach(item => {
                let selectedMenu = {};
                menus.forEach(item2 => {
                    if (item2._id === item.menuId) {
                        selectedMenu = item2;
                    }
                });
                if (!_.isEmpty(selectedMenu)) {
                    showMenus.push(selectedMenu);
                }
            });
            this.setState({
                showMenus: showMenus
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (!_.isEmpty(nextProps.selectedPackage)) {
            let menus = nextProps.selectedMenus;
            let selectedMenus = nextProps.selectedPackage.menuItems;
            let showMenus = [];
            selectedMenus.forEach(item => {
                let selectedMenu = {};
                menus.forEach(item2 => {
                    if (item2._id === item.menuId) {
                        selectedMenu = item2;
                        selectedMenu.count = item.count ? item.count : 0;
                    }
                });
                if (!_.isEmpty(selectedMenu)) {
                    showMenus.push(selectedMenu);
                }
            });
            this.setState({
                showMenus: showMenus
            });
        }
    }

    render() {
        return (
            <div className={'panel'}>
                <div className="panel-body">
                    {(
                        this.state.showMenus.map((item) => (
                            <div key={item._id}>
                                <div className="cuisines_container venue_info_item">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h3 style={{fontSize: '18px', color: '#000'}}>
                                                {item.name} {item.count > 0 ? <i style={{fontSize: '13px'}}>{`(Choose any ${item.count})`}</i> : ''}
                                            </h3>
                                        </div>
                                        <ul className="col-md-12">
                                            {item.items.map((item2, i) => (
                                                <li key={item2._id}>
                                                    {item2.name}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        ))
                    )}
                </div>
                <div className="panel-footer">
                    <Button
                        onClick={() => this.props.closeVenueMenu()}
                    >
                        Close
                    </Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state, props) {
    return {
     
        selectedMenus : getSelectedMenus(state),
        selectedPackage: getSelectedPackage(state),
        
    }
}
export default connect(mapStateToProps)(VenueMenu);
