import React, {Component} from 'react';
import {
    Button
} from 'react-bootstrap';

class InformVenue extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: []
        };
    }

    componentDidMount() {
        let venues = [];
        venues = this.props.venues.map(item => {
            item.selected = true;
            return item;
        });
        this.setState({
            venues: venues
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.venues) {
            let venues = [];
            venues = nextProps.venues.map(item => {
                item.selected = true;
                return item;
            });
            this.setState({
                venues: venues
            });
        }
    }

    handleSelectionChange = (id) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.selected = !item.selected;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    submit = () => {
        let venueList = this.state.venues.filter( item => !item.venueUpgrade && item.selected);
        let newVenueList = venueList.map(item => {
            return item.uid._id;
        });
        console.log("newVenueList", newVenueList);
        this.props.send(this.props.eventId, newVenueList);
    };

    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Send Venue User Information
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Select Venue</th>
                        <th>Venue Name</th>
                        <th>Contact Exists</th>
                        <th>Added to Dashboard</th>
                        <th>Sent Mail</th>
                        <th>Sent Sms</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.venues.length < 1 ? <tr><td>No Venues</td></tr> : <tr></tr>}
                    {this.state.venues.map((item) => (
                        <tr className="mngevent-email-details" key={item._id}>
                            <td>
                                {item.uid.venuecontact ?
                                    item.venueUpgrade ? "" :
                                        <Button
                                            onClick={() => this.handleSelectionChange(item._id)}
                                            className={"btn-default"}
                                        >
                                            {item.selected ? <i className="fa fa-check"/> : <i className="fa fa-times"/>}
                                        </Button>
                                    :
                                    <h5>Add Contacts</h5>
                                }
                            </td>
                            <td>
                                {item.uid.name}, {item.uid.sub_area}, {item.uid.city}
                            </td>
                            <td>
                                {"No"
                                }
                            </td>
                            <td>
                                {item.venueUpgrade ?
                                    <span>
                                    Yes <i className="fa fa-check-circle-o"></i>
                                </span>
                                    :
                                    <span>
                                        No < i className="fa fa-times"></i>
                                    </span>
                                }
                            </td>
                            <td>
                                {item.emailToVenueWithContact ?
                                    <span>
                                    Yes <i className="fa fa-check-circle-o"></i>
                                </span>
                                    :
                                    <span>
                                        No < i className="fa fa-times"></i>
                                    </span>
                                }
                            </td>
                            <td>
                                {item.smsToVenueWithContact ?
                                    <span>
                                    Yes <i className="fa fa-check-circle-o"></i>
                                </span>
                                    :
                                    <span>
                                        No < i className="fa fa-times"></i>
                                    </span>
                                }
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
                <div className="panel-footer">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2">
                            <Button
                                disabled={this.props.isSending}
                                onClick={() => this.submit()}
                                className="btn-primary btn vm-purple-btn"
                            >{this.props.isSending ? "Sending Email" : "Send Email"}</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default InformVenue;
