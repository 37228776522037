import React, {Component} from 'react';
import _ from 'lodash';
import {
    FormControl,
    Col,
    Row,
    Button,
    Panel,
    ListGroup,
    ListGroupItem,
    InputGroup
} from 'react-bootstrap';

import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker'

class EditVisitSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visit: this.props.visit,
        };
    }

    componentDidMount() {
        this.setState({
            visit: this.props.visit
        });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.visit, this.props.visit)) {
            this.setState({
                visit: this.props.visit
            });
        }
    }

    savevisit = () => {
        let visit = this.state.visit;
        this.props.updateVisit(this.props.venueId, this.props.id, visit);
        this.props.closeEditVisitForm();
    };

    handleVenueVisit = (e, p) => {
        let visit = this.state.visit;
        visit.visit_date = new Date(p.startDate.toISOString());
        this.setState({
            visit: visit
        });
    };

    render() {
        let isLoading = this.props.isLoading;
        let priceSection;
        if (!isLoading) {
            priceSection = <Panel header="Basic Details">
                <ListGroup fill>
                    <ListGroupItem>
                        <Row>
                            <Col xs={12} lg={6}>
                                <DateRangePicker
                                    startDate={moment(this.state.visit.visit_date)}
                                    onEvent={(e, p) => this.handleVenueVisit(e, p)}
                                    autoApply={true}
                                    singleDatePicker={true}
                                    timePicker={true}
                                >
                                    <InputGroup>
                                        <FormControl type="text" value={moment(this.state.visit.visit_date).format('Do MMM YYYY : HH:mm')}/>
                                        <InputGroup.Addon>
                                            <i className="fa fa-calendar" aria-hidden="true"/>
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </DateRangePicker>
                            </Col>
                        </Row>
                    </ListGroupItem>
                </ListGroup>
            </Panel>;
        } else {
            priceSection = '';
        }
        return (
            <div className={'panel'}>
                <div className={'panel-body'}>
                    <div className="step-pane">
                        {priceSection}
                    </div>
                </div>
                <div className={'panel-footer'}>
                    <div className="text-center">
                        <Button style={{marginRight: "20px"}}
                                onClick={() => this.props.closeEditVisitForm()}>Close</Button>
                        <Button className="btn btn-md btn-primary"
                                onClick={() => this.savevisit()}
                                disabled={this.props.isSaving}>
                            Update
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditVisitSection;
