export const vendorList = ['photographer', 'mehendi', 'decorator']
export const vendorUrlPathLastValue = {
    photographer: "p",
}


export const PHOTOGRAPHER_MODEL = "photographer"
export const MEHENDI_ARTIST_MODEL = "mehandi_artist"
export const MAKE_UP_ARTIST_MODEL = "makeup_artist"
export const DECORATOR_MODEL = "decorator"
export const EVENT_PLANNER = "event_planner"
export const WEDDING_STYLIST = "wedding_stylist"
export const CATERERS = "caterer"
export const CHOREOGRAPHER = "choreographer"
export const DJ = "dj"
export const ANCHOR = "anchor"
export const WEDDING_ENTERTAINER = "wedding_entertainer"
export const PANDIT = "pandit"
export const CAR = "car"
export const BARTENDER = "bartender"
export const EVENT_EQUIPMENT = "event_equipment"
