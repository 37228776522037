import callApi, {callApiAndCache} from "../../util/apiCaller";
import callEventApi from "../../util/eventApiCaller";
import _ from "lodash";
import isEmpty from 'lodash/isEmpty';
import async from 'async';
import showToastMessage from "../../util/notificationUtil";
import {callEventApiAndCache} from "../../util/eventApiCaller";
import callVenueApi from "../../util/venueApiCaller";
import {} from "./SalesAgentQueueActions";

const SEARCH_PACKAGE_API = `${process.env.REACT_APP_API_URL}/v3/package-suggestions/search?term=`;
const SEARCH_AGENT_API = `${process.env.REACT_APP_ID_API_URL}/v3/agents/search?term=`;

export const FETCH_RECOMMENDED_VENUES = "FETCH_RECOMMENDED_VENUES";
export const SET_EVENT_LOADING_STEP = "SET_EVENT_LOADING_STEP";
export const TOGGLE_EVENT_MODAL_FORM = "TOGGLE_EVENT_MODAL_FORM";
export const SELECTED_VENUE = "SELECTED_VENUE";

export const SET_EVENT_ID = "SET_EVENT_ID";
export const FETCH_EVENT_INFO = "FETCH_EVENT_INFO";
export const FETCH_PREVIOUS_EVENTS = "FETCH_PREVIOUS_EVENTS";
export const FETCH_EVENT_BUNDLE = "FETCH_EVENT_BUNDLE";
export const UPDATE_FIRST_SEEN_EVENT_STATS = "UPDATE_FIRST_SEEN_EVENT_STATS";
export const FETCH_CORPORATE_EVENT_DETAILS = "FETCH_CORPORATE_EVENT_DETAILS";
export const FETCH_BASIC_PARAMETERS = "FETCH_BASIC_PARAMETERS";
export const FETCH_LOCATION_PARAMETERS = "FETCH_LOCATION_PARAMETERS";
export const FETCH_EVENT_TEMPLATES = "FETCH_EVENT_TEMPLATES";
export const FETCH_EVENT_STATS = "FETCH_EVENT_STATS";
export const FETCH_FEEDBACK = "FETCH_FEEDBACK";
export const FETCHING_LOG_INFO = "FETCHING_LOG_INFO";
export const FETCH_ASSETS = "FETCH_ASSETS";
export const FETCH_VENUE_BUNDLE_LIST = "FETCH_VENUE_BUNDLE_LIST";
export const RELOAD_BUNDLE = "RELOAD_BUNDLE";
export const RELOADING_BUNDLE = "RELOADING_BUNDLE"
export const FETCH_VENUE_SEGMENT_BY_ID = "FETCH_VENUE_SEGMENT_BY_ID";
export const FETCH_LOGS = "FETCH_LOGS";
export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_EMAIL_TASKS = "FETCH_EMAIL_TASKS";
export const FETCH_SMS_TASKS = "FETCH_SMS_TASKS";
export const FETCHING_LOGS = "FETCHING_LOGS";
export const CONVERT_LEAD = "CONVERT_LEAD";
export const CONVERTING_LEAD = "CONVERTING_LEAD";
// export const UPDATE_EVENT = "UPDATE_EVENT";
export const FETCH_VENUE_LIST = "FETCH_VENUE_LIST";
export const UPDATE_EVENT_INFO = "UPDATE_EVENT_INFO";
export const UPDATING_EVENT_INFO = "UPDATING_EVENT_INFO";
export const FINDING_AGENT = "FINDING_AGENT";
export const FIND_AGENT = "FIND_AGENT";
export const EDIT_PACKAGE = "EDIT_PACKAGE";
export const SAVING_PACKAGES = "SAVING_PACKAGES";
export const PLAN_VISIT = "PLAN_VISIT";
export const UPDATE_VISIT = "UPDATE_VISIT";
export const EDIT_VISIT_FORM = "EDIT_VISIT_FORM";
export const SCHEDULE_VISIT = "SCHEDULE_VISIT";
export const SEND_USER_EMAIL = "SEND_USER_EMAIL";
export const NOTIFY_VENUES = "NOTIFY_VENUES";
export const AGENT_OPTIONS = "AGENT_OPTIONS";
export const QUERY_LIST = "QUERY_LIST";
export const SMS_TO_VENUE = "SMS_TO_VENUE";
export const SMS_TO_USER = "SMS_TO_USER";
export const QUERY_BUNDLE_SET = "QUERY_BUNDLE_SET";


// notes
export const ADDING_CUSTOMER_EVENT_NOTE = 'ADDING_CUSTOMER_EVENT_NOTE';
export const ADD_CUSTOMER_EVENT_NOTE_LIST = 'ADD_CUSTOMER_EVENT_NOTE_LIST';
export const LOAD_CUSTOMER_EVENT_NOTE_LIST = 'LOAD_CUSTOMER_EVENT_NOTE_LIST';
export const REMOVING_CUSTOMER_EVENT_NOTE_LIST = 'REMOVING_CUSTOMER_EVENT_NOTE_LIST';
export const REMOVE_CUSTOMER_EVENT_NOTE_LIST = 'REMOVE_CUSTOMER_EVENT_NOTE_LIST';
export const UPDATE_CUSTOMER_EVENT_NOTE_LIST = 'UPDATE_CUSTOMER_EVENT_NOTE_LIST';
export const UPDATING_CUSTOMER_EVENT_NOTE_LIST = 'UPDATING_CUSTOMER_EVENT_NOTE_LIST';

export function setEventLoadingStep() {
    return (dispatch) => {
        dispatch({
            type: SET_EVENT_LOADING_STEP,
        });
    };
}

export function toggleEventModalForm(val, selectedTab) {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_EVENT_MODAL_FORM,
            isEventModalVisible: val,
            selectedTab: selectedTab,
        });
    };
}

export function setSelectedVenue(id) {
    return (dispatch) => {
        dispatch({
            type: SELECTED_VENUE,
            selectedVenueID: id,
        });
    };
}

export function setSelectedEventId(eventId) {
    return (dispatch) => {
        dispatch({
            type: SET_EVENT_ID,
            selectedEventId: eventId,
        });
    };
}

export function fetchEventInfo(id) {
    return (dispatch) => {
        let model = {};
        model.eventId = id;
        callEventApi(`v3/admin-dashboard/event/${id}`, "GET").then((res) => {
            if (res.status === "Success") {
                model.event = res.data.event;
                dispatch({
                    type: FETCH_EVENT_INFO,
                    event: model.event,
                    bundleId: model.event.bundleId
                });
                if (model.event && model.event.city) {
                    dispatch(fetchBasicParameters(model.event.city));
                    dispatch(fetchLocationParameters(model.event.city));
                }
                setTimeout(() => {
                    if (model.event && model.event.previous_event_count && model.event.previous_event_count > 0 && model.event.userId) {
                        dispatch(fetchPreviousEvents(res.data.event.userId, res.data.event, id));
                    }
                    if (model.event.bundleId) {
                        dispatch(fetchEventBundleAtMount(model.event._id, model.event.bundleId));
                    }
                    if (model.event.eventstat) {
                        dispatch(updateFirstSeenEventStats(id));
                    }
                    if (model.event.isCorporateEvent) {
                        dispatch(fetchCorporateEventDetails(model.event.corporateEventId));
                    }
                    if (model.event) {
                        dispatch(fetchEventStats(model.event._id))
                    }
                    if (model.event && model.event.feedbackId) {
                        dispatch(fetchFeedback(model.event.feedbackId));
                    }
                    if (model.event) {
                        let query = {
                            "budget_end": model.event?.budget_end,
                            "city": model.event?.city,
                            "contact_email": model.event?.contact_email,
                            "contact_name": model.event?.contact_name,
                            "contact_phone": model.event?.contact_phone,
                            "guests": model.event?.guests,
                            "package_code": model.event?.package_code,
                            "isVerified": true,
                            "occasion": model.event?.occasion
                        }
                        dispatch(recommendeVenues(query))
                    }
                }, 800);
            }
        });
    };
}

// export function loadEventById(event, id) {
//     return (dispatch) => {
//         if (event.bundleId) {
//             dispatch(fetchEventBundle(event.bundleId));
//         }

//         if (event.eventstat) {
//             dispatch(updateFirstSeenEventStats(id));
//         }

//         if (event.isCorporateEvent) {
//             dispatch(fetchCorporateEventDetails(event.corporateEventId));
//         }

//         if (event && event.city) {
//             dispatch(fetchBasicParameters(event.city));
//         }

//         if (event && event.city) {
//             dispatch(fetchLocationParameters(event.city));
//         }

//         dispatch(fetchEventTemplates());

//         if (event) {
//             dispatch(fetchEventStats(event._id));
//         }

//         if (event && event.feedbackId) {
//             dispatch(fetchFeedback(event.feedbackId));
//         }
//     };
// }

export function fetchPreviousEvents(userId, event, id) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/user/${userId}/all-events`, "GET").then(
            (res) => {
                if (res.status === "Success") {
                    if (res.data && res.data.previousEvents) {
                        dispatch({
                            type: FETCH_PREVIOUS_EVENTS,
                            previousEvents: res.data.previousEvents.filter(
                                (item) => item._id !== event._id
                            ),
                        });
                    } else {
                        dispatch({
                            type: FETCH_PREVIOUS_EVENTS,
                            previousEvents: [],
                        });
                    }
                }
            }
        );
    };
}

export function fetchEventBundle(eventId, bundleId) {
    return (dispatch, getState) => {
        callEventApi(`v3/admin-dashboard/bundle/${bundleId}`, "get").then((res) => {
            if (!_.isEmpty(res.data)) {
                let bundle = res.data.bundle;
                dispatch({
                    type: FETCH_EVENT_BUNDLE,
                    bundle: bundle,
                });
                if (bundle) {
                    let assets = getState().event.assets;
                    let venueList = bundle && bundle.venueList ? bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                    let venuesNotFound = [];
                    venueList.forEach((item) => {
                        if (!assets[item.uid._id]) {
                            venuesNotFound.push({
                                venueId: item.uid._id,
                                contactId: item.uid.venuecontact
                            });
                            assets[item.uid._id] = {};
                            assets[item.uid._id].menus = [];
                            assets[item.uid._id].contact = {};
                            assets[item.uid._id].packages = [];
                            assets[item.uid._id].subpackages = [];
                            assets[item.uid._id].visits = [];
                        }
                    });
                    dispatch({
                        type: FETCH_VENUE_BUNDLE_LIST,
                        assets: assets,
                        venueList: venueList,
                        recoList: bundle.recoVenues ? bundle.recoVenues : [],
                        prevEmails: bundle.prevemails ? bundle.prevemails : []
                    });
                    dispatch(loadVenueSegment(venueList, assets, eventId))
                }
            }
        });
    };
}

export function fetchEventBundleSet(bundleId) {
    return (dispatch, getState) => {
        callEventApi(`v3/admin-dashboard/bundle/${bundleId}`, "get").then((res) => {
            if (!_.isEmpty(res.data)) {
                let bundle = res.data.bundle;
                dispatch({
                    type: FETCH_EVENT_BUNDLE,
                    bundle: bundle,
                });
                if (bundle) {
                    let assets = getState().event.assets;
                    let venueList = bundle && bundle.venueList ? bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                    let venuesNotFound = [];
                    venueList.forEach((item) => {
                        if (!assets[item.uid._id]) {
                            venuesNotFound.push({
                                venueId: item.uid._id,
                                contactId: item.uid.venuecontact
                            });
                            assets[item.uid._id] = {};
                            assets[item.uid._id].menus = [];
                            assets[item.uid._id].contact = {};
                            assets[item.uid._id].packages = [];
                            assets[item.uid._id].subpackages = [];
                            assets[item.uid._id].visits = [];
                        }
                    });
                    dispatch({
                        type: FETCH_VENUE_BUNDLE_LIST,
                        assets: assets,
                        venueList: venueList,
                        recoList: bundle.recoVenues ? bundle.recoVenues : [],
                        prevEmails: bundle.prevemails ? bundle.prevemails : []
                    });
                }
            }
        });
    };
}

export function fetchEventBundleAtMount(eventId, bundleId) {
    return (dispatch, getState) => {
        callEventApi(`v3/admin-dashboard/bundle/${bundleId}`, "get").then((res) => {
            if (!_.isEmpty(res.data)) {
                let bundle = res.data.bundle;
                dispatch({
                    type: FETCH_EVENT_BUNDLE,
                    bundle: bundle,
                });
                if (bundle) {
                    let assets = {};
                    let venueList = bundle && bundle.venueList ? bundle.venueList.filter(item => !isEmpty(item.uid)) : [];
                    venueList.forEach(item => {
                        assets[item.uid._id] = {};
                    });
                    let recoList = bundle && bundle.recoVenues ? bundle.recoVenues : [];
                    let prevEmails = bundle && bundle.prevemails ? bundle.prevemails : [];
                    dispatch({
                        type: FETCH_VENUE_BUNDLE_LIST,
                        assets: assets,
                        venueList: venueList,
                        recoList: recoList,
                        prevEmails: prevEmails
                    });
                    dispatch(loadVenueSegment(venueList, assets, eventId))
                }
            }
        });
    };
}

export function updateFirstSeenEventStats(id) {
    return (dispatch) => {
        callEventApi(`v2/eventstats/${id}/first-seen`, "get").then((res) => {
            dispatch({
                type: UPDATE_FIRST_SEEN_EVENT_STATS,
                eventstat: res.data?.eventstats ? res.data?.eventstats : [],
            });
        });
    };
}

export function fetchCorporateEventDetails(corporateEventId) {
    return (dispatch) => {
        callEventApi(
            `v3/admin-dashboard/corporate-event/${corporateEventId}/info`,
            "get"
        ).then((res) => {
            dispatch({
                type: FETCH_CORPORATE_EVENT_DETAILS,
                corporateEvent: res.data.corporateevent,
            });
        });
    };
}

export function fetchBasicParameters(city) {
    return (dispatch) => {
        callEventApiAndCache(`v3/dashboard/user/${city}/base-filters`, "GET").then((res) => {
            dispatch({
                type: FETCH_BASIC_PARAMETERS,
                params: res.data,
            });
        });
    };
}

export function fetchLocationParameters(city) {
    return (dispatch) => {
        let location = [];
        callEventApiAndCache(`v3/dashboard/user/${city}/subareas`, "GET").then((res) => {
            if (res.data.subareas) {
                location = res.data.subareas.map((item) => {
                    item.label = item.name;
                    item.value = item.sub_area;
                    return item;
                });
            }
            dispatch({
                type: FETCH_LOCATION_PARAMETERS,
                subareas: res.data.subareas,
                location: location
            });
        });
    };
}

export function fetchEventTemplates() {
    return (dispatch) => {
        callEventApiAndCache(`v3/eventtemplates/full-list`, "GET").then((res) => {
            dispatch({
                type: FETCH_EVENT_TEMPLATES,
                eventtemplates: res?.data?.eventtemplates,
            });
        });
    };
}

export function fetchEventStats(eventid) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/event/${eventid}/stats`, "get").then((res) => {
            dispatch({
                type: FETCH_EVENT_STATS,
                eventStat: res.data.stats,
            });
        });
    };
}

export function fetchFeedback(feedbackId) {
    return (dispatch) => {
        callEventApi(`v3/feedbacks/${feedbackId}/event-feedback`, "get").then((res) => {
            if (res && res.status === "Success" && res.data) {
                dispatch({
                    type: FETCH_FEEDBACK,
                    feedback: res.data.feedback,
                });
            }
        });
    };
}

export function loadVenueSegment(venueList, assets, id) {
    return (dispatch) => {
        dispatch({
            type: RELOADING_BUNDLE,
            isReLoadingBundle: true,
        });
        if (venueList) {
            let newVenueList = venueList.filter((item) => !!item.venueUpgrade);
            dispatch(loadPrimaryAssetssByVenue(venueList, assets));
            dispatch(loadSubpackagesByVenue(venueList, assets, id));
            dispatch(loadVenuebundlesByVenuebundleId(newVenueList));
            dispatch(loadVisitsByVenue(venueList, assets, id));
        }
        dispatch({
            type: RELOADING_BUNDLE,
            isReLoadingBundle: false,
        });
    }
}

export function loadVenuebundlesByVenuebundleId(venues) {
    return function (dispatch, getState) {
        let model = {};
        model.venuebundleList = [];
        async.each(
            venues,
            (item, callback) => {
                if (item && item.venueBundle) {
                    fetchVenueBundle(_.toString(item.venueBundle), (err, venuebundle) => {
                        venuebundle.venue_name = item.uid.name;
                        model.venuebundleList.push(venuebundle);
                        return callback();
                    });
                } else {
                    return callback();
                }
            },
            (err) => {
                dispatch({
                    type: RELOAD_BUNDLE,
                    venuebundleList: model.venuebundleList,
                });
            }
        );
    }
}

export function loadPrimaryAssetssByVenue(venues, asset) {
    return (dispatch) => {
        let assets = asset;
        async.each(
            venues,
            (item, callback) => {
                if (item && item.uid && item.uid._id) {
                    fetchPrimaryAssetsByVenueId(item.uid._id, (err, data) => {
                        if (assets[item.uid._id]) {
                            assets[item.uid._id].contact =
                                data && data.contact ? data.contact : {};
                            assets[item.uid._id].packages =
                                data && data.packages ? data.packages : [];
                            assets[item.uid._id].menus = data && data.menus ? data.menus : [];
                        }
                        return callback();
                    });
                } else {
                    return callback();
                }
            },
            (err) => {
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                });
            }
        );
    }
}

export function loadSubpackagesByVenue(venues, asset, id) {
    return (dispatch) => {
        let assets = asset;
        async.each(
            venues,
            (item, callback) => {
                if (item && item.uid !== "") {
                    fetchSubpackagesByVenueId(id, item.uid._id, (err, subpackages) => {
                        if (subpackages) {
                            assets[item.uid._id].subpackages = subpackages.map((item) => {
                                item.isPackageSelected = true;
                                return item;
                            });
                        } else {
                            assets[item.uid._id].subpackages = [];
                        }
                        return callback();
                    });
                } else {
                    assets[item.uid._id].subpackages = [];
                    return callback();
                }
            },
            (err) => {
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                });
            }
        );
    }
}

export function loadVisitsByVenue(venues, asset, id) {
    return (dispatch) => {
        let assets = asset;
        async.each(
            venues,
            (item, callback) => {
                if (item && item.uid !== "") {
                    fetchVisitsByVenueId(id, item.uid._id, (err, visits) => {
                        assets[item.uid._id].visits = visits;
                        return callback();
                    });
                } else {
                    assets[item.uid._id].visits = [];
                    return callback();
                }
            },
            (err) => {
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                });
            }
        );
    }
}

export function fetchPrimaryAssetsByVenueId(venueId, next) {
    callEventApi(`v3/admin-dashboard/venue/${venueId}/primary-assets`, "get").then(
        (res) => {
            return next(null, res.data);
        }
    );
}

export function fetchVenueBundle(id, next) {
    callEventApi(`v3/admin-dashboard/venuebundle/${id}`, "get").then((res) => {
        if (!_.isEmpty(res.data)) {
            return next(null, res.data.venuebundle);
        }
    });
}

export function fetchSubpackagesByVenueId(eventid, venueid, next) {
    callEventApi(
        `v3/admin-dashboard/event/${eventid}/venue/${venueid}/user-packages`,
        "get"
    ).then((res) => {
        return next(null, res.data.subpackages);
    });
}

export function fetchVisitsByVenueId(eventid, venueid, next) {
    callEventApi(
        `v3/admin-dashboard/event/${eventid}/venue/${venueid}/user-visits`,
        "get"
    ).then((res) => {
        return next(null, res.data.visits);
    });
}


// Post component did mount
export function reloadBundle() {
    return (dispatch, getState) => {
        let bundleId = getState().event.bundleId;
        let eventId = getState().event.event._id;
        dispatch({
            type: RELOADING_BUNDLE,
            isReLoadingBundle: true,
        });
        dispatch(fetchEventBundle(eventId, bundleId))

    }
};

export function loadVenueSegmentById(venueId, venuebundleId, asset, venueBundleList, id) {
    return (dispatch) => {
        let assets = asset;
        let venuebundleList = venueBundleList;
        if (assets[venueId]) {
            async.parallel([
                (cb) => {
                    if (venuebundleId) {
                        fetchVenueBundle(venuebundleId, (err, venuebundle) => {
                            if (venuebundle) {
                                venuebundleList.push(venuebundle);
                            }
                            return cb();
                        });
                    } else {
                        return cb();
                    }
                },
                (cb) => {
                    if (venueId) {
                        fetchPrimaryAssetsByVenueId(venueId, (err, data) => {
                            assets[venueId].menus = data.menus ? data.menus : [];
                            assets[venueId].packages = data.packages ? data.packages : [];
                            assets[venueId].contact = data.contact ? data.contact : {};
                            return cb();
                        });
                    } else {
                        return cb();
                    }
                },
                (cb) => {
                    if (venueId) {
                        fetchSubpackagesByVenueId(id, venueId, (err, subpackages) => {
                            if (subpackages) {
                                assets[venueId].subpackages = subpackages.map(item => {
                                    item.isPackageSelected = true;
                                    return item;
                                });
                            } else {
                                assets[venueId].subpackages = [];
                            }
                            return cb();
                        });
                    } else {
                        return cb();
                    }
                },
                (cb) => {
                    if (venueId) {
                        fetchVisitsByVenueId(id, venueId, (err, visits) => {
                            if (visits) {
                                assets[venueId].visits = visits.map(item => {
                                    item.isPackageSelected = true;
                                    return item;
                                });
                            } else {
                                assets[venueId].visits = [];
                            }
                            return cb();
                        });
                    } else {
                        return cb();
                    }
                }
            ], (err) => {
                if (err) {
                    console.log(err);
                } else {
                    dispatch({
                        type: FETCH_VENUE_SEGMENT_BY_ID,
                        assets: assets,
                        venuebundleList: venuebundleList
                    })
                }
            });
        }
    }
};

export function recommendeVenues(query) {
    return (dispatch) => {
        let model = [];
        let pageNum = 1;
        let pageSize = 10;
        if (query) {
            async.parallel([
                (cb) => {
                    fetchReccomendedVenues({query, pageNum, pageSize}, (err, list) => {
                        if (list) {
                            model.list1 = list ? list : [];
                            model.list1 = model.list1.map(item => {
                                item.algo = 'V1';
                                return item;
                            })
                            return cb();
                        } else {
                            return cb(err);
                        }
                    });
                },
                (cb) => {
                    fetchReccomendedVenuesByTags({query, pageNum, pageSize}, (err, list) => {
                        if (list) {
                            model.list2 = list ? list : [];
                            model.list2 = model.list2.map(item => {
                                item.algo = 'V2';
                                return item;
                            })
                            return cb();
                        } else {
                            return cb(err);
                        }
                    });
                },
            ], (err) => {
                if (err) {

                    console.log("err in com", err);
                } else {
                    let combinedList = model.list2.concat(model.list1)
                    let uniqueList = _.uniqBy(combinedList, 'vm_id');
                    dispatch({
                        type: FETCH_RECOMMENDED_VENUES,
                        recoListCombined: uniqueList,
                    })
                }
            });
        }
    }
};

export function fetchReccomendedVenues({query, pageNum, pageSize}, next) {
    callVenueApi(`v1/venues/recommended-venues`, 'post', {
        event: query,
        pageNum: pageNum,
        pageSize: pageSize
    }).then(res => {
        if (res.status === "Success") {
            return next(null, res.data.venues);
        } else {
            return next(null, []);
        }
    }).catch((e) => {
        console.log("err rec", e);
        return next(e);
    })
}

export function fetchReccomendedVenuesByTags({query, pageNum, pageSize}, next) {
    callVenueApi(`v1/venues/tags/recommended-venue`, 'post', {
        event: query,
        pageNum: pageNum,
        pageSize: pageSize
    }).then(res => {
        if (res.status === "Success") {
            return next(null, res.data.venues);
        } else {
            return next(null, []);
        }
    }).catch((e) => {
        return next(e);
    })
}

export function loadLogSegment(s_event) {
    return (dispatch) => {
        let model = {};
        model.event = s_event;
        dispatch({
            type: FETCHING_LOGS,
            isFetchingLogs: true,
        })

        if (model.event.userlog) {
            dispatch(fetchEventLog(model.event.userlog))
        }
        if (model.event) {
            dispatch(fetchEventTasks(model.event._id));
            dispatch(fetchEventEmailTasks(model.event._id));
            dispatch(fetchEventSmsTasks(model.event._id));
        }
    }
};

export function fetchEventLog(id) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/log/${id}`, 'get').then(res => {
            dispatch({
                type: FETCH_LOGS,
                logs: res.data.eventlog ? res.data.eventlog.logs : [],
            })
        })
    }
};

export function fetchEventTasks(id) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/event/${id}/task-list`, 'get').then(res => {
            dispatch({
                type: FETCH_TASKS,
                tasks: res.data.tasks ? res.data.tasks : [],
            })
        })
    }
};

export function fetchEventEmailTasks(eventid) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/event/${eventid}/email-list`, 'post').then(res => {
            dispatch({
                type: FETCH_EMAIL_TASKS,
                emailtasks: res.data.emailtasks ? res.data.emailtasks : [],
            })
        });
    }
};

export function fetchEventSmsTasks(eventid) {
    return (dispatch) => {
        callEventApi(`v3/admin-dashboard/event/${eventid}/sms-list`, 'post').then(res => {
            dispatch({
                type: FETCH_SMS_TASKS,
                smstasks: res.data.smstasks ? res.data.smstasks : [],
            })
        });
    }
};

export function convertLead(eventid, feedback) {
    return (dispatch) => {
        dispatch({
            type: CONVERT_LEAD,
            isClosingLead: true,
            isShowCloseWonBell: false
        })
        callEventApi(`v3/admin/${eventid}/convert`, 'POST', feedback).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: CONVERTING_LEAD,
                    event: res.data.event,
                    isShowCloseWonBell: true
                })
                setTimeout(() => {
                    dispatch({
                        type: CONVERT_LEAD,
                        isClosingLead: false,
                        isShowCloseWonBell: false
                    })
                    dispatch(toggleEventModalForm(false, 1));
                }, 3000);

            } else {
                dispatch({
                    type: CONVERT_LEAD,
                    isClosingLead: false,
                    isShowCloseWonBell: false
                })
                dispatch(toggleEventModalForm(false, 1));
            }

        });
    }
};

export function updateEvent(id, event) {
    return (dispatch) => {
        callEventApi(`v2/events/${id}/update`, 'post', event).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: FETCH_EVENT_INFO,
                    event: res.data.event,
                    bundleId: res.data.event.bundleId
                })
            }
        });
    }
};

export function addRecoVenues(bundleid, list) {
    return (dispatch) => {
        callEventApi(`v3/bundles/${bundleid}/venues/recommended`, 'POST', {
            recoVenues: list
        }).then(res => {
            if (res.status === 'Success') {
                showToastMessage("Venue Added", "success");
                dispatch(reloadBundle());
            }
        });
    }
};

export function shortlistRecoVenue(bundleid, venueid) {
    return (dispatch) => {
        callEventApi(`v3/bundles/${bundleid}/venues/${venueid}/recommended/add`, 'POST', {
            userSelected: false
        }).then(res => {
            dispatch(reloadBundle());
        });
    }
};

export function removeRecoVenue(bundleid, venueid) {
    return (dispatch) => {
        callEventApi(`v3/bundles/${bundleid}/venues/${venueid}/recommended/remove`, 'POST', {}).then(res => {
            dispatch(reloadBundle());
        });
    }
};

export function removeShortlistVenue(bundleid, venueid) {
    return (dispatch, getState) => {
        callEventApi(`v3/admin-dashboard/event/${getState().event.event._id}/venue/${venueid}/remove`, 'get').then(res => {
            dispatch(reloadBundle());
        });
    }
};

export function showMenuForVenuePackage(venueId, packageId, val) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].packages = assets[venueId].packages.map(item => {
            if (_.toString(item._id) === _.toString(packageId)) {
                item.isMenuVisible = val;
            }
            return item;
        });
        dispatch({
            type: FETCH_ASSETS,
            assets: assets
        })
    }
};

export function showVenueMenu(venueId, val) {
    return (dispatch, getState) => {
        let items = getState().event.venueList
        let venueList = items.map(item => {
            if (item.uid._id === venueId) {
                item.isVenueMenuVisible = val;
            }
            return item;
        });
        dispatch({
            type: FETCH_VENUE_LIST,
            venueList: venueList
        })
    }
};

export function clonePackage(venueId, subId, pack, eventId) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].packages = assets[venueId].packages.map(item => {
            if (item._id === pack?._id) {
                item.isProcessingPackages = true;
            }
            return item;
        });
        dispatch({
            type: FETCH_ASSETS,
            assets: assets
        })
        setTimeout(() => {
            callEventApi(`v3/admin-dashboard/event/${eventId}/venue/${subId}/package/${pack?._id}/clone-package`, 'POST', {
                package : pack
            }).then(res => {
                if (res.status === 'Success') {
                    let assets = getState().event.assets;
                    assets[venueId].packages = assets[venueId].packages.map(item => {
                        if (item._id === pack?._id) {
                            item.isProcessingPackages = false;
                        }
                        return item;
                    });
                    let subpackage = res.data.subpackage;
                    subpackage.isPackageSelected = true;
                    if (assets[venueId].subpackages) {
                        assets[venueId].subpackages.push(subpackage);
                    } else {
                        assets[venueId].subpackages = [];
                        assets[venueId].subpackages.push(subpackage);
                    }
                    dispatch({
                        type: FETCH_ASSETS,
                        assets: assets
                    })
                    dispatch(reloadBundle());
                    showToastMessage("Added", "success");
                } else {
                    showToastMessage('Failed, Try Again', 'error');
                }
            });
        }, 2000);
    }
};

export function updateCustomerEventInfo(id, event) {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATING_EVENT_INFO,
            isUpdatingEventInfo: true
        })
        callEventApi(`v3/admin-dashboard/customer-event/${id}/event-update`, 'POST', {
            event: event
        }).then(res => {
            if (res.status === 'Success') {
                showToastMessage('Details Updated', 'success');
                dispatch({
                    type: UPDATE_EVENT_INFO,
                    event: res.data.event ? res.data.event : event,
                })
                dispatch({
                    type: UPDATING_EVENT_INFO,
                    isUpdatingEventInfo: false
                })
            } else {
                dispatch({
                    type: UPDATING_EVENT_INFO,
                    isUpdatingEventInfo: false
                })
            }
        });
    }
};

export function changeInsideAgent(id, agentid) {
    return (dispatch, getState) => {
        let event = {
            agent_id: agentid
        };
        callEventApi(`v3/admin-dashboard/event/${id}/agent-update`, 'POST', {
            event: event,
        }).then(res => {
            if (res.data.event) {
                dispatch({
                    type: UPDATE_EVENT_INFO,
                    event: res.data.event,
                })
                showToastMessage('Agent Updated', 'success');
            }else{

                showToastMessage('Error occured!', 'error');
            }
            // this.props.close(true);
        });
    }
};

export function updateVisitAgent(id, visit_agent_id) {
    return (dispatch, getState) => {
        let event = {
            visit_agent_id: visit_agent_id
        };
        callEventApi(`v3/admin-dashboard/event/${id}/visit-agent-update`, 'POST', {
            event: event,
        }).then(res => {
            if (res.data.event) {
                dispatch({
                    type: UPDATE_EVENT_INFO,
                    event: res.data.event,
                })
                showToastMessage('Agent Updated', 'success');
            }else{

                showToastMessage(res.data.message.message, 'error');
            }
        });
    }
};

export function addVisitAgent(id, visit_agent_id) {
    return (dispatch, getState) => {
        let event = {
            visit_agent_id: visit_agent_id
        };
        callEventApi(`v3/admin-dashboard/event/${id}/visit-agent-add`, 'POST', {
            event: event,
        }).then(res => {
            if (res.data.event) {
                dispatch({
                    type: UPDATE_EVENT_INFO,
                    event: res.data.event,
                })
                showToastMessage('Agent Updated', 'success');
            }else{

                showToastMessage(res.data.message.message, 'error');
            }
        });
    }
};


export function findNewAgent(eventId) {
    return (dispatch) => {
        dispatch({
            type: FINDING_AGENT,
            isFindingNewAgent: true,
        })
        callEventApi(`v3/admin-dashboard/event/${eventId}/find-new-agent`, 'get').then((res) => {
            console.log(res);
            if (res && res.status === 'Success') {
                dispatch({
                    type: FIND_AGENT,
                    isFindingNewAgent: false,
                    isNewAgentFoundProcessed: true,
                    newFoundAgent: res.data.agent
                })
            }
        });
    }
};

export function clearAgent() {
    return (dispatch) => {
        dispatch({
            type: FIND_AGENT,
            isFindingNewAgent: false,
            isNewAgentFoundProcessed: false,
            newFoundAgent: {}
        })
    }
};

export function updateSubpackage(venueId, id, subpackage) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].subpackages = assets[venueId].subpackages.map(item => {
            if (item._id === id) {
                item.isUpdatingPackage = true;
            }
            return item;
        });
        dispatch({
            type: SAVING_PACKAGES,
            assets: assets,
            isSavingPackage: true,
        })
        callApi(`v2/subpackages/${id}/update`, 'POST', {
            subpackage: subpackage
        }).then(res => {
            if (res.status === 'Success') {
                let assets = getState().event.assets;
                assets[venueId].subpackages = assets[venueId].subpackages.map(item => {
                    if (item._id === id) {
                        let subpackage = res.data.subpackage;
                        subpackage.isPackageSelected = true;
                        return subpackage;
                    } else {
                        return item;
                    }
                });
                dispatch({
                    type: SAVING_PACKAGES,
                    assets: assets,
                    isSavingPackage: false,
                })
                showToastMessage('Updated', 'success');
            } else {
                showToastMessage('Failed, Try Again', 'error');

            }
        });
    }
};

export function removeSubpackage(venueId, subId, packageId) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].subpackages = assets[venueId].subpackages.map(item => {
            if (item._id === packageId) {
                item.isDeletingPackage = true;
            }
            return item;
        });
        dispatch({
            type: FETCH_ASSETS,
            assets: assets,
        })
        callEventApi(`v3/admin-dashboard/event/${getState().event.event._id}/venue/${subId}/package/${packageId}/remove-package`, 'get').then(res => {
            if (res.status === 'Success') {
                let assets = getState().event.assets;
                assets[venueId].subpackages = assets[venueId].subpackages.filter(item => _.toString(item._id) !== _.toString(packageId));
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                })
                showToastMessage('Removed', 'success');
            } else {
                let assets = getState().event.assets;
                assets[venueId].subpackages = assets[venueId].subpackages.map(item => {
                    if (item._id === packageId) {
                        item.isDeletingPackage = false;
                    }
                    return item;
                });
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                })
                showToastMessage('Failed, Try Again', 'error');
            }
        });
    }
};

export function editPackage(pack) {
    return (dispatch, getState) => {
        let menus = getState().event.assets[pack.venueId].menus;
        dispatch({
            type: EDIT_PACKAGE,
            isEditingPackage: true,
            isViewingPackageMenu: false,
            selectedPackage: pack,
            selectedMenus: menus
        })
    }
};

export function closeEditPackageForm() {
    return (dispatch, getState) => {
        dispatch({
            type: EDIT_PACKAGE,
            isEditingPackage: false,
            isViewingPackageMenu: false,
            selectedPackage: {},
            selectedMenus: []
        })
    }
};

export function showMenu(pack) {
    return (dispatch, getState) => {
        let menus = getState().event.assets[pack.venueId].menus;
        dispatch({
            type: EDIT_PACKAGE,
            isEditingPackage: false,
            isViewingPackageMenu: true,
            selectedPackage: pack,
            selectedMenus: menus
        })
    }
};

export function closeVenueMenu() {
    return (dispatch, getState) => {
        dispatch({
            type: EDIT_PACKAGE,
            isEditingPackage: false,
            isViewingPackageMenu: false,
            selectedPackage: {},
            selectedMenus: []
        })
    }
};

export function addPackage(venueId, subId, subpackage) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        if (assets[venueId] && assets[venueId].packages) {
            assets[venueId].packages = assets[venueId].packages.map(item => {
                item.isProcessingPackages = true;
                return item;
            });
        }
        dispatch({
            type: FETCH_ASSETS,
            assets: assets
        })
        callEventApi(`v3/admin-dashboard/bundle/${getState().event.bundleId}/venue/${subId}/add-package`, 'POST', {
            subpackage: subpackage
        }).then(res => {
            if (res.status === 'Success') {
                let assets = getState().event.assets;
                if (assets[venueId] && assets[venueId].packages) {
                    assets[venueId].packages = assets[venueId].packages.map(item => {
                        item.isProcessingPackages = false;
                        return item;
                    });
                }
                let subpackage = res.data.subpackage;
                subpackage.isPackageSelected = true;
                if (assets[venueId].subpackages) {
                    assets[venueId].subpackages.push(subpackage);
                } else {
                    assets[venueId].subpackages = [];
                    assets[venueId].subpackages.push(subpackage);
                }
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets
                })
                showToastMessage('Added', 'success');
            } else {
                showToastMessage('Failed, Try Again', 'error');
            }
        });
    }
};

export function updateAssetData(venueId, moduleName, subId, keyName, val) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId][moduleName] = assets[venueId][moduleName].map(item => {
            if (item._id === subId) {
                item[keyName] = val;
            }
            return item;
        });
        dispatch({
            type: FETCH_ASSETS,
            assets: assets
        })
    }
};

export function planVisit(venueId, visit) {
    return (dispatch, getState) => {
        let venueList = getState().event.venueList.map(item => {
            if (item.uid._id === venueId) {
                item.isPlanningVisit = true;
            }
            return item;
        });
        dispatch({
            type: FETCH_VENUE_LIST,
            venueList: venueList
        })
        callEventApi(`v3/admin-dashboard/event/${getState().event.event._id}/venue/${venueId}/plan-visit`, 'POST', {
            visit: visit
        }).then(res => {
            if (res.status === 'Success') {
                let assets = getState().event.assets;
                let venueList = getState().event.venueList.map(item => {
                    if (item.uid._id === venueId) {
                        item.isPlanningVisit = false;
                    }
                    return item;
                });
                if (assets[venueId].visits) {
                    assets[venueId].visits.push(res.data.visit);
                } else {
                    assets[venueId].visits = [];
                    assets[venueId].visits.push(res.data.visit);
                }
                dispatch({
                    type: PLAN_VISIT,
                    assets: assets,
                    venueList: venueList
                })
                showToastMessage('Visit Planned', 'success');
            } else {
                showToastMessage('Failed, Try Again', 'error');
            }
        });
    }
};

export function updateVisit(venueId, id, visit) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].visits = assets[venueId].visits.map(item => {
            item.isUpdatingVisitDate = true;
            return item;
        });
        dispatch({
            type: UPDATE_VISIT,
            assets: assets,
            isSavingVisit: true
        })
        callEventApi(`v2/visits/${id}/update`, 'POST', {
            visit: visit
        }).then(res => {
            if (res.status === 'Success') {
                let assets = getState().event.assets;
                assets[venueId].visits = assets[venueId].visits.map(item => {
                    if (item._id === id) {
                        return res.data.visit;
                    } else {
                        return item;
                    }
                });
                dispatch({
                    type: UPDATE_VISIT,
                    assets: assets,
                    isSavingVisit: true
                })
                showToastMessage('Updated', 'success');
            } else {
                showToastMessage('Failed, Try Again', 'error');
            }
        });
    }
};

export function removeVisit(venueId, visitId) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].visits = assets[venueId].visits.map(item => {
            item.isDeletingVisit = true;
            return item;
        });
        dispatch({
            type: FETCH_ASSETS,
            assets: assets,
        })
        callEventApi(`v3/admin-dashboard/event/${getState().event.event._id}/venue/${venueId}/visit/${visitId}/remove-visit`, 'get').then(res => {
            if (res.status === 'Success') {
                let assets = getState().event.assets;
                assets[venueId].visits = assets[venueId].visits.filter(item => _.toString(item._id) !== _.toString(visitId));
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                })
                showToastMessage('Removed', 'success');

            } else {
                let assets = getState().event.assets;
                assets[venueId].visits = assets[venueId].visits.map(item => {
                    if (item._id === visitId) {
                        item.isDeletingVisit = false;
                    }
                    return item;
                });
                dispatch({
                    type: FETCH_ASSETS,
                    assets: assets,
                })
                showToastMessage('Failed, Try Again', 'error');
            }
        });
    }
};

export function handleVenueVisitUpdate(venueId, e, p) {
    return (dispatch, getState) => {
        let assets = getState().event.assets;
        assets[venueId].visits[0].visit_date = new Date(p.startDate.toISOString());
        dispatch({
            type: FETCH_ASSETS,
            assets: assets,
        })
    }
};

export function closeEditVisitForm() {
    return (dispatch) => {
        dispatch({
            type: EDIT_VISIT_FORM,
            isEditingVisit: false,
            selectedVisit: {},
        })
    }
};

export function openScheduleVisit(visitId) {
    return (dispatch) => {
        dispatch({
            type: SCHEDULE_VISIT,
            isSchedulingVisit: true,
            scheduleVisitId: visitId,
        })
    }
};

export function closeScheduleVisitForm() {
    return (dispatch) => {
        dispatch({
            type: SCHEDULE_VISIT,
            isSchedulingVisit: false,
            scheduleVisitId: '',
        })
    }
};

export function editVisit(visit) {
    return (dispatch) => {
        dispatch({
            type: EDIT_VISIT_FORM,
            isEditingVisit: true,
            selectedVisit: visit,
        })
    }
};

export function handleScheduleVisit(visit) {
    return (dispatch) => {
        callEventApi('v3/visits/schedule-visit', 'post', {
            visit: visit
        }).then((res) => {
            console.log(res);
            if (res.status === 'Success') {
                showToastMessage('Visit Scheduled!', 'success');
            } else {
                showToastMessage('Failed!', 'error');
            }
        });
    }
};

export function sendEmailForVisitInfoToUser(list) {
    return (dispatch, getState) => {
        dispatch({
            type: SEND_USER_EMAIL,
            isSendingUserEmail: true
        })
        let data = {};
        data.venueList = list;
        callEventApi(`v3/dashboard/event/${getState().event.event._id}/notify/visit`, 'POST', data).then(res => {
            dispatch({
                type: SEND_USER_EMAIL,
                isSendingUserEmail: false
            })
            dispatch(reloadBundle());
            showToastMessage('Mail Sent!', 'success');
        });
    }
};

export function notifyToVenues(eventId, list) {
    return (dispatch) => {
        dispatch({
            type: NOTIFY_VENUES,
            isInformingVenue: true
        })
        callEventApi(`v3/events/${eventId}/notify`, 'POST', {
            list: list
        }).then(res => {
            if (res.status === "Success") {
                dispatch(reloadBundle());
                showToastMessage('Notified to venues', 'success');
            } else {
                showToastMessage(res.data.message, 'error');
            }
            dispatch({
                type: NOTIFY_VENUES,
                isInformingVenue: false
            })
        });
    }
};


export function getAgentOptions(input) {
    return (dispatch) => {
        if (!input) {
            dispatch({
                type: AGENT_OPTIONS,
                options: []
            })
        } else {
            let searchTerm = encodeURI(input);
            let URL = `${SEARCH_AGENT_API}${searchTerm}&team=sales`;
            fetch(URL)
                .then((response) => response.json())
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: AGENT_OPTIONS,
                            options: res.data.agents
                        })
                    } else {
                        dispatch({
                            type: AGENT_OPTIONS,
                            options: []
                        })
                    }
                });
        }
    }

};

export function getPackageOptions(input) {
    return (dispatch) => {
        if (!input) {
            dispatch({
                type: AGENT_OPTIONS,
                options: []
            })
        }
        let searchTerm = encodeURI(input);
        let URL = SEARCH_PACKAGE_API + searchTerm;
        return fetch(URL)
            .then((response) => response.json())
            .then((res) => {
                if (res.data) {
                    dispatch({
                        type: AGENT_OPTIONS,
                        options: res.data
                    })
                } else {
                    dispatch({
                        type: AGENT_OPTIONS,
                        options: []
                    })
                }
            });
    }
};

export function updateVenueBundleAdminNote(id, note) {
    return (dispatch, getState) => {
        callEventApi(`v3/admin-dashboard/venuebundle/${id}/add-admin-note`, 'post', {
            note: note
        }).then(res => {
            if (res.status === 'Success') {
                let venuebundleList = getState().event.venuebundleList.map(item => {
                    if (item._id === id) {
                        let newItem = res.data.venuebundle;
                        newItem.venue_name = item.venue_name;
                        return newItem;
                    } else {
                        return item;
                    }
                });
                dispatch({
                    type: RELOAD_BUNDLE,
                    venuebundleList: venuebundleList
                })
            }
        });
    }
};

export function sendSmsToVenue(id, text) {
    return (dispatch) => {
        dispatch({
            type: SMS_TO_VENUE,
            isSendingSmsToVenue: true
        })
        //TODO review ritwik
        callEventApi(`v3/bundles/contact/${id}/sms`, 'POST', {
            text: text
        }).then(res => {
            dispatch({
                type: SMS_TO_VENUE,
                isSendingSmsToVenue: false
            })
            showToastMessage('Sms Sent', 'success');
        });
    }
};

export function sendSmsForUser(bundleid, data) {
    return (dispatch, getState) => {
        dispatch({
            type: SMS_TO_USER,
            isSendingSmsToUser: true
        })
        //TODO v1 to v3 change ritwik
        callEventApi(`v3/bundles/user/sms`, 'POST', data).then(res => {
            dispatch({
                type: SMS_TO_USER,
                isSendingSmsToUser: false
            })
            showToastMessage('Sms Sent', 'success');
        });
    }
};

export function sendEmailForPackageInfoToUser(isTemplateTextToBeUsed, list) {
    return (dispatch, getState) => {
        let bundleId = getState().event.bundleId;
        dispatch({
            type: SEND_USER_EMAIL,
            isSendingUserEmail: true
        })
        let data = {};
        data.packageList = list;
        let model = {};
        callApi(`v3/dashboard/admin/${getState().event.eventId}/package-email`, 'POST', data).then(res => {
            if (res && res.status === 'Success') {
                model.event = res.data.event;
                dispatch({
                    type: UPDATE_EVENT_INFO,
                    event: model.event
                })
            }
        });
        callApi(`v2/eventstats/${getState().event.eventId}/option-sent`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                dispatch({
                    type: SEND_USER_EMAIL,
                    isSendingUserEmail: false
                })
                // dispatch(reloadBundle());
                dispatch(fetchEventBundleSet(bundleId));
                showToastMessage('Mail Sent', 'success');
            }
        });


    }
};

export function handleCityChange(city) {
    return (dispatch) => {
        if (city && city !== '') {
            dispatch(fetchBasicParameters(city))
            dispatch(fetchLocationParameters(city))

        }
    }
}

export function queryForVenue(city, text) {
    return (dispatch) => {
        if (text && text.length > 2) {
            callApi(`venueslistquery/${city}/${text}`, 'GET').then(res => {
                if (res.data) {
                    dispatch({
                        type: QUERY_LIST,
                        queryList: res.data
                    })
                }
            });
        }
    }
};

export function closeQueryForVenueList() {
    return (dispatch) => {
        dispatch({
            type: QUERY_LIST,
            queryList: []
        })

    }
}

export function shortlistRecoVenueFromVenueSelection(bundleid, venueid) {
    return (dispatch, getState) => {
        let list = [];
        list.push({
            uid: venueid
        });
        let queryList = getState().event.queryList.map(item => {
            if (item._id === venueid) {
                item.isShortlisting = true;
            }
            return item;
        });
        dispatch({
            type: QUERY_LIST,
            queryList: queryList
        })
        setTimeout(() => {
            callEventApi(`v3/bundles/${bundleid}/venues/recommended`, 'POST', {
                recoVenues: list
            }).then(res => {
                if (res.status === 'Success') {
                    showToastMessage('Venue Added!', 'success');
                    let queryList = getState().event.queryList.map(item => {
                        if (item._id === venueid) {
                            item.isShortlisting = false;
                        }
                        return item;
                    });
                    dispatch({
                        type: QUERY_LIST,
                        queryList: queryList
                    })
                    dispatch(shortlistRecoVenue(bundleid, venueid));
                }
            });
        }, 800);
    }
};

export function queryBundle(text) {
    return (dispatch) => {
        if (text && text.length > 2) {
            callEventApi(`v3/bundlesets/search/${text}/list`, 'GET').then(res => {
                if (res.data) {
                    dispatch({
                        type: QUERY_BUNDLE_SET,
                        queryBundleSet: res.data.bundlesets
                    })
                }
            });
        }
    }
};

export function selectBundle(bundlesetid) {
    return (dispatch, getState) => {
        let queryBundleSet = getState().event.queryBundleSet.map(item => {
            if (item._id === bundlesetid) {
                item.isLoading = true;
            }
            return item;
        });
        dispatch({
            type: QUERY_BUNDLE_SET,
            queryBundleSet: queryBundleSet
        })
        setTimeout(() => {
            callEventApi(`v3/bundlesets/selectbundleset/${bundlesetid}/bundle/${getState().event.event.bundleId}/event/${getState().event.event._id}`, 'GET').then(res => {
                if (res.status === 'Success') {
                    dispatch({
                        type: QUERY_BUNDLE_SET,
                        queryBundleSet: []
                    })
                    dispatch(reloadBundle(getState().event.event.bundleId));
                    showToastMessage('Bundle Added Successfully', 'success');
                } else {
                    showToastMessage('Failed to Add Bundle', 'error');
                }
            });
        }, 500);
    }
}

export function addEventNote(event_id, eventnote) {
    return (dispatch) => {
        dispatch({
            type: ADDING_CUSTOMER_EVENT_NOTE,
            isCreatingNote: true
        })
        return callEventApi(`v3/admin-dashboard/event/${event_id}/add-note`, 'POST', {
            eventnote: eventnote
        }).then(res => {
            if (res.status === 'Success') {
                showToastMessage("Note Added", "success");
                dispatch({
                    type: ADD_CUSTOMER_EVENT_NOTE_LIST,
                    event_id: event_id,
                    isCreatingNote: false,
                    eventnote: res.data.eventnote,
                });
            } else {
                dispatch({
                    type: ADDING_CUSTOMER_EVENT_NOTE,
                    isCreatingNote: false
                })
            }
        });
    };
}

export function fetchEventNotes(event_id, filters) {
    return (dispatch) => {
        return callEventApi(`v3/admin-dashboard/event/${event_id}/list`, 'POST', {filters: filters ? filters : {}}).then(res => {
            console.log("res", res);
            if (res.status === 'Success') {
                dispatch({
                    type: LOAD_CUSTOMER_EVENT_NOTE_LIST,
                    event_id: event_id,
                    eventnotes: res.data.eventnotes ? res.data.eventnotes : [],
                    totalCount: res.data.total
                });
            }
        });
    };
}

export function updateEventNote(event_id, note_id, update) {
    return (dispatch) => {
        dispatch({
            type: UPDATING_CUSTOMER_EVENT_NOTE_LIST,
            isUpdatingNote: true
        })
        return callEventApi(`v3/admin-dashboard/event/${event_id}/note/${note_id}/update-note`, 'POST', {
            eventnote: update
        }).then(res => {
            if (res.status === 'Success') {
                showToastMessage('Note Updated', 'success');
                dispatch({
                    type: UPDATE_CUSTOMER_EVENT_NOTE_LIST,
                    event_id: event_id,
                    isUpdatingNote: false,
                    eventnote: res.data,
                });
            } else {
                dispatch({
                    type: UPDATING_CUSTOMER_EVENT_NOTE_LIST,
                    isUpdatingNote: false
                })
            }
        });
    };
}

export function removeEventNote(event_id, note_id) {
    return (dispatch) => {
        dispatch({
            type: REMOVING_CUSTOMER_EVENT_NOTE_LIST,
            isRemovingNote: true
        })
        return callEventApi(`v3/admin-dashboard/event/${event_id}/note/${note_id}/remove-note`, 'POST', {}).then(res => {
            if (res.status === 'Success') {
                dispatch({
                    type: REMOVE_CUSTOMER_EVENT_NOTE_LIST,
                    isRemovingNote: false,
                    event_id: event_id,
                    note_id: note_id,
                    eventnote: res.data.eventnote ? res.data.eventnote : {},
                });
            } else {
                dispatch({
                    type: REMOVING_CUSTOMER_EVENT_NOTE_LIST,
                    isRemovingNote: false
                })
            }
        });
    };
}

