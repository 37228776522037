import React, {Component} from 'react';
import _  from 'lodash';
import {
    Button,
    ButtonGroup
} from 'react-bootstrap';
import shortid from 'shortid';
import {connect} from 'react-redux';
import moment from "moment";
import { getAsset, getIsSendingUserEmail, getSelectedVenue, getToggleForAssetChange, getVenueList } from '../../../redux/admin/EventReducer';
import { getTotalAgentMap } from '../../../redux/admin/UserReducer';
import { getAgentName } from '../../../util/utility';
class BookedVisitsInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            visits: [],
            visit_agent_name:''
        };
    }

    componentDidMount() {
        let venues = [];
        // this.props.venueList.forEach(item => {
        //     let venue = {};
        //     venue.venueId = item.uid._id;
        //     venue.selected = true;
        //     this.props.assets[item.uid._id].visits.forEach(visit => {
        //         venue.packageId = visit._id;
        //         venues.push(venue);
        //     });
        // });
       
        this.setState({
            venues: venues
        });
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.venues) {
            let venues = [];
            this.setState({
                venues: venues
            });
        }
    }

    handleSelectionChange = (venueId, subId, val) => {
        this.props.updateAssetData(venueId, 'visits' , subId, 'isPackageSelected', val);
    };

    sendEmail = () => {
        let venueList = [];
        this.props.venueList.filter(data => data._id == this.props.selectedVenueID).forEach(venue => {
            if(!_.isEmpty(this.props.assets) && !_.isEmpty(this.props.assets[venue.uid._id])) {
                this.props.assets[venue.uid._id].visits.filter(item => item.isPackageSelected).map(item => {
                    venueList.push({
                        venueId: venue.uid._id,
                        visitId: item._id
                    });
                });
            }
        });
        this.props.send(venueList);
    };

    toggleControl = (venueId, subId, keyName, val) => {
        let visit = {};
        visit[keyName] = !val;
        this.props.updateVisit(venueId, subId, visit);
    };

    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Planned Visits
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Select</th>
                        {/* <th>Venue Details</th> */}
                        <th>Visible To User</th>
                        <th>Date</th>
                        <th>Confirmation</th>
                        <th>Visit Agent</th>
                        <th>Status</th>
                        <th>Cancel</th>
                        <th>Remove</th>
                    </tr>
                    </thead>
                    <tbody>
                    { this.props.venueList && this.props.venueList.length < 1 ? <tr><td>No Venues</td></tr> : <tr></tr>}
                    {this.props.venueList && this.props.venueList.filter(data => data._id == this.props.selectedVenueID).map((item) => (
                        this.props.assets[item.uid._id] && this.props.assets[item.uid._id].visits && this.props.assets[item.uid._id].visits.length > 0 ?
                            this.props.assets[item.uid._id].visits.map(visit => (
                                <tr className="mngevent-email-details" key={shortid.generate()}>
                                    <td>
                                        <Button
                                            onClick={() => this.handleSelectionChange(item.uid._id, visit._id, !visit.isPackageSelected)}
                                            className={"btn-default"}
                                        >
                                            {visit.isPackageSelected ? <i className="fa fa-check" /> : <i className="fa fa-times" />}
                                        </Button>
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() => this.toggleControl(item.uid._id, visit._id, 'isVisibleToUser', !!visit.isVisibleToUser)}
                                        >{visit.isVisibleToUser ? 'Yes' : 'No'}</Button>
                                    </td>
                                    <td>
                                        <span>{moment(new Date(visit.visit_date)).format('DD-MMM-YYYY - HH:mm')}</span>
                                        <span onClick={() => this.props.openScheduleVisit(visit._id)} className="btn btn-sm btn-warning" style={{marginLeft: '5px'}}><i className="fa fa-edit"></i></span>
                                    </td>
                                    <td>
                                        <span>User</span>
                                        <span style={{marginLeft: '5px'}}>
                                            <Button className="btn btn-sm btn-default"
                                                onClick={() => this.toggleControl(item.uid._id, visit._id,'isConfirmedByUser', !!visit.isConfirmedByUser)}
                                            >{visit.isConfirmedByUser ? 'Yes' : 'No'}</Button>
                                        </span>
                                        <br/>
                                        <span>Venue</span>
                                        <span style={{marginLeft: '5px'}}>
                                            <Button className="btn btn-sm btn-default"
                                                onClick={() => this.toggleControl(item.uid._id, visit._id,'isConfirmedByVenue', !!visit.isConfirmedByVenue)}
                                            >{visit.isConfirmedByVenue ? 'Yes' : 'No'}</Button>
                                        </span>

                                    </td>
                                    <td>
                                        {getAgentName(this.props.totalAgentMap, visit.agentId)}
                                    </td>
                                    <td>
                                        {visit.isVisitCompleted ? 'Completed' : ''}
                                        {visit.isVisitCancelled ? 'Cancelled' : ''}
                                    </td>
                                    <td>
                                        <Button
                                            onClick={() => this.toggleControl(item.uid._id, visit._id,'isVisitCancelled', !!visit.isVisitCancelled)}
                                        >{visit.isVisitCancelled ? 'Yes' : 'No'}</Button>
                                    </td>
                                    <td>
                                        <Button
                                            disabled={item.isDeletingVisit}
                                            onClick={() => this.props.removeVisit(item.uid._id, visit._id)}
                                        >{item.isDeletingVisit ? <i className="fa fa-refresh fa-spinner" /> : <i className="fa fa-trash-o" />}
                                        </Button>
                                    </td>
                                </tr>
                            ))
                            :
                            null
                    ))}
                    </tbody>
                </table>
                <div>
                </div>
                <div className="panel-footer">
                    <div className="row">
                        <div className="col-sm-8 col-sm-offset-2">
                            <ButtonGroup>
                                <Button
                                    disabled={this.props.isSendingUserEmail}
                                    onClick={() => this.sendEmail()}
                                    className="btn-default"
                                >{this.props.isSendingUserEmail ? "Sending To Customer" : "Send To Customer"}
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}


function mapStateToProps(state, props) {
    return {
        assets: getAsset(state),
        toggleForAssetChange: getToggleForAssetChange(state),
        isSendingUserEmail : getIsSendingUserEmail(state),
        venueList: getVenueList(state),
        selectedVenueID: getSelectedVenue(state),
        totalAgentMap : getTotalAgentMap(state),
    }
}

export default connect(mapStateToProps)(BookedVisitsInfo);
