import React, {Component} from 'react';
import {
    Button, FormControl
} from 'react-bootstrap';

class PartnerVenueLeads extends Component {
    constructor(props) {
        super(props);
        this.state = {
            venues: [],
            isAdminNoteEditable: false,
            selectedVenuebundleId: '',
            selectedAdminNote: ''
        };
    }

    // componentDidMount() {
    //     let venues = [];
    //     venues = this.props.venues.map(item => {
    //         item.selected = true;
    //         return item;
    //     });
    //     this.setState({
    //         venues: venues
    //     });
    // }
    //
    // componentWillReceiveProps(nextProps) {
    //     if(nextProps.venues) {
    //         let venues = [];
    //         venues = nextProps.venues.map(item => {
    //             item.selected = true;
    //             return item;
    //         });
    //         this.setState({
    //             venues: venues
    //         });
    //     }
    // }

    editAdminNote = (id, note) => {
        this.setState({
            selectedVenuebundleId: id,
            selectedAdminNote: note,
            isAdminNoteEditable: true
        });
    };

    closeAdminNote = () => {
        this.setState({
            selectedVenuebundleId: '',
            selectedAdminNote: '',
            isAdminNoteEditable: false
        });
    };

    handleAdminNoteChange = (note) => {
        this.setState({
            selectedAdminNote: note,
        });
    };

    handleSelectionChange = (id) => {
        let oldArray = this.state.venues;
        let newArray = oldArray.map(item => {
            if (item._id === id) {
                item.selected = !item.selected;
            }
            return item;
        });
        this.setState({
            venues: newArray
        });
    };

    submit = () => {
        let venueList = this.state.venues.filter( item => !item.venueUpgrade && item.selected);
        let newVenueList = venueList.map(item => {
            return item.uid._id;
        });
        this.props.send(this.props.eventId, newVenueList);
    };

    saveAdminNote = () => {
        this.props.updateVenueBundleAdminNote(this.state.selectedVenuebundleId, this.state.selectedAdminNote);
        this.closeAdminNote();
    };

    render() {
        return (
            <div id="useremail-packages" className="panel panel-default">
                <div className="panel-heading">
                    Venue Dashboard
                </div>
                <div className="panel-body">
                    {
                        this.state.isAdminNoteEditable ?
                            <div className="panel panel-default">
                                <div className="panel-heading">Edit Note</div>
                                <div className="panel-body">
                                    <FormControl
                                        componentClass={'textarea'}
                                        value={this.state.selectedAdminNote}
                                        onChange={(e) => this.handleAdminNoteChange(e.target.value)}
                                    />
                                </div>
                                <div className="panel-footer">
                                    <Button onClick={() => this.saveAdminNote()}>
                                        Save
                                    </Button>
                                    <Button
                                        onClick={() => this.closeAdminNote()}
                                    >
                                        Close
                                    </Button>
                                </div>
                            </div>
                            :
                            null

                    }
                </div>
                <table className="table">
                    <thead>
                    <tr>
                        <th>Venue Name</th>
                        <th>Admin Note</th>
                        <th>Venue Note</th>
                        <th>Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.venuebundleList && this.props.venuebundleList.length < 1 ? <tr><td>No Venues</td></tr> : <tr></tr>}
                    {this.props.venuebundleList && this.props.venuebundleList.map(item => (
                        <tr className="mngevent-email-details" key={item._id}>
                            <td>
                                {item.venue_name}
                            </td>
                            <td>
                                {item.adminNoteForVenue}
                                {item.adminNoteForVenue ?
                                    <Button
                                        onClick={() => this.editAdminNote(item._id, item.adminNoteForVenue)}
                                    ><i className="fa fa-edit"></i></Button>
                                     :
                                    <Button
                                        onClick={() => this.editAdminNote(item._id, '')}
                                    >Add</Button>
                                }
                            </td>
                            <td>
                                {item.venueNote}
                            </td>
                            <td>
                                {item.isVenueAvailableForEventDate ?
                                    <span>
                                        {item.status}
                                        {item.isClosedWon || item.isFrozen ? `Reason - ${item.conversion_factor}` :'' }
                                    </span>
                                    :
                                    <span>{'Not Accepted Yet'}</span>
                                }
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }

}

export default PartnerVenueLeads;
