import { combineReducers } from 'redux';
import app from './redux/admin/AppReducer';
import user from './redux/admin/UserReducer';
import agent from './redux/admin/OnlineCustomerReducer.js';
import sales from './redux/admin/SalesAgentsQueueReducer';
import event from './redux/admin/EventReducer';
import dialer from './redux/admin/DialerReducer';
const rootReducer = combineReducers({
    app,
    sales,
    event,
    agent,
    user,
    dialer
});

export default rootReducer;