import {
    AGENT_DETAILS, SET_AGENT_LIST, SET_OPERATIONAL_CITY_LIST, QC_COUNT
} from './AppAction';

const initialState = {
    agentName: '',
    agentId: '',
    totalAgentList: [],
    operationalCityList: [],
    totalAgentMap: {},
    qc_count: {}
};

const AppReducer = (state = initialState, action) => {
    let cities = [];

    switch (action.type) {
        case QC_COUNT: {
            return {
                ...state,
                qc_count: action.count
            }
        }

        case SET_OPERATIONAL_CITY_LIST:{

            let  operationalCities = action.cityList.map((item) => {
                  item.label = item.name;
                  item.value = item.slug;
                  return item;
              });
              return {
                  ...state,
                  operationalCityList: operationalCities
              }
          }

        case AGENT_DETAILS:
            return {
                ...state,
                agentName: action.agentName,
                agentId: action.agentId,
            };
        case SET_AGENT_LIST:
            let objectMap = {};
            if (action.agentList && action.agentList.length > 0) {
                action.agentList.forEach(agent => {
                    objectMap[agent._id] = agent;
                })
            }
            return {
                ...state,
                totalAgentList: action.agentList,
                totalAgentMap: objectMap
            };
            default:
                return state;
        }
    }

    export const getAgentName = (state) => state.app.agentName;
    export const getAgentId = (state) => state.app.agentId;
    export const getTotalAgentMap = (state) => state.user.totalAgentMap;
    export const getOperationalCityList = (state) => state.app.operationalCityList

    export const getQcCount = (state) => state.app.qc_count;

    export default AppReducer;
