import fetch from 'isomorphic-fetch';
import moment from 'moment';

const API_URL = process.env.REACT_APP_EVENT_API_URL || `https://event-api.venuemonk.com`;
// const API_URL = `http://localhost:8003`;

export default function callEventApi(endpoint, method = 'get', body) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    return fetch(`${API_URL}/api/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}

export function callEventApiAndCache(endpoint, method = 'get', body, expireHour) {
    let token = window.localStorage.getItem('token') ?  window.localStorage.getItem('token') : '';
    let headers = {};
    headers['content-type'] = 'application/json';
    if (token && token !=='') {
        headers.Authorization = `Bearer ${token}`;
    }
    let cacheResponse = window.localStorage.getItem(`event-api/${endpoint}`);
    if (cacheResponse) {
        let parsedResponse = JSON.parse(cacheResponse);
        if (parsedResponse.expires && moment(parsedResponse.expires).isAfter(moment())) {
            return Promise.resolve(parsedResponse.data);
        }
    }
    return fetch(`${API_URL}/api/${endpoint}`, {
        headers: headers,
        method,
        body: JSON.stringify(body),
    })
        .then(response => response.json().then(json => ({json, response})))
        .then(({json, response}) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            let cachedResponse = {
                data:  json,
                expires: moment().startOf('hour').add(expireHour ? expireHour : 1, 'hour').toISOString()
            }
            window.localStorage.setItem(`api/${endpoint}`, JSON.stringify(cachedResponse));
            return json;
        })
        .then(
            response => response,
            error => error
        );
}
