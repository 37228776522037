import {GLOBAL_CHANNEL} from "../../Connector";
import callApi from '../../util/apiCaller';
import callEventApi from '../../util/eventApiCaller';

export const OPEN_AGENT_BUTTON = "OPEN_AGENT_BUTTON";
export const CLOSE_AGENT_BUTTON = "CLOSE_AGENT_BUTTON";
export const FETCH_ONLINE_AGENTS = "FETCH_ONLINE_AGENTS";
export const FETCH_KEYS = "FETCH_KEYS";
export const SET_ONLINE_USERS = "SET_ONLINE_USERS";
export const TOTAL_ONLINE_USER = "TOTAL_ONLINE_USER";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";

export const openAgentButton = () => {
    return {
        type: OPEN_AGENT_BUTTON,
        openAgentList: true,
        showFabButton: false
    };
};

export const closeAgentButton = () => {
    return {
        type: CLOSE_AGENT_BUTTON,
        openAgentList: false,
        showFabButton: true
    };
};

export const fetchOnlineUsers = (onlineUsers) => {
    return {
        type: FETCH_ONLINE_AGENTS,
        onlineUsers: onlineUsers
    };
}

// export const fetchOnlineAgents = (channels, pubnubCredentials) => {
//     return dispatch => {
//         // const hereNowObject = { channels: channels, includeUUIDs: true, includeState: false };
//         // const pubnub = new Pubnub(pubnubCredentials);
//         // return pubnub.hereNow(hereNowObject)
//         //     .then(success => {
//         //         console.log(success, 'success')
//         //         dispatch(fetchOnlineUsers(success))
//         //     }).catch(err => {
//         //         console.log('error occured while getting online users >> pubnub', err)
//         //         dispatch(fetchOnlineUsers([1, 2, 3, 4]))
//         //     });
//     }
// }

export const setPubNubCreds = (pubnubCredentials) => {
    return {
        type: FETCH_KEYS,
        pubnubCredentials: pubnubCredentials
    }
}
export const openModalForm = () => {
    return {
        type: OPEN_MODAL,
        isOpenModal: true
    }
}
export const closeModalForm = () => {
    return {
        type: CLOSE_MODAL,
        isOpenModal: false
    }
}
export const configApiCall = () => {
    return dispatch => {
        callEventApi(`config`, 'GET').then(res => {
            if (res && res.status === 'Success') {
                const credentials = {
                    publishKey: res.data.pubnub_publisher_key,
                    subscribeKey: res.data.pubnub_subscriber_key,
                }
                dispatch(setPubNubCreds(credentials))
            }
        }).catch(err => {
            if (err) {
                console.log(err);
                dispatch(setPubNubCreds({hey: 1, bae: 2}))
            }
        })
    }

}

export const setOnlineUserList = (onlineUserList) => {
    return {
        type: SET_ONLINE_USERS,
        onlineUserList: onlineUserList
    }
}

export const totalOnlineUser = (value) => {
    return {
        type: TOTAL_ONLINE_USER,
        totalOnlineUser: value
    }
}

function getOnlyEventUUIDs(onlineUser, agentId) {
    let occupants = onlineUser && onlineUser.channels && onlineUser.channels[agentId] && onlineUser.channels[agentId].occupants;
    return occupants;
}

function getEventUUIds(occupants) {
    return occupants.filter(item => item.toString().includes("event-")).map(item => item.toString().substring(6, item.toString().length));
}

export const agentCredentialsApiCall = (onlineUser, agentId) => {
    return dispatch => {
        const eventIds = getOnlyEventUUIDs(onlineUser, agentId);
        const EVENTID_ALL = [...new Set(eventIds)];
        const eventList = getEventUUIds(EVENTID_ALL && EVENTID_ALL.map(e => e.uuid));
        const totalOnlineUsers = eventList.length;
        if (totalOnlineUsers) {
            dispatch(totalOnlineUser(totalOnlineUsers))
        }
        if (eventList) {
            callEventApi(`v2/events/events-from-ids`, 'POST', {eventIds: eventList}).then(res => {
                if (res && res.status === 'Success') {
                    if (res.data.length > 0) {
                        dispatch(setOnlineUserList(res.data))
                    } else {
                        dispatch(setOnlineUserList([]))
                    }
                }
            }).catch(err => {
                if (err) {
                    console.log('error', err);
                    dispatch(setOnlineUserList([]))
                }
            })
        } else {
            dispatch(setOnlineUserList([]))
        }
    }
}