import React, {Component} from 'react';
import _ from 'lodash';
import {
    FormControl,
    Button,
    InputGroup
} from 'react-bootstrap';

import moment from "moment";
import DateRangePicker from 'react-bootstrap-daterangepicker'


const scheduleTimer = ["after 1-hour", "after 2-hours", "after 6-hours", "after 12-hours", "after one-day", "after two-day", "Custom Set"];
class ScheduleVisitSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visitId: this.props.visitId,
            isCustomTimeSet: false,
            customVisitTime: moment(),
            visitTime: ''
        };
    }

    componentDidMount() {
        this.setState({
            visitId: this.props.visitId
        });
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps.visitId, this.props.visitId)) {
            this.setState({
                visitId: this.props.visitId
            });
        }
    }


    handleChangeVisitDate = (e, p) => {
        this.setState({
            customVisitTime: p.startDate
        });
    };

    handleChangeTime = (time) => {
        this.setState({visitTime: time}, () => {
            if(time === 'Custom Set') {
                this.handleToggleCustomSet(true);
            } else {
                this.handleToggleCustomSet(false);
            }
        });
    };

    getTime = () => {
        let time = '';
        switch (this.state.visitTime) {
            case 'after 1-hour':
                time = moment().add(1, 'h').utc().utcOffset(330).toISOString();
                break;
            case 'Custom Set':
                time = this.state.customVisitTime.utc().utcOffset(330).toISOString();
                break;

            default :
                time = '';

        }

        return time;
    };

    scheduleVisit = () => {
        let visit = {};
        visit.visitId = this.props.visitId;
        visit.scheduleTime = this.getTime();
        if(!!!visit.visitId || visit.visitId === '') {
            this.props.showMessage('Please Select a Visit', 'warning');
            return ;
        } else if(!!!visit.scheduleTime || visit.scheduleTime === '') {
            this.props.showMessage('Please select visit Time', 'warning');
            return;
        }
        this.props.handleScheduleVisit(visit);
        // scheduler.onetime.create(visit, function (err, res) {
        //    if(err) {
        //
        //    } else {
        //
        //    }
        // });
    };

    handleToggleCustomSet = (status) => {
        this.setState({isCustomTimeSet: !!status});
    };

    render() {
        let customDate = this.state.customVisitTime.format('DD MM YYYY - hh:mm');
        return (
            <div className={'panel'}>
                <div className={'panel-body'}>
                    <div className="col-xs-6">
                        <label htmlFor="">Select Time</label>
                        <select className="form-control" name="" id="" onChange={(e) => this.handleChangeTime(e.target.value)}>
                            <option key={''} value="">{`-- Select Time --`}</option>
                            {
                                scheduleTimer.map(time => (
                                    <option key={time} value={time}>{time}</option>
                                ))
                            }
                        </select>
                    </div>
                    {
                        this.state.isCustomTimeSet ?
                            <div className="col-xs-6">
                                <label htmlFor="">Custom Date & Time</label>
                                <DateRangePicker
                                    startDate={moment(this.state.customVisitTime)}
                                    onEvent={(e, p) => this.handleChangeVisitDate(e, p)}
                                    autoApply={true}
                                    singleDatePicker={true}
                                    timePicker={true}
                                >
                                    <InputGroup>
                                        <FormControl type="text" value={customDate}/>
                                        <InputGroup.Addon>
                                            <i className="fa fa-calendar" aria-hidden="true"/>
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </DateRangePicker>
                            </div> : null
                    }
                </div>
                <div className={'panel-footer'}>
                    <div className="text-center">
                        <Button style={{marginRight: "20px"}}
                                onClick={() => this.props.closeEditVisitForm()}>Close</Button>
                        <Button className="btn btn-md btn-primary"
                                onClick={() => this.scheduleVisit()}
                                disabled={this.props.isSaving}>
                            Schedule
                        </Button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ScheduleVisitSection;
