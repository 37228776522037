import React from 'react';
import {FadingCircle} from 'better-react-spinkit';

export function FadingCircleLoader(props) {
  return (
    <div className="text-center">
      <FadingCircle color={'#491369'} size={props.size ? props.size : 15} endOpacity={1} startOpacity={0} />
    </div>
  );
}

export default FadingCircleLoader;
