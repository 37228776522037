import callApi, { callSalesApi } from "../../util/apiCaller";
import callIdApi from "../../util/idApiCaller";
import callVenueApi from "../../util/venueApiCaller";
import _ from "lodash";

export const AGENT_DETAILS = "AGENT_DETAILS";
export const SET_AGENT_LIST = 'SET_AGENT_LIST';
export const SET_OPERATIONAL_CITY_LIST = 'SET_OPERATIONAL_CITY_LIST'

export const QC_COUNT = "QC_COUNT"

export function setQcCountData(count) {
    return (dispatch) => {
        dispatch({
            type: QC_COUNT,
            count: count
        })
    }
}

export function fetchQcCountData() {
    return async  dispatch => {
        const res = await callVenueApi(`v1/venue-tracker/get-count`, 'get');
            if (res.status === 'Success' && !_.isEmpty(res.data)) {
                dispatch(setQcCountData(res.data.count ? res.data.count : {}));
            }
    }
}


export function setAgentDetails(name, id) {
    return (dispatch) => {
        dispatch({
            type: AGENT_DETAILS,
            agentName: name,
            agentId: id

        });
    };
}


export function fetchOperationalCityList() {
    return async dispatch => {
        const res = await callSalesApi(`v3/citys/city-list?is_operational=true`, 'GET');
        if (res.status === 'Success') {
            dispatch({
                type: SET_OPERATIONAL_CITY_LIST,
                cityList: res.data.cities
            });
        }
        return;
    };
}

export function setAgentList(agentList) {
    return (dispatch) => {
        dispatch({
            type: SET_AGENT_LIST,
            agentList: agentList
        });
    };
}





export function fetchAgentList () {
    return (dispatch) => {
       return  callIdApi(`api/v3/agents/agent-list`, 'POST', {}).then(res => {
            if (res.status === "Success") {
                dispatch(setAgentList(res?.data?.agents))
            }
        });
    }
}
