import fetch from 'isomorphic-fetch';

export default function callAppUrl(url, method = 'get', body) {
    return fetch(`${url}`, {
        method,
        body: JSON.stringify(body),
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })
        .then(response => response.json().then(json => ({ json, response })))
        .then(({ json, response }) => {
            if (!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
        .then(
            response => response,
            error => error
        );
}
