import React from 'react';
import {Link} from 'react-router-dom';

export default function NotificationItem(props) {
    if (props.link && props.link !== '') {
        return (
            <Link
                to={props.link}>
                <div
                    style={{
                        width: '245px',
                        backgroundColor: '#AFF29A',
                        border: '1px solid #35DB00',
                        padding: '10px'
                    }}
                >
                    {props.message}
                </div>
            </Link>
        );
    } else {
        return (
            <div style={{
                width: '245px',
                backgroundColor: '#AFF29A',
                border: '1px solid #35DB00',
                padding: '10px'
            }}>
                <p>{props.message}</p>
            </div>
        );
    }
}
