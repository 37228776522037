import React from 'react';
import {
    Button
} from 'react-bootstrap';

function RecoVenues(props) {
    console.log("RecoVenues", props.venues);
    if (props.venues && props.venues.length > 0) {
        return (
            <div className="list-group">
                {props.venues.filter(item => item.uid).map(item => (
                    <div className="list-group-item" key={item._id}>
                        <div className="row">
                            <div className="col-xs-6">
                                <h4>{item.uid.name} <span className="matching-score">{item.score}%</span></h4>
                            </div>
                            <div className="col-xs-3">
                                {item.isShortlisted ? <Button
                                    disabled={item.isShortlisted}
                                >{item.userSelected ? "User Selected" : "Admin Selected"}</Button> : <Button
                                    onClick={() => props.shortlistVenue(props.bundleid, item.uid._id)}
                                >
                                    Shortlist
                                </Button> }
                            </div>
                            <div className="col-xs-3">
                                <Button
                                    onClick={() => props.removeVenue(props.bundleid, item.uid._id)}
                                >
                                    Remove
                                </Button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    } else {
        return (
            <div className="list-group">
                <div className="list-group-item">
                    <div className="text-center">
                       Add Venues
                    </div>
                </div>
            </div>
        );
    }
}

export default RecoVenues;
