import React, {Component} from 'react';
import {Button, FormGroup, InputGroup} from 'react-bootstrap';
import _ from 'lodash';

import ImagePreviewSection from './ImagePreviewSection';

const boxStyle = {
    border: '2px dashed #ddd',
    padding: '1em',
    textAlign: 'center',
    color: '#888'
};

const innerStyle = {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
};

const hiddenInput = {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: '-1'
};

class UploadImage extends Component {
    constructor() {
        super();
        this.state = {
            isCollapsed: false,
        };
    }


    generateRandomString = (len) => {
        var text = '';
        var possible = 'abcdefghijklmnopqrstuvwxyz0123456789';
        for (var i = 0; i < len; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    };

    handleUpdateForImage = (e) => {
        let oldfiles = e.target.files;
        let files = Array.from(oldfiles);
        let filenames = files.map((item, i) => {
            let ext = item.name.split('.').pop();
            let image = item.name.split('.');
            image.pop();
            let imageName = image.join('.');
            let preurl = `${this.props.url !== '' ? this.props.url : _.kebabCase(imageName)}-${this.generateRandomString(this.props.postFixLength)}`;
            let url = `${preurl}.${ext}`;
            let s3;
            if (!!this.props.isImageConvertible) {
                s3 = `${this.props.baseUrl}/original/`;
            }else {
                s3 = `${this.props.baseUrl}/`;
            }
            return {
                name: item.name,
                preurl: preurl,
                url: url,
                s3_url: s3 + url,
                filetype: `image/${ext}`,
                file: item,
                meta: this.getMetaData(oldfiles[i])
            };
        });
        this.props.uploadFiles(filenames, this.fileInput);
    };

    getMetaData = (file) => {
        let returnFile = {};
        returnFile.hasErrors = false;
        returnFile.errors = [];
        if (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/bmp') {
            let image = new Image();
            image.src = URL.createObjectURL(file);
            returnFile.imageurl = URL.createObjectURL(file);
            image.onload = function () {
                returnFile.h = this.height;
                returnFile.w = this.width;
                returnFile.size = file.size;
                if (this.height >= 480 || this.width >= 720) {
                    returnFile.hasErrors = true;
                    returnFile.h = this.height;
                    returnFile.w = this.width;
                } else {
                    returnFile.hasErrors = true;
                    returnFile.errors.push("Image resolution should be atleast 720 height or 480 width.");
                }
                if (file.size >= 500000) {
                    returnFile.size = file.size;
                } else {
                    returnFile.hasErrors = true;
                    returnFile.errors.push("Size less than 500kb");
                }
            };
            return returnFile;
        } else {
            returnFile.hasErrors = true;
            returnFile.errors.push("File is not a supported image format");
            return returnFile;
        }
    };

    toggleCollapse = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed
        })
    };

    handlePasteImage = (e) => {
        let oldfiles = e.clipboardData.files;
        let files = Array.from(oldfiles);
        let filenames = files.map((item, i) => {
            let ext = item.name.split('.').pop();
            let image = item.name.split('.');
            image.pop();
            let imageName = image.join('.');
            let preurl = `${this.props.url !== '' ? this.props.url : _.kebabCase(imageName)}-${this.generateRandomString(this.props.postFixLength)}`;
            let url = `${preurl}.${ext}`;
            let s3;
            if (!!this.props.isImageConvertible) {
                s3 = `${this.props.baseUrl}/original/`;
            }else {
                s3 = `${this.props.baseUrl}/`;
            }
            return {
                name: item.name,
                preurl: preurl,
                url: url,
                s3_url: s3 + url,
                filetype: `image/${ext}`,
                file: item,
                meta: this.getMetaData(oldfiles[i])
            };
        });
        this.props.uploadFiles(filenames, this.fileInput);
    };

    render() {
        let addImageSegment =
            <div style={{display: 'inline-flex', justifyContent: 'flex-start', width: '100%', flexWrap: 'wrap'}}>
                <div style={{display: 'flex', width: '100%'}}>
                    <form>
                        <FormGroup style={{marginBottom: '10px'}}>
                            <InputGroup>
                                <input
                                    type="text"
                                    name="paste"
                                    className="form-control"
                                    id="paste"
                                    placeholder={'Paste here to upload'}
                                    onPaste={(e) => this.handlePasteImage(e)}
                                    multiple={!!this.props.isMultiFileUploadAllowed}
                                    ref={(input) => {
                                        this.fileInput = input;
                                    }}
                                    style={{minWidth: '200px', height: '40px'}}
                                />
                                <InputGroup.Addon>
                                    <input
                                        type="file"
                                        name="file"
                                        id="file"
                                        onChange={(e) => this.handleUpdateForImage(e)}
                                        multiple={!!this.props.isMultiFileUploadAllowed}
                                        ref={(input) => {
                                            this.fileInput = input;
                                        }}
                                        style={hiddenInput}
                                    />
                                    <label
                                        htmlFor="file" style={{margin: 0, cursor: 'pointer'}}>
                                        <i style={{fontSize: '20px'}} className="fa fa-upload" aria-hidden="true"/>
                                    </label>
                                </InputGroup.Addon>
                            </InputGroup>
                        </FormGroup>
                    </form>
                </div>
                <div style={{width: '100%'}}>
                    <div style={{fontSize: '1.2em'}}>Choose File/Files</div>
                    <div>Please upload JPG/PNG files only.</div>
                </div>
            </div>;

        let uploadingSegment =
            <div style={boxStyle}>
                <div style={innerStyle}>
                    <i style={{fontSize: '3em'}} className="fa fa-sync fa-spin" aria-hidden="true"/>
                    <div
                        style={{fontSize: '1.2em'}}>Uploading {this.props.uploadedCount} of {this.props.totalUploadCount} Files
                    </div>
                </div>
            </div>;

        return (
            <div className="panel panel-default" style={{margin: "1.5em 0"}}>
                <div className="panel-heading">
                    <span className="panel-title">Select and upload Images</span>
                    <Button
                        style={{marginTop: "8px"}}
                        className="pull-right" onClick={() => {
                        this.toggleCollapse()
                    }}>
                        <i className={"fa fa-fw " + (this.state.isCollapsed ? " fa-arrow-circle-down " : " fa-arrow-circle-up ")}/>
                    </Button>
                </div>
                <div className="panel-body">
                    <div className={'row ' + (this.state.isCollapsed ? "hide" : "")}>

                        {/* List of uploading images*/}

                        <div className={'col-lg-6 col-md-6 col-sm-6 col-xs-12'}>
                            <div id="realevent-upload-segment"></div>
                            {this.props.isUploading
                                ? uploadingSegment
                                : addImageSegment
                            }
                        </div>
                    </div>
                    <div className="row">
                        {this.props.uploadingImages && this.props.uploadingImages.map(item => (
                            <ImagePreviewSection
                                name={item.name}
                                imageurl={item.imageurl}
                                hasErrors={item.hasErrors}
                                errors={item.errors}
                                progress={item.progress}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default UploadImage;
