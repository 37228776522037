import React, { Component } from 'react';
import {
    FormControl,
    Button,
    ButtonGroup
} from 'react-bootstrap';
import _ from 'lodash';
import moment from "moment";
import { useSelector, useDispatch } from 'react-redux';

function RackRate(props) {
    const venueList = useSelector(state => state.event.venueList)
    const assets = useSelector(state => state.event.assets)
    const selectedVenueID = useSelector(state => state.event.selectedVenueID)
    const dispatch = useDispatch();
    return (
        <div className="">
            {venueList.filter(data => data._id == selectedVenueID).map((item) => (
                <div className="" key={item._id}>
                    {assets && assets[item.uid._id] && assets[item.uid._id].packages && assets[item.uid._id].packages.length > 0 && assets[item.uid._id].packages.map(pack => (
                        <div key={pack._id} className={'list-group-item'}>
                            {!!pack.isMenuVisible ?
                                <div className="row">
                                    <div className={'col-xs-8'}>
                                        {pack.menuItems ?
                                            pack.menuItems.map(menuItem => `${menuItem.name} ${menuItem.count ? `- ${menuItem.count}, ` : `, `}`).join('')
                                            :
                                            <span>No Menu Added</span>
                                        }
                                    </div>
                                    <div className={'col-xs-2'}>
                                        <Button
                                        disabled={props.checkIfAdded(pack._id, assets[item.uid._id].subpackages) || item.isProcessingPackages}
                                        onClick={() => props.clonePackage(item.uid._id, item._id, pack)}
                                        >
                                            {item.isProcessingPackages ?
                                                <i className="fa fa-refresh fa-spinner" /> : props.checkIfAdded(pack._id, assets[item.uid._id].subpackages) ? 'Added' : 'Add'}
                                        </Button>
                                    </div>
                                    <div className="col-xs-2">
                                        <Button
                                            onClick={() => props.showMenuForVenuePackage(item.uid._id, pack._id, false)}
                                        >Back</Button>
                                    </div>
                                </div> :
                                <div className="row">
                                    <div className={'col-xs-4'}>
                                        {pack.name}
                                    </div>
                                    <div className={'col-xs-4'}>
                                        {pack.final_price}
                                    </div>
                                    <div className={'col-xs-2'}>
                                                <Button
                                                    disabled={props.checkIfAdded(pack._id, assets[item.uid._id].subpackages) || item.isProcessingPackages}
                                                    onClick={() => props.clonePackage(item.uid._id, item._id, pack)}
                                                >{item.isProcessingPackages ?
                                                    <i className="fa fa-refresh fa-spinner"/> : props.checkIfAdded(pack._id, assets[item.uid._id].subpackages) ? 'Added' : 'Add'}
                                                </Button>
                                            </div>
                                            <div className="col-xs-2">
                                                <Button
                                                    onClick={() => props.showMenuForVenuePackage(item.uid._id, pack._id, true)}
                                                >Menu</Button>
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );


}

export default RackRate;
