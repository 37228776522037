import React, { Component } from "react";
import _ from 'lodash';
import moment from 'moment';

class EventFeedback extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let feedback = this.props.feedback;
        return(
            <div className="panel panel-default" style={{marginTop: '15px'}}>
                <div className="panel-heading">
                    Feedback
                </div>
                <div className="panel-body">
                    {
                        this.props.feedback && !_.isEmpty(this.props.feedback) ?
                            <div className={`col-xs-12 alert ${feedback.isWon ? 'alert-success' : 'alert-danger'}`}>
                                { feedback.isWon ? 'Closed - Won' : 'Closed - Lost' }
                            </div> : null
                    }

                    {
                        this.props.feedback && !_.isEmpty(this.props.feedback) ?
                            <div style={{position: 'relative', width: '100%'}}>
                                {
                                    !feedback.isWon ?
                                        <div style={{position: 'relative', width: '100%'}}>
                                            {
                                                feedback.isEventHappen ?
                                                    <div>
                                                        <h5>Event Happen</h5>
                                                        {
                                                            feedback.isClosedLostVenuePresent ?
                                                                <div>
                                                                    <span><b>Venue Name:</b> </span><span>{!_.isEmpty(feedback.closeLostVenueId) ? feedback.closeLostVenueId.name : 'Venue details are not Present.'}</span>
                                                                </div> :
                                                                <div>
                                                                    <span><b>Venue Name:</b> </span><span>{!_.isEmpty(feedback.notFoundVenueId) ? feedback.notFoundVenueId.venue_name : 'Venue details are not Present.'}</span>
                                                                </div>
                                                        }

                                                    </div>: null
                                            }
                                            {
                                                feedback.isPostponed ?
                                                    <div>
                                                        <h5>Event Postponed</h5>
                                                        <div><span><b>Future Event Date: </b></span>
                                                        <span>{feedback.futureEvent && !_.isEmpty(feedback.futureEventId) ? moment(feedback.futureEventId.event_date).format('DD MMM YYYY') : 'Future Event details are not Present.'}</span>
                                                        </div>
                                                    </div> : null
                                            }
                                            {
                                                feedback.isCanceled ?
                                                    <div>
                                                        <h5>Event Canceled</h5>
                                                        <div><span><b>Reason: </b></span> <span>{feedback.reasonForCancel}</span></div>
                                                    </div> : null
                                            }
                                        </div>
                                        : null
                                }
                            </div>
                            :
                            <div className="text-center">No Feedback Yet</div>
                    }
                </div>
            </div>
        );
    }
}

export default EventFeedback;
