import React, { Component } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';

class ReSetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    submitForm = (e) => {
        e.preventDefault();
        let creds = {
            email: this.username.value,
        };
        this.props.resetPassword(creds);
    };

    render() {
        return (
            <div>
                <div className="col-md-4 col-md-offset-4">
                    <div className="panel panel-default" >
                        <div className="panel-heading">
                            <h2>Reset Password</h2>
                        </div>
                        <div className="panel-body">
                            <Form onSubmit={(e) => this.submitForm(e)} className="form-horizontal">
                                <div className="form-group mb-n">
                                    <div className="col-xs-12">
                                        <p>Enter your email to reset your password</p>
                                        <div className="input-group">
                                            <span className="input-group-addon">
                                                <i className="ti ti-user"></i>
                                            </span>
                                            <FormControl
                                                inputRef={ref => {
                                                    this.username = ref;
                                                }}
                                                type="text" name="email"
                                                placeholder="Username" />
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                        <div className="panel-footer">
                            <div className="d-flex justify-content-between">
                                <a href="/login" className="btn btn-default pull-left">Go Back</a>
                                <div >
                                    <Button

                                        onClick={(e) => this.submitForm(e)}
                                        className="btn btn-primary pull-right" type="submit"
                                    >Reset
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReSetPassword; 