import React, {useState, useEffect} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useSelector, useDispatch} from "react-redux";
import SingleEventNote from './SingleEventNote';
import {addEventNote} from "../../../redux/admin/SalesAgentQueueActions";
import AddEventNote from "./EventNotes/AddEventNote";
import {removeEventNote} from "../../../redux/admin/EventActions";
import {getActiveAgentId} from "../../../redux/admin/UserReducer";
import _ from 'lodash';

export default function EventNotes({event_id}) {

    const [activeTab, setActiveTab] = useState("1");

    const eventNotes = useSelector(state => {
        console.log("event", state.event.eventNoteMap);
        return state.event?.eventNoteMap[event_id]?.eventNoteList
    });
    const totalCount = useSelector(state => state.event?.eventNoteMap[event_id]?.totalEventNoteCount);
    const isRemovingNote = useSelector(state => state.event.isRemovingNote);
    const isUpdatingNote = useSelector(state => state.event.isUpdatingNote);
    const activeAgentId = useSelector(state => getActiveAgentId(state));
    const totalAgentMap = useSelector(state => state.user.totalAgentMap);

    const [editNote, setNote] = useState({});

    const dispatch = useDispatch();

    const setEditNote = (note) => {
        setNote(note);
        setActiveTab("3");
    };

    const removeNote = (note_id) => {
        dispatch(removeEventNote(event_id, note_id))
    };

    return (
        <div style={{marginTop: '20px'}}>
            <div className="panel">
                <Tabs
                    activeKey={activeTab}
                    animation={false}
                    id='noanim-tab-example-visit'
                    onSelect={setActiveTab}
                >
                    <Tab eventKey={"1"} title='Active Notes'>
                        <table className="table">
                            <thead>
                            <tr>
                                <th>Date</th>
                                <th>Note</th>
                                <th>Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {eventNotes?.map(note => (
                                <SingleEventNote
                                    key={note._id}
                                    note={note}
                                    isRemovingNote={isRemovingNote}
                                    isUpdatingNote={isUpdatingNote}
                                    setEditNote={setEditNote}
                                    removeNote={removeNote}
                                    activeAgentId={activeAgentId}
                                    totalAgentMap={totalAgentMap}
                                />
                            ))}
                            </tbody>
                        </table>
                    </Tab>
                    {/*<Tab eventKey={"2"} title='All Notes'>*/}
                    {/*    <table className="table">*/}
                    {/*        <thead>*/}
                    {/*        <tr>*/}
                    {/*            <th>Date</th>*/}
                    {/*            <th>Agent</th>*/}
                    {/*            <th>Note</th>*/}
                    {/*            <th>Action</th>*/}
                    {/*        </tr>*/}
                    {/*        </thead>*/}
                    {/*        <tbody>*/}
                    {/*        {eventNotes?.map(note => (*/}
                    {/*            <SingleEventNote*/}
                    {/*                note={note}*/}
                    {/*                totalAgentMap={totalAgentMap}*/}
                    {/*            />*/}
                    {/*        ))}*/}
                    {/*        </tbody>*/}
                    {/*    </table>*/}
                    {/*</Tab>*/}
                    <Tab eventKey={"3"} title="Add Note">
                        <AddEventNote
                            event_id={event_id}
                            isUpdatingNote={isUpdatingNote}
                            editNote={!_.isEmpty(editNote) ? editNote : null}
                        />
                    </Tab>
                </Tabs>
            </div>
        </div>

    );
}
