import React from 'react';
import {ProgressBar, ListGroup, ListGroupItem} from 'react-bootstrap';


export function ImagePreviewSection(props) {
    return (
        <div className="col-xs-3">
            <img
                style={{height: "160px", width:"100%"}}
                src={props.imageurl}
                alt="242x200"
            />
            <p>{props.name}</p>
            <ListGroup>
                {props.hasErrors && props.errors.map(error => (
                   <ListGroupItem>{error}</ListGroupItem>
                ))}
            </ListGroup>
            <ProgressBar now={props.progress} />
        </div>
    );
}

export default ImagePreviewSection;
