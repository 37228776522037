import React, {Component} from 'react';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import {
    Button,
    FormGroup,
    FormControl,
    ControlLabel,
    InputGroup,
} from 'react-bootstrap';
import callApi from "../../../util/apiCaller";
import callEventApi from "../../../util/eventApiCaller";
import ImageUploader from "./ImageUploader/ImageUploader";
import {CopyToClipboard} from "react-copy-to-clipboard";
import BasicLoader from "../../Loader/BasicLoader";

class LeadConversionForm extends Component {

    constructor() {
        super();
        this.startdate = moment();
        this.state = {
            isClosedWonEdit: false,
            convertedDate: this.startdate,
            isWon: false,
            images: [],
            isPaymentLinkCreated: false,
            isCreatingPayment: false,
            isCancellingPaymentLink: false,
            isPartialPaymentAllowed: false
        };
    };

    componentDidMount() {
        if (this.props.event && this.props.event.conversion_date) {
            this.setState({convertedDate: moment(this.props.event.conversion_date)});
        }
    }

    handleConvertedDateChange = (event, picker) => {
        this.setState({
            convertedDate: picker.startDate
        });
    };

    convertLeadValid = () => {
        if (this.eventConversionFactorText.value === "") {
            this.props.showMessage("Please select a reason for closing", "warning");
        }
        else if (this.eventFinalPrice.value === "") {
            this.props.showMessage("Please Enter Final Price", "warning");
        }
        else if (this.eventFinalComs.value === "") {
            this.props.showMessage("Please Enter Commissions per person", "warning");
        }
        else if (this.eventFinalMG.value === "") {
            this.props.showMessage("Please Enter Final MG(minimum guarantee)", "warning");
        }
        else if (this.eventConvertedVenue.value === "") {
            this.props.showMessage("Please select a venue", "warning");
        }
        else if (Number.isInteger(this.eventFinalPrice.value)) {
            this.props.showMessage("Please Enter Final price decimal value", "warning");
        }
        else if (!Number.isInteger(Number(this.eventFinalComs.value))) {
            this.props.showMessage("Please Enter Commissions per person whole number(without decimal value)", "warning");
        }
        else if (!Number.isInteger(Number(this.eventFinalMG.value))) {
            this.props.showMessage("Please Enter Final MG(minimum guarantee) whole number(without decimal value)", "warning");
        }
        else {
            return true;
        }
        return false;
    }

    submit = () => { 
        let isValid = this.convertLeadValid();
        if(isValid){
            let feedback = {};
            feedback.eventId = this.props.eventid;
            feedback.userId = this.props.userid;
            feedback.venueId = this.eventConvertedVenue.value;
            feedback.convertedDate = new Date(this.state.convertedDate.toISOString());
            feedback.conversionReason = this.eventConversionFactorText.value;
            feedback.min_pax = this.eventFinalMG.value;
            feedback.final_price = this.eventFinalPrice.value;
            feedback.commission = this.eventFinalComs.value;
            feedback.newLink = true;
            feedback.isWon = true;
            if (this.state.images && this.state.images.length > 0) {
                feedback.venue_confirmation_imageId = this.state.images[0]._id;
                feedback.venue_confirmation_imageUrl = this.state.images[0].url;
            }
            this.props.convertLead(this.props.eventid, feedback);
        }
    };

    editItem = (val) => {
        this.setState({
            isClosedWonEdit: !!val
        });
    };


    addImage = (image, next) => {
        let event = {};
        event.venue_confirmation_imageUrl = image.url;
        event.venue_confirmation_imageId = image._id;
        // this.saveUpdatedData(event);
        let array = [];
        array.push(image);
        return next(null, array);
    };

    createImage = (file, next) => {
        let data = {};
        data.name = file.preurl;
        data.url = file.url;
        data.size = file.meta.size;
        data.h = file.meta.h;
        data.w = file.meta.w;
        data.baseUrl = 'events/closed-won/confirmation-images';
        callApi(`v3/images/new`, 'post', data).then(res => {
            if (res.status === "Success") {
                if (res.data && res.data.image && res.data.image._id) {
                    this.addImage(res.data.image, (err, images) => {
                        if (err) {
                            this.props.showMessage("Images cannot be uploaded", "error");
                            return next();
                        } else {
                            this.setState({
                                isUploading: false,
                                images: images,
                            });
                            return next();
                        }
                    });
                } else {
                    return next();
                }
            } else {
                this.props.showMessage("Error", "danger");
                return next();
            }
        });
    };

    createPaymentLink = () => {
        this.setState({
            isCreatingPayment: true
        });
        let payment = {};
        payment.amount = this.paymentAmount.value;
        payment.description = `Payment Advance for Booking - ${this.props.event.enquiry_num}`;
        payment.contact_name = this.props.event.contact_name;
        payment.contact_email = this.props.event.contact_email;
        payment.contact_phone = this.props.event.contact_phone;
        payment.isPartialPaymentAllowed = this.state.isPartialPaymentAllowed;
        callEventApi(`v3/payments/razorpay/create-payment-link`, 'post', {
            payment
        }).then(res => {
            this.setState({
                isCreatingPayment: false
            });
            if (res.status === "Success") {
                console.log('payment', res);
                let event = {};
                event.payment_amount = res.data.payment.amount;
                event.isPaymentLinkCreated = true;
                event.payment_service = 'razorpay';
                event.paymentId = res.data.payment.id;
                event.paymentLink = res.data.payment.short_url;
                this.props.updateEvent(this.props.eventid, event);
                this.props.showMessage("Payment Link Created", "success");
            } else {
                this.props.showMessage("Error", "danger");
            }
        });
    };

    cancelPaymentLink = () => {
        this.setState({
            isCancellingPaymentLink: true
        });
        let payment = {
            paymentId: this.props.event.paymentId,
        };
        callEventApi(`v3/payments/razorpay/cancel-payment-link`, 'post', {
            payment: payment
        }).then(res => {
            this.setState({
                isCancellingPaymentLink: false
            });
            if (res.status === "Success") {
                if (res.data && res.data.payment && res.data.payment.status === 'cancelled') {
                    let event = {};
                    event.payment_amount = 0;
                    event.isPaymentLinkCreated = false;
                    event.payment_service = '';
                    event.paymentId = '';
                    event.paymentLink = '';
                    this.props.updateEvent(this.props.eventid, event);
                    this.props.showMessage("Payment Link Cancelled", "success");
                }
            } else {
                this.props.showMessage("Error", "danger");
            }
        });
    };

    render() {
        let currentUploaderImageUrl;
        if (this.state.images && this.state.images.length > 0) {
            currentUploaderImageUrl = this.state.images[0].url;
        } else if (this.props.event && this.props.event.venue_confirmation_imageUrl) {
            currentUploaderImageUrl = this.props.event.venue_confirmation_imageUrl;
        } else {
            currentUploaderImageUrl = '';
        }
        let convertedDate = this.state.convertedDate.format('DD-MM-YYYY');
        let convertedDatelabel = `${convertedDate}`;
        return (
            <div className="panel panel-default">
                <div className="panel-heading">
                    Close - Won
                </div>
                {this.props.event.status === 'Closed - Won' && !this.state.isClosedWonEdit ?
                    <React.Fragment>
                        <div className="list-group">
                            <div className="list-group-item">
                                Venue Name : {this.props.event.final_venue_name}
                            </div>
                            <div className="list-group-item">
                                Final Price: {this.props.event.customer_price}
                            </div>
                            <div className="list-group-item">
                                Commissions per person: {this.props.event.commission}
                            </div>
                            <div className="list-group-item">
                                Final MG: {this.props.event.min_pax}
                            </div>
                            <div className="list-group-item">
                                Conversion Date: {moment(this.props.event.conversion_date).format('DD MMM YYYY')}
                            </div>
                            <div className="list-group-item">
                                Converted Reason: {this.props.event.conversion_factor}
                            </div>
                            {this.props.event && this.props.event.venue_confirmation_imageUrl ?
                                <div style={{padding: '15px'}}>
                                    <div><label htmlFor="">Confirmation Image:</label></div>
                                    <div>
                                        <img style={{maxWidth: '400px'}}
                                             src={`https://cdn.venuemonk.com/events/closed-won/confirmation-images/${this.props.event.venue_confirmation_imageUrl}`}
                                             alt=""/>
                                    </div>
                                </div> :
                                <div className="list-group-item">
                                    Confirmation Image: N/A
                                </div>
                            }
                        </div>
                        <div className="panel-footer">
                            <Button
                                onClick={() => this.editItem(true)}
                            >{'Edit Details'}</Button>
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div className="panel-body">
                            <div className="panel">
                                <div className="panel-heading">
                                    Create Payment Link
                                </div>
                                {!this.state.isCancellingPaymentLink ?
                                    <div className="panel-body">
                                        <FormGroup>
                                            <ControlLabel>
                                                Payment Amount*
                                            </ControlLabel>
                                            <FormControl
                                                disabled={this.state.isCreatingPayment}
                                                defaultValue={this.props.event.payment_amount}
                                                inputRef={ref => {
                                                    this.paymentAmount = ref;
                                                }}
                                            />
                                        </FormGroup>
                                        {this.props.event.isPaymentLinkCreated ?
                                            <FormGroup>
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        <FormControl
                                                            value={this.props.event.paymentLink}
                                                        />
                                                    </div>
                                                    <div className="col-xs-2">
                                                        <CopyToClipboard text={this.props.event.paymentLink}
                                                                         onCopy={() => this.props.showMessage('copied', 'success')}>
                                                            <Button><i className="fa fa-clipboard" /></Button>
                                                        </CopyToClipboard>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-xs-8">
                                                        <a
                                                            disabled={this.state.isCancellingPaymentLink}
                                                            onClick={() => this.cancelPaymentLink()} className="btn">
                                                            {this.state.isCancellingPaymentLink ?
                                                                <BasicLoader size={10} />
                                                                :
                                                                'Cancel or Change Amount in Payment Link'
                                                            }
                                                        </a>
                                                    </div>
                                                </div>
                                            </FormGroup>
                                            :
                                            <FormGroup>
                                                <Button
                                                    disabled={this.state.isCreatingPayment}
                                                    onClick={() => this.createPaymentLink()}
                                                >
                                                    {this.state.isCreatingPayment ?
                                                        'Creating Link' :
                                                        'Create Payment Link'
                                                    }
                                                </Button>
                                            </FormGroup>
                                        }
                                    </div>:
                                    <div className={'panel-body'}>
                                        <div className="text-center">
                                            <BasicLoader size={10} />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="panel-body">
                            <div className="panel panel-default">
                                <div className="panel-body">
                                    <FormGroup>
                                        <ControlLabel>
                                            Converted Venue*
                                        </ControlLabel>
                                        <FormControl
                                            defaultValue={this.props.event.finalVenueId}
                                            componentClass="select"
                                            inputRef={ref => {
                                                this.eventConvertedVenue = ref;
                                            }}
                                        >
                                            <option value=""></option>
                                            {this.props.venues && this.props.venues.map(item => (
                                                <option key={item._id} value={item.uid._id}>{item.uid.name}</option>
                                            ))}
                                        </FormControl>
                                    </FormGroup>
                                    <DateRangePicker
                                        startDate={this.state.convertedDate}
                                        onEvent={this.handleConvertedDateChange}
                                        autoApply={true}
                                        singleDatePicker={true}
                                        timePicker={true}
                                    >
                                        <FormGroup>
                                            <InputGroup>
                                                <FormControl type="text" value={convertedDatelabel}/>
                                                <InputGroup.Addon>
                                                    <i className="fa fa-calendar" aria-hidden="true"/>
                                                </InputGroup.Addon>
                                            </InputGroup>
                                        </FormGroup>
                                    </DateRangePicker>
                                    <FormGroup>
                                        <ControlLabel>
                                            Final Price*
                                        </ControlLabel>
                                        <FormControl
                                            type="number"
                                            inputRef={ref => {
                                                this.eventFinalPrice = ref;
                                            }}
                                        >
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Commissions per person*
                                        </ControlLabel>
                                        <FormControl
                                            type="number"
                                            inputRef={ref => {
                                                this.eventFinalComs = ref;
                                            }}
                                        >
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Final MG*
                                        </ControlLabel>
                                        <FormControl
                                            type="number"
                                            inputRef={ref => {
                                                this.eventFinalMG = ref;
                                            }}
                                        >
                                        </FormControl>
                                    </FormGroup>
                                    <FormGroup>
                                        <ControlLabel>
                                            Converted Reason
                                        </ControlLabel>
                                        <FormControl
                                            componentClass="textarea"
                                            defaultValue={this.props.event.conversion_factor}
                                            inputRef={ref => {
                                                this.eventConversionFactorText = ref;
                                            }}
                                        >
                                        </FormControl>
                                    </FormGroup>
                                    {currentUploaderImageUrl && currentUploaderImageUrl !== '' ?
                                        <div style={{padding: '15px'}}>
                                            <div><label htmlFor="">Confirmation Image:</label></div>
                                            <div>
                                                <img style={{maxWidth: '400px'}}
                                                     src={`https://cdn.venuemonk.com/events/closed-won/confirmation-images/${currentUploaderImageUrl}`}
                                                     alt=""/>
                                            </div>
                                        </div> :
                                        <div className="list-group-item">
                                            Confirmation Image: N/A
                                        </div>
                                    }
                                    <ImageUploader
                                        baseUrl={'events/closed-won/confirmation-images'}
                                        url={this.props.event && this.props.event._id ? this.props.event._id : ''}
                                        isBaseUrlPresent={true}
                                        isbaseUrlNotEditable={true}
                                        isBaseUrlVisible={false}
                                        isMultiFileUploadAllowed={true}
                                        postFixLength={6}
                                        isImageConvertible={false}
                                        showMessage={this.props.showMessage}
                                        createImage={this.createImage}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="panel-footer">
                            <div className="row">
                                <div className="col-sm-8 col-sm-offset-2">
                                    <Button
                                        disabled={this.props.isSending}
                                        onClick={() => this.submit()}
                                    >{this.props.isSending ? "Closing Lead" : "Close Lead"}</Button>
                                    {this.props.event.status === 'Closed - Won'
                                        ?
                                        <Button
                                            onClick={() => this.editItem(false)}
                                        >{'Back'}</Button> :
                                        null
                                    }
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default LeadConversionForm;
