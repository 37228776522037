import React, {useEffect, useState} from 'react';
import {
    Button
} from 'react-bootstrap';


function RecoVenueCombined(props) {
    const [venues, setVenues] = useState([]);
    const [event, setEvent] = useState({});

    useEffect(() => {
        let event = {...props.event};
        if (props.event.budget_end) {
            if (parseInt(props.event.budget_end) <= 800) {
                event.budget_end = 'Under 800';
            } else if (parseInt(props.event.budget_end) > 800 && parseInt(props.event.budget_end) <= 1200) {
                event.budget_end = '800-1200';
            } else if (parseInt(props.event.budget_end) > 1200 && parseInt(props.event.budget_end) <= 1600) {
                event.budget_end = '1200-1600';
            } else if (parseInt(props.event.budget_end) > 1600 && parseInt(props.event.budget_end) <= 1800) {
                event.budget_end = '1600-1800';
            } else if (parseInt(props.event.budget_end) > 1800 && parseInt(props.event.budget_end) <= 2200) {
                event.budget_end = '1800-2200';
            } else if (parseInt(props.event.budget_end) > 2200) {
                event.budget_end = 'Above 2200';
            }
        }
        if (props.event.guests) {
            if (parseInt(props.event.guests) <= 20) {
                event.guests = 'Under 20';
            } else if (parseInt(props.event.guests) > 20 && parseInt(props.event.guests) <= 50) {
                event.guests = '20-50';
            } else if (parseInt(props.event.guests) > 50 && parseInt(props.event.guests) <= 100) {
                event.guests = '50-100';
            } else if (parseInt(props.event.guests) > 100) {
                event.guests = '100+';
            }
        }
        setEvent(event);
    }, [props.event])

    const getIfShortlistedVenue = (venueId) => {
        return !!props.assets[venueId]
    }

    const getVenueSegment = () => {
        let segment = null;
        if (props.venues && props.venues.length > 0) {
            segment = <div className="list-group" style={{height: '250px', overflowY: 'auto', borderTop: '1px solid'}}>
                {props.venues.map(item => (
                    <div className="list-group-item" key={item._id}>
                        <div className="row">
                            <div className="col-xs-9">
                                <div className="row">
                                    <div className="col-xs-12">
                                        <span>{item.name}</span>
                                    </div>
                                    <div className="col-xs-12">
                                        <div style={{display: 'flex', flexDirection: 'row'}}>
                                            {
                                                item.algo ?
                                                    <div className='btn btn-sm tags-rec-venues'>{item.algo}</div> : null
                                            }
                                            {
                                                item.city ?
                                                    <div className='btn btn-sm  tags-rec-venues'><i
                                                        className="fa fa-map-marker space-right"
                                                        aria-hidden="true"></i> {item.city}
                                                    </div> : null
                                            }
                                            {
                                                item.veg_show ? <div className='btn btn-sm  tags-rec-venues-veg'><i
                                                    className="fa fa-cutlery space-right"
                                                    aria-hidden="true"></i> {item.veg_show}
                                                </div> : null
                                            }
                                            {
                                                item.nonveg_show ?
                                                    <div className='btn btn-sm  tags-rec-venues-non-veg'><i
                                                        className="fa fa-cutlery space-right"
                                                        aria-hidden="true"></i> {item.nonveg_show}
                                                    </div> : null
                                            }
                                            {
                                                item.cap_max ?
                                                    <div className='btn btn-sm  tags-rec-venues'><i
                                                        className="fa fa-users space-right"
                                                        aria-hidden="true"></i> {item.cap_max}
                                                    </div> : null
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-3">
                                <div className="row">
                                    {getIfShortlistedVenue(item._id) ? <Button
                                            disabled={getIfShortlistedVenue(item._id)}
                                        >{"Selected"}</Button> :
                                        <Button
                                            onClick={() => props.shortlistVenue(props.bundleid, item._id)}
                                        >
                                            Shortlist
                                        </Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>;
        } else {
            segment = <div className="list-group">
                <div className="list-group-item">
                    <div className="text-center">
                        No Recommended Venues
                    </div>
                </div>
            </div>;
        }
        return segment
    }

    return (
        <div className="list-group">
            <div className='list-group-item'>
                <div style={{display: 'flex', flexDirection: 'row', minHeight: '15px', margin: '12px'}}>
                    {
                        props.event.category ? <div className='tags'><i className="fa fa-calendar space-right"
                                                                        aria-hidden="true"></i>{props.event.category}
                        </div> : null
                    }
                    {
                        props.event.city ?
                            <div className='tags'>
                                <i className="fa fa-map-marker space-right" aria-hidden="true"></i>
                                {props.event.city} ,
                                {props.event?.location}
                            </div> : null
                    }
                    {
                        props.event.packagetype ? <div className='tags'><i className="fa fa-cutlery space-right"
                                                                           aria-hidden="true"></i>{props.event.packagetype}
                        </div> : null
                    }
                    {
                        props.event.budget_end ? <div className='tags'><i className="fa fa-inr space-right"
                                                                          aria-hidden="true"></i>{event.budget_end}
                        </div> : null
                    }
                    {
                        props.event.guests ? <div className='tags'><i className="fa fa-users space-right"
                                                                      aria-hidden="true"></i>{event.guests}
                        </div> : null
                    }
                </div>
            </div>
            {getVenueSegment()}
        </div>
    )
}

export default RecoVenueCombined;
